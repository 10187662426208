import React, { useEffect, useState, useContext } from 'react'
import '../../styles/car_list/car_list_main.css'
import ReactLoading from 'react-loading'
import { useCookies } from 'react-cookie'
import { CarListContext } from '../../context/index'
import Search_input from '../UI/search_input.jsx'
import Groups_cars_filter from '../UI/groups_cars_filter.jsx'
import SimpleBar from 'simplebar-react'
import Table_item from './car_list_item.jsx'
import { useDispatch, useSelector } from 'react-redux'
import car_data from '../../API/car_data'

export default function Car_list(props) {
  const window_height = useSelector((state) => state.window_height)
  const simplebar_height = window_height - 70 - 24 - 24 - 24 - 24 - 21 - 60 - 55

  let { car_list_object } = useContext(CarListContext)
  const [cookies] = useCookies(['csrftoken'])
  let searched_array = []
  const [render_table, setRender_table] = useState(false)
  //groups_filter
  const [group_sort_id, setGroup_sort_id] = useState('ALL')
  //end groups_filter

  //
  const [search, setSearch] = useState(null)
  //

  let cars = []

  if (props.cars) {
    cars = props.cars
  }

  cars.map((car) => {
    if (car.car_number == null) {
      car.car_number = '-'
    }
    if (car.brand == null) {
      car.brand = '-'
    }
    if (car.phone_number == null) {
      car.phone_number = '-'
    }
    if (car.mileage == null) {
      car.mileage = '-'
    }
    if (car.daily_mileage == null) {
      car.daily_mileage = '-'
    }
    if (car.fuel_consumption == null) {
      car.fuel_consumption = '-'
    }
    if (!search) {
      if (group_sort_id == 'ALL' || group_sort_id == car.car_group) {
        searched_array.push(car)
      }
    } else {
      if (car.car_number && car.car_number.toUpperCase().includes(search.toUpperCase())) {
        if (group_sort_id == 'ALL' || group_sort_id == car.car_group) {
          searched_array.push(car)
        }
      }
    }
  })

  function check_checked(car_id) {
    // console.log(props.group_id)
    // console.log(car_id)
    if (props.cars_for_report.includes(car_id)) {
      return 'checked'
    } else {
      return 'unchecked'
    }
  }

  return (
    <div className='' style={{ height: '100%' }}>
      <div className='text_car_list'>Отчет для авто</div>
      {props.cars && props.groups ? (
        <>
          <div style={{ marginTop: '24px', width: '340px' }}>
            <Groups_cars_filter
              groups={props.groups}
              cars={props.cars}
              group_sort_id={group_sort_id}
              setGroup_sort_id={setGroup_sort_id}
              text_all={'Выбрать группу'}
            />
          </div>
          <div style={{ marginTop: '20px' }}>
            <Search_input placeholder={'Введите  гос. номер'} change={setSearch} />
          </div>
          <SimpleBar style={{ maxHeight: simplebar_height }}>
            {searched_array.map((car) => (
              <Table_item
                setTime={props.setTime}
                car_img={car_data.get_svg_car(car.color)}
                car={car}
                checked={check_checked(car.id)}
                key={car.id}
                cars_for_report={props.cars_for_report}
                setCars_for_report={props.setCars_for_report}
                setRender_table={setRender_table}
              />
            ))}
          </SimpleBar>
        </>
      ) : (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ReactLoading type={'spin'} color='#6486FB' />
        </div>
      )}
    </div>
  )
}
