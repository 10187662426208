import React from "react";
import '../../../styles/routes_map/routes.css'

const Modal_cars_new_zone = ({active, setActive, children}) => {

    return (
        <div className={active ? "modal_cars_new_zone active" : "modal_cars_new_zone"} onClick={() => setActive()}>
            <div className="modal_cars_new_zone_content" onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );

}

export default Modal_cars_new_zone;