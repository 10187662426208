/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState, useContext } from 'react'
import Search_input from '../../UI/search_input'
import Car_table_window_routes from './car_table_window_routes'
import Groups_cars_filter from '../../UI/groups_cars_filter.jsx'
import '../../../styles/routes_map/routes.css'
import ReactLoading from 'react-loading'
import axios_api from '../../../API/axios_api'
import { MapContext } from '../../../context/index'
import { useCookies } from 'react-cookie'
import SimpleBar from 'simplebar-react'
import Modal_with_accept from '../../UI/modal_with_accept'
import Btn_defolt from '../../UI/btn_defolt'
import Basic_modal from '../../UI/basic_modal'
import Basic_input from '../../UI/basic_input'
import Icon_btn from '../../UI/icon_btn.jsx'
import content_copy from '../../../styles/icons/content-copy.svg'
import Tippy from '@tippyjs/react/headless'
import { useSelector } from 'react-redux'

export default function Car_list_window_routes(props) {
  const window_height = useSelector((state) => state.window_height)
  const user_current = useSelector((state) => state.user_current)
  const simplebar_height = window_height - 70 - 24 - 24 - 55 - 35 - 43 - 36 - 20

  const [cookies] = useCookies(['csrftoken'])

  const [search, setSearch] = useState(null)
  const [group_sort_id, setGroup_sort_id] = useState('ALL')
  const [cars, setCars] = useState(null)
  const [groups, setGroups] = useState(null)

  const [cars_for_video, setCars_for_video] = useState([])

  const [yandex_location, setYandex_location] = useState('')
  const [yandex_location_modal, setYandex_location_modal] = useState(false)

  //commands
  const [command_id, setCommand_id] = useState(0)
  //

  //warning_modals
  const [modal_warning_active, setActive_modal_warnind] = useState(false)
  //

  //for modals accept turn on/off engine
  const [modal_turn_on_engine_active, setActive_modal_turn_on_engine] = useState(false)
  const [status_modal_turn_on_engine, setStatus_modal_turn_on_engine] = useState('')

  const [modal_turn_off_engine_active, setActive_modal_turn_off_engine] = useState(false)
  const [status_modal_turn_off_engine, setStatus_modal_turn_off_engine] = useState('')

  const [modal_cancel_command_active, setActive_modal_cancel_command] = useState(false)
  const [status_modal_cancel_command, setStatus_modal_cancel_command] = useState('')

  const [car_id_engine, setCar_id_engine] = useState(0)
  const [car_number_engine, setCar_number_engine] = useState('')
  //

  const [modal_text_error, setModal_text_error] = useState('')

  let { map_object } = useContext(MapContext)

  map_object.setYandex_location = setYandex_location
  map_object.setYandex_location_modal = setYandex_location_modal

  function change_search_input(new_search) {
    setSearch(new_search)
  }

  function copy_text() {
    navigator.clipboard.writeText(yandex_location)
  }

  useEffect(() => {
    reload_cars_table()
  }, [])

  if (map_object.first_init_window_routes) {
    setInterval(() => {
      reload_cars_table()
    }, 60000)
    map_object.first_init_window_routes = false
  }

  let class_visible
  if (props.item == 'cars') {
    class_visible = ''
  } else {
    class_visible = 'divs_window_routes'
  }

  function send_command_to_car(command_type, command_id, car_id, car_number) {
    console.log(
      'command : ',
      command_type,
      ', command_id : ',
      command_id,
      ', car : ',
      car_id,
      ' - ',
      car_number,
    )
    setCar_id_engine(car_id)
    setCar_number_engine(car_number)
    setCommand_id(command_id)

    if (!command_id) {
      setActive_modal_warnind(true)
      return
    }

    if (command_type == 'turn_on_engine') {
      setActive_modal_turn_on_engine(true)
    }
    if (command_type == 'turn_off_engine') {
      setActive_modal_turn_off_engine(true)
    }
    if (command_type == 'cancel_command') {
      setActive_modal_cancel_command(true)
    }
  }

  async function accept_cancel_command() {
    if (user_current.email === 'demo@controlgps.org') {
      setStatus_modal_cancel_command('success')
      reload_cars_table()
      setTimeout(function () {
        setActive_modal_cancel_command(false)
        setStatus_modal_cancel_command('')
      }, 2000)
      return
    }

    if (!user_current.permissions.includes('ENGINE_BLOCK')) {
      setModal_text_error('У Вас нет прав доступа!')
      setStatus_modal_cancel_command('error')
      setTimeout(function () {
        setStatus_modal_cancel_command('')
        setModal_text_error('')
      }, 2000)
      reload_cars_table()
      return
    }

    let response = await axios_api.cancel_command_to_car(
      command_id,
      car_id_engine,
      cookies.csrftoken,
    )

    if (response.status == '200' && response.data.message !== 'Nothing to cancel') {
      setStatus_modal_cancel_command('success')
    } else {
      setStatus_modal_cancel_command('error')
    }

    setTimeout(function () {
      setActive_modal_cancel_command(false)
      setStatus_modal_cancel_command('')
    }, 2000)

    reload_cars_table()
  }

  async function accept_turn_on_engine() {
    if (user_current.email === 'demo@controlgps.org') {
      setStatus_modal_turn_on_engine('success')
      cars_for_video.push({ number: car_number_engine, engine: 'pending' })
      reload_cars_table()
      setTimeout(function () {
        setActive_modal_turn_on_engine(false)
        setStatus_modal_turn_on_engine('')
      }, 2000)

      setTimeout(function () {
        for (let car of cars_for_video) {
          if (car.number == car_number_engine) {
            car.engine = 'on'
          }
        }
        reload_cars_table()
      }, 6000)
      return
    }

    if (!user_current.permissions.includes('ENGINE_BLOCK')) {
      setModal_text_error('У Вас нет прав доступа!')
      setStatus_modal_turn_on_engine('error')
      setTimeout(function () {
        setStatus_modal_turn_on_engine('')
        setModal_text_error('')
      }, 2000)
      reload_cars_table()
      return
    }

    let response = await axios_api.send_command_to_car(command_id, car_id_engine, cookies.csrftoken)

    if (response.status == '200') {
      setStatus_modal_turn_on_engine('success')
    } else {
      setStatus_modal_turn_on_engine('error')
    }

    setTimeout(function () {
      setActive_modal_turn_on_engine(false)
      setStatus_modal_turn_on_engine('')
    }, 2000)

    reload_cars_table()
  }

  async function accept_turn_off_engine() {
    if (user_current.email === 'demo@controlgps.org') {
      setStatus_modal_turn_off_engine('success')
      cars_for_video.push({ number: car_number_engine, engine: 'pending' })
      reload_cars_table()
      setTimeout(function () {
        setActive_modal_turn_off_engine(false)
        setStatus_modal_turn_off_engine('')
      }, 2000)

      setTimeout(function () {
        for (let car of cars_for_video) {
          if (car.number == car_number_engine) {
            car.engine = 'off'
          }
        }
        reload_cars_table()
      }, 6000)
      return
    }

    if (!user_current.permissions.includes('ENGINE_BLOCK')) {
      setModal_text_error('У Вас нет прав доступа!')
      setStatus_modal_turn_off_engine('error')
      setTimeout(function () {
        setStatus_modal_turn_off_engine('')
        setModal_text_error('')
      }, 2000)
      reload_cars_table()
      return
    }

    let response = await axios_api.send_command_to_car(command_id, car_id_engine, cookies.csrftoken)

    if (response.status == '200') {
      setStatus_modal_turn_off_engine('success')
    } else {
      setStatus_modal_turn_off_engine('error')
    }

    setTimeout(function () {
      setActive_modal_turn_off_engine(false)
      setStatus_modal_turn_off_engine('')
    }, 2000)

    reload_cars_table()
  }

  map_object.send_command_to_car = send_command_to_car

  return (
    <div className={class_visible} style={{ height: '100%' }}>
      {cars && groups ? (
        <>
          <div style={{ marginBottom: '20px' }}>
            <Groups_cars_filter
              groups={groups}
              cars={cars}
              group_sort_id={group_sort_id}
              setGroup_sort_id={setGroup_sort_id}
              text_all={'Выбрать группу'}
            />
          </div>
          <Search_input placeholder={'Введите  гос. номер'} change={change_search_input} />
          <SimpleBar style={{ maxHeight: simplebar_height }}>
            <Car_table_window_routes
              search={search}
              group_sort_id={group_sort_id}
              cars={cars}
              setNavBar_item={props.setNavBar_item}
            />
          </SimpleBar>
        </>
      ) : (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ReactLoading type={'spin'} color='#6486FB' />
        </div>
      )}
      <Modal_with_accept
        active={modal_cancel_command_active}
        setActive={setActive_modal_cancel_command}
      >
        <div className='text_accept_delete'>
          Вы уверены, что хотите отменить выполение команды "{car_number_engine}"?
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
          <Btn_defolt
            color={'#EFF3FF'}
            text_color={'#6486FB'}
            hover_color={'#dbe4ff'}
            text={'Назад'}
            height={'40px'}
            width={'150px'}
            onClick={(e) => setActive_modal_cancel_command(false)}
          />
          <Btn_defolt
            color={'#6486FB'}
            text_color={'#FFFFFF'}
            hover_color={'#5179fc'}
            text={'Отменить'}
            height={'40px'}
            width={'150px'}
            onClick={accept_cancel_command}
          />
        </div>
        <div
          className={
            status_modal_cancel_command == 'success'
              ? 'status_modal_success active'
              : 'status_modal_success'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>Успешно!</div>
            <div className='text_status_info'>
              Последняя команда "{car_number_engine}" отменена!
            </div>
          </div>
        </div>
        <div
          className={
            status_modal_cancel_command == 'error'
              ? 'status_modal_error active'
              : 'status_modal_error'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>Ошибка!</div>
            <div className='text_status_info'>{modal_text_error}</div>
          </div>
        </div>
      </Modal_with_accept>
      <Modal_with_accept
        active={modal_turn_off_engine_active}
        setActive={setActive_modal_turn_off_engine}
      >
        <div className='text_accept_delete'>
          Вы уверены, что хотите заглушить двигатель "{car_number_engine}"?
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
          <Btn_defolt
            color={'#EFF3FF'}
            text_color={'#6486FB'}
            hover_color={'#dbe4ff'}
            text={'Назад'}
            height={'40px'}
            width={'150px'}
            onClick={(e) => setActive_modal_turn_off_engine(false)}
          />
          <Btn_defolt
            color={'#6486FB'}
            text_color={'#FFFFFF'}
            hover_color={'#5179fc'}
            text={'Заглушить'}
            height={'40px'}
            width={'150px'}
            onClick={accept_turn_off_engine}
          />
        </div>
        <div
          className={
            status_modal_turn_off_engine == 'success'
              ? 'status_modal_success active'
              : 'status_modal_success'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>Успешно!</div>
            <div className='text_status_info'>
              Команда глушения двигателя "{car_number_engine}" отправлена!
            </div>
          </div>
        </div>
        <div
          className={
            status_modal_turn_off_engine == 'error'
              ? 'status_modal_error active'
              : 'status_modal_error'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>Ошибка!</div>
            <div className='text_status_info'>{modal_text_error}</div>
          </div>
        </div>
      </Modal_with_accept>
      <Modal_with_accept
        active={modal_turn_on_engine_active}
        setActive={setActive_modal_turn_on_engine}
      >
        <div className='text_accept_delete'>
          Вы уверены, что хотите разблокировать двигатель "{car_number_engine}"?
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
          <Btn_defolt
            color={'#EFF3FF'}
            text_color={'#6486FB'}
            hover_color={'#dbe4ff'}
            text={'Назад'}
            height={'40px'}
            width={'150px'}
            onClick={(e) => setActive_modal_turn_on_engine(false)}
          />
          <Btn_defolt
            color={'#6486FB'}
            text_color={'#FFFFFF'}
            hover_color={'#5179fc'}
            text={'Разблокировать'}
            height={'40px'}
            width={'150px'}
            onClick={accept_turn_on_engine}
          />
        </div>
        <div
          className={
            status_modal_turn_on_engine == 'success'
              ? 'status_modal_success active'
              : 'status_modal_success'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>Успешно!</div>
            <div className='text_status_info'>
              Команда разблокировки двигателя "{car_number_engine}" отправлена!
            </div>
          </div>
        </div>
        <div
          className={
            status_modal_turn_on_engine == 'error'
              ? 'status_modal_error active'
              : 'status_modal_error'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>Ошибка!</div>
            <div className='text_status_info'>{modal_text_error}</div>
          </div>
        </div>
      </Modal_with_accept>
      <Modal_with_accept active={modal_warning_active} setActive={setActive_modal_warnind}>
        <div className='text_accept_delete'>
          К сожалению, данная команда недоступна для авто "{car_number_engine}" :(
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
          <Btn_defolt
            color={'#EFF3FF'}
            text_color={'#6486FB'}
            hover_color={'#dbe4ff'}
            text={'Закрыть'}
            height={'40px'}
            width={'150px'}
            onClick={(e) => setActive_modal_warnind(false)}
          />
        </div>
      </Modal_with_accept>
      <Basic_modal active={yandex_location_modal} setActive={setYandex_location_modal}>
        <div className='yandex_content'>
          <div className='text_accept_delete' style={{ textAlign: 'center', fontSize: '20px' }}>
            Локация авто '{map_object.yandex_location_car}'
          </div>
          <div style={{ marginTop: '20px', display: 'flex' }}>
            <div>
              <Basic_input
                value={yandex_location}
                text={''}
                status={'readonly'}
                width={'650px'}
                type={'text'}
                onChange={setYandex_location}
              />
            </div>
            <Tippy
              render={(attrs) => (
                <div className='box tippy_content' tabIndex='-1' {...attrs}>
                  Скопировать
                </div>
              )}
            >
              <div style={{ marginTop: '6px', marginLeft: '8px' }}>
                <Icon_btn
                  color={'#EFF3FF'}
                  hover_color={'#dbe4ff'}
                  icon={content_copy}
                  height={'46px'}
                  width={'46px'}
                  onClick={copy_text}
                />
              </div>
            </Tippy>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Btn_defolt
              color={'#EFF3FF'}
              text_color={'#6486FB'}
              hover_color={'#dbe4ff'}
              text={'Закрыть'}
              height={'40px'}
              width={'150px'}
              onClick={(e) => setYandex_location_modal(false)}
            />
          </div>
        </div>
      </Basic_modal>
    </div>
  )

  async function reload_cars_table() {
    if (!map_object.cars) {
      setTimeout(reload_cars_table, 250)
    } else {
      let groups = await axios_api.get_groups(cookies.csrftoken)
      console.log('groups : ', groups)
      map_object.groups = groups
      setGroups(groups)
      let new_cars = await axios_api.get_cars_detailed(cookies.csrftoken, map_object.frame_index)
      map_object.frame_index = map_object.frame_index + 1 //инфа для бэка
      console.log('new cars for list: ', map_object.cars)
      if (user_current.email === 'demo@controlgps.org') {
        for (let car of cars_for_video) {
          for (let car2 of new_cars) {
            if (car.number === car2.car_number) {
              if (car.engine === 'on') {
                car2.commands[0].state = false
              } else {
                if (car.engine === 'off') {
                  car2.commands[0].state = true
                } else {
                  car2.commands[0].pending = true
                }
              }
            }
          }
        }
        console.log('cars_for_video : ', cars_for_video)
        console.log('new_cars : ', new_cars)
        setCars(new_cars)
      } else {
        setCars(new_cars)
      }
    }
  }
}
