import React, { useEffect, useState } from 'react'
import '../../styles/components/components.css'

export default function Btn_defolt(props) {
  //console.log('props : ', props)

  let box_shadow = hexToRgb(props.color)

  let btn_style_defolt = {
    background: props.color,
    height: props.height,
    width: props.width,
    color: props.text_color,
    boxShadow:
      '0px 15px 35px rgba(' +
      box_shadow.r +
      ',' +
      box_shadow.g +
      ',' +
      box_shadow.b +
      ', 0.3)',
  }

  let btn_style_hover = {
    background: props.hover_color,
    height: props.height,
    width: props.width,
    color: props.text_color,
    boxShadow:
      '0px 15px 35px rgba(' +
      box_shadow.r +
      ',' +
      box_shadow.g +
      ',' +
      box_shadow.b +
      ', 0.4)',
  }

  const [style, setStyle] = useState(btn_style_defolt)

  function click_btn() {
    props.onClick()
  }

  function MouseEnter() {
    setStyle(btn_style_hover)
  }

  function onMouseLeave() {
    setStyle(btn_style_defolt)
  }

  return (
    <div
      className="div_btn_defolt"
      style={style}
      onMouseEnter={MouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={click_btn}
    >
      <span>{props.text}</span>
    </div>
  )

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  }
}
