import React, { useRef, useEffect, useStaet, useContext } from 'react'
import Draw_nav_bar_main from '../components/navbar/draw_nav_bar'
import Draw_dashboard from '../components/dashboard/draw_dashboard.jsx'

import { useDispatch, useSelector } from 'react-redux'
import resize_action from '../actions/window_actions.js'
import page_change from '../actions/router_actions.js'
import { MapContext } from '../context/index'
import $ from 'jquery'

const MainDashboard = () => {
  let { map_object } = useContext(MapContext)
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = 'Дашборд | ControlGPS'
    let height = $(document).height()
    let width = $(document).width()
    dispatch(resize_action(height, width))
    dispatch(page_change('dashboard'))
    map_object.markers = []
    map_object.map = null
  }, [])

  return <Draw_dashboard />
}

export default MainDashboard
