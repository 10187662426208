/* eslint-disable react/jsx-pascal-case */
import React from 'react'

export default function TableItemParam(props) {
  // const [active, setActive] = useState(false)

  function row_click(e) {
    let id = e.currentTarget.getAttribute('data_id')
    let name = e.currentTarget.getAttribute('data_name')
    props.row_click(id, name)
  }

  // category_name: '-'
  // id: 9
  // left: 123123
  // state: 'NO_NEED'

  // "NEED" "NO_NEED" "SKIPPED" "FUTURE" "PASSED" "NOT_CONFIGURED"

  const makeTOClass = (inspections_state) => {
    switch (inspections_state) {
      case 'NOT_CONFIGURED':
        return 'Не настроено'
      case 'PASSED':
        return 'Пройдено'
      case 'NEED':
        return 'Требуется'
      case 'SKIPPED':
        return 'Пропущено'
      case 'FUTURE':
        return 'Запланировано'
      case 'NO_NEED':
        return 'Не требуется'
      default:
        return ''
    }
  }

  return (
    <div
      className={'carParam ' + props.param.state}
      data_id={props.param.id}
      data_name={props.param.category_name}
      onClick={(e) => row_click(e)}
      // onMouseEnter={(e) => setActive(true)}
      // onMouseLeave={(e) => setActive(false)}
    >
      <div className="carParamName">{props.param.category_name}</div>
      <div className={'carParamStatus ' + props.param.state}>
        {makeTOClass(props.param.state)}
      </div>
      <div className="carParamLeftDiv">
        Через{' '}
        <span className={'carParamLeft ' + props.param.state}>
          {props.param.left} км
        </span>
      </div>
    </div>
  )
}
