import React, { useEffect, useState, useContext } from 'react';
import '../../../styles/park_settings/main_park_settings.css'
import Aggregators_list from './aggregators_list/aggregators_list'
import Aggregator_edit from './aggregator_edit/aggregator_edit'

export default function Aggregators_settings(props) {

    const [page, setPage] = useState('list')
    const [aggregator, setAggregator] = useState()

    if (page == 'list'){
        return (
            <Aggregators_list aggregators={props.aggregators} setAggregator={setAggregator} setPage={setPage} update_all={props.update_all}/>
        )
    } else {
        return (
            <Aggregator_edit aggregator={aggregator} setPage={setPage} update_all={props.update_all}/>
        )
    }
}