import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import { colorsRouteShare } from '../../components/share/constants'

const isValidCoords = (longitude, latitude) => {
  if (-180 <= longitude <= 180 && -90 <= latitude <= 90) {
    return true
  }
  return false
}

export default class shareMapApi {
  static async setCarMarkerLoc(car, lat, lon, map_object) {
    const moveNumber = () => {
      let element = document.getElementById('marker_car_number_span_' + car.id)
      let width = element.offsetWidth
      let move_px = -Math.round(width / 2.45)
      element.style.right = move_px + 'px'
    }

    const makeMarker = () => {
      let course = car.locations[0].course

      let markerDiv = document.createElement('div')

      if (!car.color) {
        car.color = 'LIGHT_BLUE'
      }

      let iconSize = ['1', '0']

      let marker_div =
        '<div class="div_marker">' +
        '<div class="marker marker_' +
        car.id +
        ' marker_' +
        car.color +
        ' marker_size_' +
        iconSize[0] +
        '_' +
        iconSize[1] +
        '" style="transform: rotate(' +
        course +
        'deg);"></div>' +
        '<span id="marker_car_number_span_' +
        car.id +
        '" class="marker_car_number_span marker_car_number_span_' +
        iconSize[0] +
        '_' +
        iconSize[1] +
        '"><span class="marker_car_number_text ' +
        ' marker_car_number_text_' +
        iconSize[0] +
        '_' +
        iconSize[1] +
        '">' +
        car.car_number +
        '</span></span>' +
        '</div>'

      markerDiv.insertAdjacentHTML('beforeend', marker_div)

      return markerDiv
    }

    if (!map_object.map_share || !isValidCoords(lon, lat)) {
      return
    }

    if (map_object.marker_share) {
      map_object.marker_share.setLngLat([lon, lat])
    } else {
      map_object.marker_share = new mapboxgl.Marker({
        element: makeMarker(),
        rotation: '0',
      })
        .setLngLat([lon, lat])
        .addTo(map_object.map_share)
      moveNumber()

      map_object.map_share.flyTo({
        center: [lon, lat],
        zoom: 12,
        speed: 0.1,
        curve: 1,
        duration: 3000,
        essential: true,
        easing(t) {
          return t
        },
      })
    }
  }

  static async drawCarRoute(days, map_object) {
    //delete old stop markers
    for (const marker of map_object.stopMarkersShare) {
      marker.remove()
    }
    map_object.stopMarkersShare = []
    //end delete old stop markers

    //delete old lines
    for (const line of map_object.linesShare) {
      map_object.map_share.removeLayer(line)
      map_object.map_share.removeSource(line)
    }
    map_object.linesShare = []
    //end delete old lines

    let colorIndex = 0
    for (let day of days) {
      for (let piece of day.route) {
        if (piece.type === 'move') {
          if (colorIndex === colorsRouteShare.length) {
            colorIndex = 0
          }
          piece.color = colorsRouteShare[colorIndex]
          colorIndex = colorIndex + 1
        }
      }
    }

    let lastStopLoc = null
    let lastMoveLoc = null
    let lineId = 0

    for (const day of days) {
      let source = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      }

      for (const piece of day.route) {
        if (piece.type === 'move') {
          let feature = {
            type: 'Feature',
            properties: {
              color: piece.color,
            },
            geometry: {
              type: 'LineString',
              coordinates: [],
            },
          }

          if (lastStopLoc) {
            feature.geometry.coordinates.push(lastStopLoc)
          }
          if (lastMoveLoc) {
            feature.geometry.coordinates.push(lastMoveLoc)
          }

          let lastLon
          let lastlat
          for (const point of piece.points) {
            lastLon = point.coords[0]
            lastlat = point.coords[1]
            feature.geometry.coordinates.push(point.coords)
          }

          source.data.features.push(feature)

          let id = 'id_' + lineId

          map_object.linesShare.push(id)

          map_object.map_share.addSource(id, source)

          map_object.map_share.addLayer({
            id: id,
            type: 'line',
            source: id,
            paint: {
              'line-width': 4,
              'line-color': ['get', 'color'],
            },
          })

          lastMoveLoc = [lastLon, lastlat]
          lastStopLoc = null
          lineId++
        } else {
          const stopLon = piece.points[0].coords[0]
          const stopLat = piece.points[0].coords[1]

          const stopMarker = document.createElement('div')
          stopMarker.className = 'stop_marker'

          let marker = new mapboxgl.Marker({ element: stopMarker, rotation: '0' })
            .setLngLat([stopLon, stopLat])
            .addTo(map_object.map_share)

          map_object.stopMarkersShare.push(marker)

          lastStopLoc = [stopLon, stopLat]
        }
      }
    }
  }
}
