export const colorsRouteShare = [
  '#6486FB',
  '#21AD7B',
  '#FBA364',
  '#FF0000',
  '#FFEB3B',
  '#FB6464',
  '#D464FB',
  '#FBD164',
  '#FB64C8',
  '#8B64FB',
  '#000F43',
  '#64FBA9',
  '#64FBFB',
  '#002FD0',
]
