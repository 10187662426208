import React, { useEffect, useState, useContext } from 'react';

import '../../styles/dashboard/dashboard_main.css'

export default function Info_card(props) {

    let style_bg = {
        'background': props.bg_color,
        'color': props.text_color,
    }

    let count = 0

    let cars = props.cars

    if (!cars){
        cars = []
    }

    if (props.value == 'ALL_CARS'){
        for (let car of cars){
            if ((props.group_sort_id == 'ALL')||(car.car_group == props.group_sort_id)){
                count = count + 1
            }
        }
    }

    if (props.value == 'ONLINE_CARS'){
        for (let car of cars){
            if ((props.group_sort_id == 'ALL')||(car.car_group == props.group_sort_id)){
                if (car.on_line){
                    count = count + 1
                }
            }
        }
    }

    if (props.value == 'OFFLINE_CARS'){
        for (let car of cars){
            if ((props.group_sort_id == 'ALL')||(car.car_group == props.group_sort_id)){
                if (!car.on_line){
                    count = count + 1
                }
            }
        }
    }

    return (
        <div className='info_card_dashboard' style={style_bg}>
            <div className='text_name_info_card'>
                {props.text}
            </div>
            <span className='text_count_info_card'>
                {count}
            </span>
            <span className='text_cars_info_card'>
                авто
            </span>
        </div>
    )

}