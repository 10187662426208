import React, { useEffect, useState, useContext } from 'react';
import axios_api from "../../API/axios_api"
import '../../styles/dashboard/cards_fuel.css'
import { useCookies } from 'react-cookie';
import SimpleBar from 'simplebar-react';
import Tippy from '@tippyjs/react/headless'
import ReactLoading from "react-loading";



export default function Card_payment_history(props) {
    const [cookies] = useCookies(['csrftoken']);

    const [cars_payment_history, setCars_payment_history] = useState([])
    const [is_loading, setIs_loading] = useState(true)

    useEffect(() => {
        get_cars_payment_history()
    }, [props.group_sort_id]);

    if (cars_payment_history){
        for (let payment of cars_payment_history){
            if (!payment.car.car_number){
                payment.car.car_number = '-'
            }
            if (!payment.car.brand){
                payment.car.brand = '-'
            }
            //"2022-11-17T04:45:17.760750"
            let date = payment.dt.split('T')[0]
            let day = date.split('-')[2]
            let month = date.split('-')[1]
            let year = date.split('-')[0]

            // payment.date = ''
            payment.date = day + '.' +  month + '.' + year

            if (payment.type_description.length > 15){
                payment.short_type = payment.type_description.substr(0, 15) + '...'
            }
        }
    }

    return (
        <div className='main_div_card_cars_fuel'>
            <div className='main_text_card_cars_fuel' style={{'marginBottom': '20px'}}>
                История пополнения трекеров
            </div>
            {is_loading == false
                ?
                <>
                    {cars_payment_history.length != 0
                        ?
                        <SimpleBar style={{ maxHeight: '345px' }}>
                            {cars_payment_history.map((payment) =>
                                <div key={payment.id}>
                                    <div className={'row_table_payments_history'}>
                                        <div className='td_car_number_table_payments_history'>
                                            <div className=''>
                                                <div className='table_top_mil_number'>
                                                    {payment.car.car_number}
                                                </div>
                                                <div className='table_top_mil_brand'>
                                                    {payment.car.brand}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='td_amount_table_payments_history'>
                                            {Math.round(payment.amount)} ₽
                                        </div>
                                        <div className='td_date_table_payments_history'>
                                            {payment.date}
                                        </div>
                                        <div className='td_type_table_payments_history'>
                                            {payment.short_type
                                                ?
                                                    <>
                                                        <Tippy
                                                            render={attrs => (
                                                                <div className="box tippy_content" tabIndex="-1" {...attrs}>
                                                                    {payment.type_description}
                                                                </div>
                                                            )}
                                                        >
                                                            <span>{payment.short_type}</span>
                                                        </Tippy>
                                                    </>
                                                :
                                                    <>
                                                        {payment.type_description}
                                                    </>
                                            }
                                        </div>
                                    </div>
                                    <div className='div_border_bottom_table_car_list'>
                                        <div className='border_bottom_table_car_list'></div>
                                    </div>
                                </div>
                            )}
                            
                        </SimpleBar>
                        :
                            <div className='no_data_car_chart_mileage' style={{'height': '345px'}}>Нет данных</div>
                    }
                    </>
                :   
                    <div style={{'height': '100%', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center'}}><ReactLoading type={'spin'} color="#6486FB" /></div>
            }
        </div>
    )

    async function get_cars_payment_history(){
        setCars_payment_history([])
        setIs_loading(true)
        let new_cars_payment_history = await axios_api.get_cars_payment_history(props.group_sort_id, cookies.csrftoken)
        console.log('dashboard new cars_payment_history : ', new_cars_payment_history)
        let count = 0
        for (let payment of new_cars_payment_history){
            payment.id = count 
            count++
        }
        setCars_payment_history(new_cars_payment_history)
        setIs_loading(false)
    }

}