/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useContext } from 'react'

import '../../styles/park_settings/main_park_settings.css'

import user_settings_icon from '../../styles/icons/user_settings_icon.svg'
import car_settings_icon from '../../styles/icons/car_settings_icon.svg'
import wrench_clock from '../../styles/icons/wrench_clock.svg'
import home_city_outline from '../../styles/icons/home-city-outline.svg'
import bank_outline from '../../styles/icons/bank-outline.svg'
import speedometer from '../../styles/icons/speedometer.svg'
import geo_alt from '../../styles/icons/geo-alt.svg'
import geozone_grey from '../../styles/icons/geozone_grey.svg'
import key from '../../styles/icons/key.svg'
import { useDispatch, useSelector } from 'react-redux'

export default function Settings_list(props) {
  const user_current = useSelector((state) => state.user_current)

  let page = props.setting_page

  function item_click(e) {
    let name = e.currentTarget.getAttribute('data_name')
    props.setSetting_page(name)
  }

  return (
    <div className='div_main_settings_list'>
      <div className='text_main_settings_list'>Настройки парка</div>
      <div
        className={page == 'users' ? 'settings_list_item active' : 'settings_list_item'}
        data_name={'users'}
        onClick={(e) => item_click(e)}
      >
        <div className='imgDivSettings'>
          <img src={user_settings_icon} />
        </div>
        <div className='text_settings_list_item'>Пользователи</div>
      </div>
      <div
        className={page == 'bills' ? 'settings_list_item active' : 'settings_list_item'}
        data_name={'bills'}
        onClick={(e) => item_click(e)}
      >
        <div className='imgDivSettings'>
          <img src={bank_outline} />
        </div>
        <div className='text_settings_list_item'>Счета и лицензии</div>
      </div>
      <div
        className={page == 'groups' ? 'settings_list_item active' : 'settings_list_item'}
        data_name={'groups'}
        onClick={(e) => item_click(e)}
      >
        <div className='imgDivSettings'>
          <img src={car_settings_icon} />
        </div>
        <div className='text_settings_list_item'>Группы авто</div>
      </div>
      {user_current && user_current.balance_blocking ? (
        <div
          className={page == 'aggregators' ? 'settings_list_item active' : 'settings_list_item'}
          data_name={'aggregators'}
          onClick={(e) => item_click(e)}
        >
          <div className='imgDivSettings'>
            <img src={home_city_outline} />
          </div>
          <div className='text_settings_list_item'>Агрегаторы</div>
        </div>
      ) : (
        <></>
      )}
      <div
        className={page == 'geozones' ? 'settings_list_item active' : 'settings_list_item'}
        data_name={'geozones'}
        onClick={(e) => item_click(e)}
      >
        <div className='imgDivSettings'>
          <img src={geozone_grey} />
        </div>
        <div className='text_settings_list_item'>Геозоны</div>
      </div>
      <div
        className={page == 'inspections' ? 'settings_list_item active' : 'settings_list_item'}
        data_name={'inspections'}
        onClick={(e) => item_click(e)}
      >
        <div className='imgDivSettings'>
          <img src={wrench_clock} />
        </div>
        <div className='text_settings_list_item'>Планы ТО</div>
      </div>
      <div
        className={page == 'geolocation' ? 'settings_list_item active' : 'settings_list_item'}
        data_name={'geolocation'}
        onClick={(e) => item_click(e)}
      >
        <div className='imgDivSettings'>
          <img src={geo_alt} />
        </div>
        <div className='text_settings_list_item'>Геопозиция парка</div>
      </div>
      <div
        className={page == 'driving_quality' ? 'settings_list_item active' : 'settings_list_item'}
        data_name={'driving_quality'}
        onClick={(e) => item_click(e)}
      >
        <div className='imgDivSettings'>
          <img src={speedometer} />
        </div>
        <div className='text_settings_list_item'>Качество вождения</div>
      </div>
      <div
        className={page == 'refill' ? 'settings_list_item active' : 'settings_list_item'}
        data_name={'refill'}
        onClick={(e) => item_click(e)}
      >
        <div className='imgDivSettings'>
          <img src={key} />
        </div>
        <div className='text_settings_list_item'>Внешний API ключ</div>
      </div>
    </div>
  )
}
