/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import './Share.css'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { colorsRouteShare } from './constants'
import { useSelector } from 'react-redux'
import ReactLoading from 'react-loading'
import SimpleBar from 'simplebar-react'

const Route = (props) => {
  const windowHeight = useSelector((state) => state.window_height)
  const simplebarHeight = props.isMobile
    ? windowHeight - 140 - 55
    : windowHeight - 70 - 24 - 24 - 55

  const mobileClass = props.isMobile ? '_mobile' : ''

  const convertDate = (date) => {
    const daysOfWeek = ['вс.', 'пн.', 'вт.', 'ср.', 'чт.', 'пт.', 'сб.']
    const months = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ]
    let day = parseFloat(date.split('.')[0])
    let month = parseFloat(date.split('.')[1])
    let year = parseFloat(date.split('.')[2])
    let newDate = new Date(year, month - 1, day)
    let dayOfWeek = daysOfWeek[newDate.getDay()]
    let monthStr = months[newDate.getMonth()]
    return day + ' ' + monthStr + ', ' + dayOfWeek
  }

  const convertDateRange = (start, end) => {
    start = start.split(' ')[1]
    start = start.split(':')[0] + ':' + start.split(':')[1]
    end = end.split(' ')[1]
    end = end.split(':')[0] + ':' + end.split(':')[1]
    return start + '-' + end
  }

  const getRgbColor = (colorInHex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorInHex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  }

  let colorIndex = 0
  for (let day of props.route) {
    for (let piece of day.route) {
      if (piece.type === 'move') {
        if (colorIndex === colorsRouteShare.length) {
          colorIndex = 0
        }
        piece.color = colorsRouteShare[colorIndex]
        colorIndex = colorIndex + 1
      }
    }
  }

  return (
    <SimpleBar style={{ maxHeight: simplebarHeight }}>
      <div className='routeDiv'>
        <div className='routeBody'>
          {props.routeLoading ? (
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ReactLoading type={'spin'} color='#6486FB' />
            </div>
          ) : (
            <>
              {props.route.length ? (
                <>
                  {props.route.map((day) => (
                    <div key={day.date}>
                      <div className='routeDay'>
                        <div className='routeDateAndDistance'>
                          <div>{convertDate(day.date)}</div>
                          <div>{day.distance.toFixed(1)} км.</div>
                        </div>
                        <div>
                          {day.route.map((piece, index) =>
                            piece.type === 'move' ? (
                              <div
                                key={day.date + index}
                                className='movePiece'
                                style={{
                                  color: piece.color,
                                }}
                              >
                                <div
                                  className='moveTime'
                                  style={{
                                    background:
                                      'rgba(' +
                                      getRgbColor(piece.color).r +
                                      ', ' +
                                      getRgbColor(piece.color).g +
                                      ', ' +
                                      getRgbColor(piece.color).b +
                                      ', 0.1)',
                                  }}
                                >
                                  {convertDateRange(piece.start_dt, piece.end_dt)}
                                </div>
                                <div className='progressBar'>
                                  <style type='text/css'>
                                    {'.bg-' +
                                      'custom_' +
                                      piece.color.split('#')[1] +
                                      ' {' +
                                      'background-color: ' +
                                      piece.color +
                                      ';' +
                                      'color: white;' +
                                      '}'}
                                  </style>
                                  <ProgressBar
                                    variant={'custom_' + piece.color.split('#')[1]}
                                    now={((piece.distance * 100) / day.distance).toFixed(0)}
                                  />
                                </div>
                                <div style={{ width: '80px' }}>
                                  <div
                                    className='moveDistance'
                                    style={{
                                      background:
                                        'rgba(' +
                                        getRgbColor(piece.color).r +
                                        ', ' +
                                        getRgbColor(piece.color).g +
                                        ', ' +
                                        getRgbColor(piece.color).b +
                                        ', 0.1)',
                                    }}
                                  >
                                    {piece.distance.toFixed(1)} км.
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div key={day.date + index} className='stopPiece'>
                                <div className='stopTime'>
                                  {convertDateRange(piece.start_dt, piece.end_dt)}
                                </div>
                                <div className='stopText'>Стоянка</div>
                                <div className='stopText'></div>
                              </div>
                            ),
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className='noRoutes'>Нет данных о маршруте</div>
              )}
            </>
          )}
        </div>
      </div>
    </SimpleBar>
  )
}

export default Route
