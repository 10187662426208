import React, { useEffect, useState, useContext } from 'react';
import '../../../styles/drivers/drivers_main.css'
import ReactLoading from "react-loading";
import { useCookies } from 'react-cookie';
import axios_api from "../../../API/axios_api"
import Basic_input from '../../UI/basic_input'
import Fake_btn_defolt from '../../UI/fake_btn_defolt.jsx'
import Btn_defolt from '../../UI/btn_defolt'
import Switch from "react-switch";
import {useDispatch, useSelector} from 'react-redux'
import resize_action from '../../../actions/window_actions.js';
import $ from 'jquery';

export default function Main_driver_profile(props) {
    const dispatch = useDispatch()
    const [cookies] = useCookies(['csrftoken']);

    // {
    //     "id": 3,
    //     "balance": 1.0,
    //     "license_expire_date": "2023-02-24T22:38:51",
    //     "license_issue_date": "2023-02-24T22:38:53",
    //     "license_number": "822",
    //     "last_name": "Егор",
    //     "first_name": "Егор",
    //     "middle_name": "Егоров",
    //     "phone_numbers": [
    //         71231231234,
    //         77777777777
    //     ],
    //     "car_api_id": 1216,
    //     "engine_block_active": false,
    //     "engine_block_balance_threshold": 50000.0,
    //     "is_active": true,
    //     "partner": 1,
    //     "car": 1
    // }

    const [first_name, setFirst_name] = useState()
    const [middle_name, setMiddle_name] = useState()
    const [last_name, setLast_name] = useState()
    const [license_expire_date, setLicense_expire_date] = useState()
    const [license_issue_date, setLicense_issue_date] = useState()
    const [phone_numbers, setPhone_numbers] = useState()
    const [license_number, setLicense_number] = useState()
    const [balance, setBalance] = useState()
    const [engine_block_balance_threshold, setEngine_block_balance_threshold] = useState()
    const [engine_block_active, setEngine_block_active] = useState()

    const [status_save, setStatus_save] = useState('')

    async function send_changes(){

        let resp_driver_block_engine = await axios_api.driver_block_engine(engine_block_active, engine_block_balance_threshold, props.driver.id, cookies.csrftoken)

        if (resp_driver_block_engine.status == 200){
            setStatus_save('success')
        } else {
            setStatus_save('error')
        }
        
        setTimeout(
            function(){
                setStatus_save(null)
            }, 2000
        );

        props.get_drivers()
    }

    function convert_date(date){
        if ((date!=null)&&(date!='-')){
            return date.split('T')[0].split('-')[2] + '.' + date.split('T')[0].split('-')[1] + '.' + date.split('T')[0].split('-')[0]
        } else {
            return '-'
        }
    }

    useEffect(() => {
        if (props.driver){
            setFirst_name(props.driver.first_name)
            setMiddle_name(props.driver.middle_name)
            setLast_name(props.driver.last_name)
            setLicense_expire_date(props.driver.license_expire_date)
            setLicense_issue_date(props.driver.license_issue_date)
            setLicense_number(props.driver.license_number)
            setPhone_numbers(props.driver.phone_numbers)
            setBalance(props.driver.balance)
            setEngine_block_balance_threshold(props.driver.engine_block_balance_threshold)
            setEngine_block_active(props.driver.engine_block_active)
        }
    }, [props.driver_profile_id]);

    useEffect(() => {
        if (props.driver){
            if (props.drivers){
                for (let driver of props.drivers){
                    if (driver.id == props.driver.id){
                        props.setDriver_profile(driver)
                    }
                }
            }
        }
        let height = $( document ).height();
        let width = $( document ).width();
        dispatch(resize_action(height, width))
    }, [props.driver, props.drivers]);

    return (
        <div className='' style={{'height': '100%'}}>
            {(props.driver)
                ?
                    <>
                        <div style={{'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'space-between', 'height': '100%'}}>
                            <div className='text_car_profile' style={{'marginLeft': '24px'}}>
                                {last_name + ' ' + first_name + ' ' +  middle_name}
                            </div>
                            <div className='text_checkbox_with_text' style={{'marginLeft': '24px', 'marginRight': '24px'}}>
                                Если баланс водителя упадет ниже указанной суммы, автомобиль будет автоматически заглушен
                            </div>
                            <div style={{'display': 'flex', 'alignItems': 'center', 'marginLeft': '24px', 'marginRight': '24px'}}>
                                <Switch onChange={(e) => setEngine_block_active(!engine_block_active)} checked={engine_block_active} uncheckedIcon={false} checkedIcon={false} height={20} width={36} offColor={'#E7ECFB'} onColor={'#6486FB'} activeBoxShadow={'0 0 2px 3px #6486FB'}/>
                                <div className={engine_block_active ? "text_checkbox_with_text active" : "text_checkbox_with_text"} style={{'marginLeft': '10px'}}>
                                    При балансе ниже
                                </div>
                                <input className="input_money_refill_settings" type="text" value={engine_block_balance_threshold} onChange={(e) => setEngine_block_balance_threshold(e.target.value)} style={{'marginLeft': 'auto', 'marginRight': '10px', 'width': '100px'}}></input>
                                <div className={engine_block_active ? "text_checkbox_with_text active" : "text_checkbox_with_text"}>
                                    ₽
                                </div>
                            </div>
                            <div style={{'marginTop': '15px', 'display': 'flex', 'justifyContent': 'center'}}>
                            {status_save
                                ?
                                    <>
                                        {status_save == 'success'
                                            ?
                                                <Fake_btn_defolt color={'#0acf97'} text_color={'#FFFFFF'} hover_color={'#0acf97'} text={'Сохранено!'} height={'40px'} width={'100%'}/>
                                            :   
                                                <Fake_btn_defolt color={'#fa5c7c'} text_color={'#FFFFFF'} hover_color={'#fa5c7c'} text={'Ошибка!'} height={'40px'} width={'100%'}/>                                
                                        }
                                    </>
                                :   
                                    <Btn_defolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Сохранить'} height={'40px'} width={'100%'} onClick={send_changes}/>
                            }
                            </div>
                        </div>
                    </> 
                :   
                    <div style={{'height': '100%', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center'}}><ReactLoading type={'spin'} color="#6486FB"/></div>
            }
        </div>
    )

}