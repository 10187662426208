import React, { useEffect, useState, useContext } from 'react';

import icon_remove_zone_list from '../../../styles/icons/icon_remove_zone_list.svg';

import { MapContext } from '../../../context/index';

export default function Zone_current_card_car(props) {
    let {map_object} = useContext(MapContext)
    let car_id = props.car_id
    let car = {
        'id': -1,
        'car_number': '-',
        'brand': '-'
    }

    for (let car_of_cars of props.cars){
        if (car_of_cars.id == car_id){
            car = car_of_cars

            if (!car.car_number){
                car.car_number = '-'
            }
            if (!car.brand){
                car.brand = '-'
            }
        }
    }

    function open_remove_car(e){
        let car_id = e.currentTarget.getAttribute('data_car_id')
        let geozone_id = e.currentTarget.getAttribute('data_geozone_id')
        map_object.open_remove_car_from_zone(geozone_id, car_id)
    }

    return (
        <div className='div_car_zone_list'>
            <div className='number_car_zone_list'>
                {car.car_number}
            </div>
            <div className='brand_car_zone_list'>
                {car.brand}
            </div>
            <div className='div_icon_remove_zone_list'>
                <img src={icon_remove_zone_list} className="icon_remove_zone_list" data_car_id={car.id} data_geozone_id={props.geozone.id} onClick={(e) => open_remove_car(e)}/>
            </div>
        </div>
    )
}