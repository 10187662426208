import React, { useEffect, useState } from 'react';
import '../../styles/routes_map/routes.css'

import Navbar_routes from "../map/navbar/navbar_routes" 
import Car_list_window_routes from "./car_list/car_list_window_routes" 
import Car_routes_window_routes from "./routes/car_routes_window_routes" 
import Car_zones_window_routes from "../map/zones/car_zones_window_routes" 
import { MapContext } from '../../context/index';

export default function Routes_window() {

    let {map_object} = React.useContext(MapContext)

    const [navbar_item, setNavBar_item] = useState('cars')

    map_object.navbar_item = navbar_item

    return (
        <div className='window_routes_card'>
            <div style={{'position': 'relative', 'height': '100%',}}>
                <Navbar_routes navbar_item={navbar_item} setNavBar_item={setNavBar_item}/>
                <div className='main_card_window_routes'>
                    <Car_list_window_routes item={navbar_item} setNavBar_item={setNavBar_item}/>
                    <Car_routes_window_routes item={navbar_item} setNavBar_item={setNavBar_item}/>
                    <Car_zones_window_routes item={navbar_item} setNavBar_item={setNavBar_item}/>
                </div>
            </div>
        </div>
    )
}