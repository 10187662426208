/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react'
import '../../styles/navbar/navbar_main.css'

import Logo from '../../styles/icons/logo.svg'
import map_marker_icon from '../../styles/icons/map_marker.svg'
import dashboard_icon from '../../styles/icons/dashboard.svg'
import list_icon from '../../styles/icons/list_navbar.svg'
import car_icon from '../../styles/icons/car_navbar.svg'
import driver_icon from '../../styles/icons/users-alt.svg'
import settings_icon from '../../styles/icons/settings_navbar.svg'
import Navbar_item from './navbar_item'
import Navbar_user from './navbar_user'
import { useSelector } from 'react-redux'

export default function Draw_nav_bar_main() {
  const [menu_open, setMenu_open] = useState(false)

  function open_close_menu() {
    if (menu_open) {
      setMenu_open(false)
    } else {
      setMenu_open(true)
    }
  }

  const user_current = useSelector((state) => state.user_current)
  const page_current = useSelector((state) => state.page_current)

  let settings_access = user_current.permissions.includes('SETTINGS')

  function go_to_map() {
    window.history.replaceState(null, 'New Page Title', '/map')
    window.location.reload()
  }

  return (
    <div className='navbar_main'>
      <div style={{ paddingLeft: '20px', marginRight: '60px' }}>
        <img src={Logo} className='main_logo' onClick={go_to_map} />
      </div>
      <div className='navbar_items'>
        <Navbar_item text={'Карта'} img={map_marker_icon} page={'map'} active_page={page_current} />
        <Navbar_item
          text={'Дашборд'}
          img={dashboard_icon}
          page={'dashboard'}
          active_page={page_current}
        />
        <Navbar_item text={'Отчет'} img={list_icon} page={'report'} active_page={page_current} />
        <Navbar_item text={'Авто'} img={car_icon} page={'car_list'} active_page={page_current} />
        {user_current && user_current.balance_blocking ? (
          <Navbar_item
            text={'Водители'}
            img={driver_icon}
            page={'drivers_list'}
            active_page={page_current}
          />
        ) : (
          <></>
        )}
        {settings_access ? (
          <Navbar_item
            text={'Настройки'}
            img={settings_icon}
            page={'park_settings'}
            active_page={page_current}
          />
        ) : (
          <></>
        )}
      </div>
      <Navbar_user change_status={open_close_menu} status={menu_open} user={user_current} />
    </div>
  )
}
