import React, { useEffect, useState, useContext } from 'react';
import '../../../styles/park_settings/main_park_settings.css'
import Switch from "react-switch";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import Btn_defolt from '../../UI/btn_defolt.jsx'
import Fake_btn_defolt from '../../UI/fake_btn_defolt.jsx'

import axios_api from "../../../API/axios_api"
import { useCookies } from 'react-cookie';

export default function Driving_quality(props) {
    const [cookies] = useCookies(['csrftoken']);

    // "driving_accuracy_monitoring": true,
    // "DAM_speed_limit": 0,
    // "DAM_angle_speed_limit": 0,
    // "DAM_acceleration_limit": 0,

    const [is_driving_quality, setIs_driving_quality] = useState(false)
    const [DAM_speed_limit, setDAM_speed_limit] = useState('')
    const [DAM_angle_speed_limit, setDAM_angle_speed_limit] = useState('')
    const [DAM_acceleration_limit, setDAM_acceleration_limit] = useState('')

    const [status_save_edit_btn, setStatus_save_edit_btn] = useState(null)

    useEffect(() => {
        if (props.settings_current){
            setIs_driving_quality(props.settings_current.driving_accuracy_monitoring)
            setDAM_speed_limit(props.settings_current.DAM_speed_limit)
            setDAM_angle_speed_limit(props.settings_current.DAM_angle_speed_limit)
            setDAM_acceleration_limit(props.settings_current.DAM_acceleration_limit)
        }
    }, []);

    async function save_edit(){

        let response_edit = await axios_api.driving_quality_edit(is_driving_quality, DAM_speed_limit, DAM_angle_speed_limit, DAM_acceleration_limit, cookies.csrftoken)

        if (response_edit.status == '200'){
            setStatus_save_edit_btn('success')
        } else {
            setStatus_save_edit_btn('error')
        }
        
        setTimeout(
            function(){
                setStatus_save_edit_btn(null)
            }, 2000
        );

        props.update_all()
    }

    return (
        <div className='div_main_setting'>
            <div className='header_refill_settings'>
                <div className='text_header_refill_settings'>
                    Качество вождения
                </div>
            </div>
            <div style={{'marginTop': '30px', 'marginLeft': '16px'}}>
                <div style={{'marginTop': '30px', 'display': 'flex', 'alignItems': 'center', 'marginBottom': '20px'}}>
                    <Switch onChange={(e) => setIs_driving_quality(!is_driving_quality)} checked={is_driving_quality} uncheckedIcon={false} checkedIcon={false} height={20} width={36} offColor={'#E7ECFB'} onColor={'#6486FB'} activeBoxShadow={'0 0 2px 3px #6486FB'}/>
                    <div className={is_driving_quality ? "text_checkbox_with_text active" : "text_checkbox_with_text"} style={{'marginLeft': '10px'}}>
                        Включить оценку качества вождения
                    </div>
                </div>
                <SlideDown className={'my-dropdown-slidedown'}>
                    {is_driving_quality
                        ?
                            <>
                                <div style={{'display': 'flex', 'alignItems': 'center'}}>
                                    <div className={"text_checkbox_with_text"} style={{'marginLeft': '0px'}}>
                                        Порог скорости
                                    </div>
                                    <input className="input_money_refill_settings" type="text" value={DAM_speed_limit} onChange={(e) => setDAM_speed_limit(e.target.value)} style={{'marginLeft': '20px', 'marginRight': '10px', 'width': '70px'}}></input>
                                    <div className={"text_checkbox_with_text"}>
                                        км/ч
                                    </div>
                                </div>
                                <div style={{'display': 'flex', 'alignItems': 'center', 'marginTop': '30px'}}>
                                    <div className={"text_checkbox_with_text"} style={{'marginLeft': '0px'}}>
                                        Порог угловой скорости
                                    </div>
                                    <input className="input_money_refill_settings" type="text" value={DAM_angle_speed_limit} onChange={(e) => setDAM_angle_speed_limit(e.target.value)} style={{'marginLeft': '20px', 'marginRight': '10px', 'width': '70px'}}></input>
                                    <div className={"text_checkbox_with_text"}>
                                        град/с
                                    </div>
                                </div>
                                <div style={{'display': 'flex', 'alignItems': 'center', 'marginTop': '30px'}}>
                                    <div className={"text_checkbox_with_text"} style={{'marginLeft': '0px'}}>
                                        Порог ускорения
                                    </div>
                                    <input className="input_money_refill_settings" type="text" value={DAM_acceleration_limit} onChange={(e) => setDAM_acceleration_limit(e.target.value)} style={{'marginLeft': '20px', 'marginRight': '10px', 'width': '70px'}}></input>
                                    <div className={"text_checkbox_with_text"}>
                                        м/с^2
                                    </div>
                                </div>
                            </>
                        :
                            <></>
                    }
                </SlideDown>
                <div style={{'marginTop': '30px'}}>
                    {status_save_edit_btn
                        ?
                            <>
                                {status_save_edit_btn == 'success'
                                    ?
                                        <Fake_btn_defolt color={'#0acf97'} text_color={'#FFFFFF'} hover_color={'#0acf97'} text={'Сохранено!'} height={'40px'} width={'250px'}/>
                                    :   
                                        <Fake_btn_defolt color={'#fa5c7c'} text_color={'#FFFFFF'} hover_color={'#fa5c7c'} text={'Ошибка!'} height={'40px'} width={'250px'}/>                                
                                }
                            </>
                        :   
                            <Btn_defolt color={'#0acf97'} text_color={'#FFFFFF'} hover_color={'#07b584'} text={'Сохранить изменения'} height={'40px'} width={'250px'} onClick={save_edit}/>
                    }
                </div>
            </div>
        </div>
    )
}