import { createContext } from "react"

export const AuthContext = createContext(null);

export const MapContext = createContext(null);
export const DashboardContext = createContext(null);
export const CarListContext = createContext(null);
export const ParkSettingsContext = createContext(null);
export const ReportContext = createContext(null);
export const DriversContext = createContext(null);

export const TestContext = createContext(null);