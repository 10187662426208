import React, { useEffect, useState } from 'react';
import '../../styles/components/components.css'
import search_icon from "../../styles/icons/search_icon.svg" 



export default function Search_input(props) {
    //console.log('props : ', props)

    function change_input(new_value){
        //console.log('new_value : ', new_value.target.value)
        setSearch(new_value.target.value)
        props.change(new_value.target.value)

    }

    const [search, setSearch] = useState('')

    return (
        <div className='main_div_input_search'>
            <div className='div_input_search'>
                <img src={search_icon}/>
                <input type="text" value={search} placeholder={props.placeholder} className='input_search' onChange={change_input}/>
                <div className='div_border_input_search'>
                </div>
            </div>
        </div>
    )
}