/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import axios_api from '../../../../API/axios_api'
import Param from './param'
import { useCookies } from 'react-cookie'
import Btn_defolt from '../../../UI/btn_defolt.jsx'
import '../../../../styles/park_settings/main_park_settings.css'

export default function Params(props) {
  const [cookies] = useCookies(['csrftoken'])

  const newParamObj = () => {
    return {
      edited: false,
      status: 'new',
      error: '',
      verbose_name: '',
      period_type: 'MILEAGE', // MILEAGE, MILEAGE_RANGES, TIME
      value_period: '10000',
      mileage_ranges: ['10000', '10000', '10000', '10000'],
      id: new Date().getTime(),
    }
  }

  const prepareParams = async (inspection) => {
    if (inspection === null) {
      const arr = [newParamObj()]
      props.setParams(arr)
      props.setRender(new Date().getTime())
    } else {
      const arr = []
      let paramsRaw = await axios_api.get_inspection_params(cookies.csrftoken)
      if (paramsRaw) {
        for (let item of paramsRaw) {
          if (item.inspection_group === inspection.id) {
            if (
              (item.period_type === 'MILEAGE_RANGES' &&
                item.mileage_ranges.length !== 4) ||
              item.period_type !== 'MILEAGE_RANGES'
            ) {
              item.mileage_ranges = ['10000', '10000', '10000', '10000']
            }
            item.edited = false
            arr.push(item)
          }
        }
      }
      arr.sort(function (a, b) {
        return a.id - b.id
      })
      props.setParams(arr)
      props.setRender(new Date().getTime())
    }
  }

  const addParam = () => {
    props.params.push(newParamObj())
    props.setParams(props.params)
    props.setRender(new Date().getTime())
  }

  useEffect(() => {
    prepareParams(props.inspection)
  }, [props.inspection])

  return (
    <div className="">
      {props.params.map((param) => (
        <Param
          key={param.id}
          param={param}
          number={props.params.indexOf(param)}
          params={props.params}
          setParams={props.setParams}
          render={props.render}
          setRender={props.setRender}
        />
      ))}
      <div style={{ marginTop: '16px' }}>
        <Btn_defolt
          color={'#eff3ff'}
          text_color={'#6486fb'}
          hover_color={'#dbe4ff'}
          text={'+ Добавить новый параметр'}
          height={'40px'}
          width={'250px'}
          onClick={addParam}
        />
      </div>
    </div>
  )
}
