import React, { useContext } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { AuthContext } from '../context'
import { privateRoutes, publicRoutes } from '../router'
import Draw_nav_bar_main from './navbar/draw_nav_bar'
import { useSelector } from 'react-redux'

const AppRouter = () => {
  const location = useLocation()
  const park_current = useSelector((state) => state.park_current)
  const user_current = useSelector((state) => state.user_current)

  const { Auth, isLoading } = useContext(AuthContext)

  if (isLoading) {
    return <div></div>
  }

  return Auth.isAuth ? (
    <>
      {park_current && user_current ? (
        <>
          {location.pathname !== '/share' && <Draw_nav_bar_main />}
          <Routes>
            {privateRoutes.map((route) => (
              <Route
                element={<route.component />}
                path={route.path}
                exact={route.exact}
                key={route.path}
              />
            ))}
          </Routes>
        </>
      ) : (
        <></>
      )}
    </>
  ) : (
    <Routes>
      {publicRoutes.map((route) => (
        <Route
          element={<route.component />}
          path={route.path}
          exact={route.exact}
          key={route.path}
        />
      ))}
    </Routes>
  )
}

export default AppRouter
