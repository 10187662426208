import React, { useEffect, useState, useContext } from 'react';

import '../../styles/car_list/car_list_main.css'

import { useCookies } from 'react-cookie';
import axios_api from "../../API/axios_api"
import map_api from "../../API/map_api"
import { CarListContext } from '../../context/index';
import { MapContext } from '../../context/index';
import Basic_select from '../UI/basic_select.jsx'
import ReactLoading from "react-loading";

import Chart from "react-apexcharts";

export default function Car_chart_mileage(props) {
    let {car_list_object} = useContext(CarListContext)
    const [cookies] = useCookies(['csrftoken']);

    const [car_mileage_history, setCar_mileage_history] = useState(null)
    const [time_range_filter_value, setTime_range_filter_value] = useState('WEEK')

    const [is_loading, setIs_loading] = useState(true)

    const time_range_filter = [
        ['За день', 'DAY'],
        ['За неделю', 'WEEK'],
        ['За месяц', 'MONTH'],
    ]

    useEffect(() => {
        get_car_mileage_history()
    }, [time_range_filter_value, props.car.id]);

    let state = {
        options: {
          chart: {
            id: "basic-bar",
            zoom: {
                enabled: false
            },
            toolbar: {
                show: true,
                tools:{
                    download: false // <== line to add
                }
            },
            fontFamily: 'Gilroy, Gilroy, Gilroy',
          },
          tooltip: {
            enabled: true,
            marker: {
                show: false,
            },
          },
          xaxis: {
            categories: [],
            tooltip: {
                enabled: false,
            },
            labels: {
                rotate: -45,
            },
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return Math.round(parseFloat(value));
              }
            },
          },
          colors: ['#6486FB'],
        },
        series: [
          {
            name: "Километров",
            data: []
          }
        ],
    };

    let days_of_week = [
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
        'Воскресенье'
    ]

    let day_of_week_current = -1

    if (car_mileage_history != null){
        if (time_range_filter_value == 'WEEK'){
            for (let piece of car_mileage_history){
                // day_of_week: 2
                // label: "00:00"     ​​
                // value: 1
        
                if (piece.day_of_week != day_of_week_current){
                    state.options.xaxis.categories.push(days_of_week[piece.day_of_week])
                    day_of_week_current = piece.day_of_week
                } else {
                    state.options.xaxis.categories.push('')
                }
                if (piece.value > 0){
                    state.options.yaxis.min = 0
                }
                state.series[0].data.push(Math.round(parseFloat(piece.value)))
            }
        } else {
            for (let piece of car_mileage_history){
                // day_of_week: 2
                // label: "00:00"     ​​
                // value: 1
                state.options.xaxis.categories.push(piece.label)
                state.series[0].data.push(Math.round(parseFloat(piece.value)))
                if (piece.value > 0){
                    state.options.yaxis.min = 0
                }
            }
        }
    }

    return (
        <div className='car_chart_mileage_main'>
            <div style={{'display': 'flex', 'alignItems': 'center'}}>
                <div className='car_profile_chart_header'>
                    График пробега
                </div>
                <div style={{'marginLeft': '20px'}}>
                    <Basic_select current_value={time_range_filter_value} all_values={time_range_filter} setValue={setTime_range_filter_value} width={'180px'}/>
                </div>
            </div>
            <div>
                {is_loading == false
                    ?
                    <>
                        {car_mileage_history != null
                            ?
                                <Chart
                                    options={state.options}
                                    series={state.series}
                                    type="line"
                                    width="100%"
                                    height='300px'
                                />
                            :
                            <div className='no_data_car_chart_mileage'>Нет данных</div>
                        }
                    </>
                    :
                    <div style={{'height': '300px', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center'}}><ReactLoading type={'spin'} color="#6486FB" /></div>
                }
            </div>
        </div>
    )

    async function get_car_mileage_history(){
        setIs_loading(true)
        let car_mileage_history_new = await axios_api.car_history(cookies.csrftoken, time_range_filter_value, 'mileage', props.car.id)
        console.log('car_mileage_history_new : ', car_mileage_history_new)

        if (car_mileage_history_new == 'error'){
            setCar_mileage_history(null)
            setIs_loading(false)
            return
        }

        if (!car_mileage_history_new.length){
            setCar_mileage_history([])
        } else {
            setCar_mileage_history(car_mileage_history_new)
        }
        setIs_loading(false)
    }
}