import React, { useEffect, useState, useContext } from 'react'
import '../../../../styles/park_settings/main_park_settings.css'
import plus_white from '../../../../styles/icons/plus_white.svg'
import Icon_btn from '../../../UI/icon_btn.jsx'
import ReactLoading from 'react-loading'
import Table_item from './table_item'
import Modal_add_aggregator from './modal_add_aggregator'
import Btn_defolt from '../../../UI/btn_defolt.jsx'
import Basic_input from '../../../UI/basic_input.jsx'
import { useDispatch, useSelector } from 'react-redux'
import SimpleBar from 'simplebar-react'
import { useCookies } from 'react-cookie'
import axios_api from '../../../../API/axios_api'

export default function Aggregators_list(props) {
  const [cookies] = useCookies(['csrftoken'])
  const window_height = useSelector((state) => state.window_height)
  const simplebar_height = window_height - 70 - 24 - 24 - 140

  const [modal_add_active, setActive_modal_add] = useState(false)
  const [status_modal_add, setStatus_modal_add] = useState('')

  const [loading_add, setLoading_add] = useState(false)

  const [name, setName] = useState('')
  const [yandex_api_key, setYandex_api_key] = useState('')
  const [yandex_client_id, setYandex_client_id] = useState('')
  const [yandex_park_id, setYandex_park_id] = useState('')

  const [name_status, setName_status] = useState('active')
  const [yandex_api_key_status, setYandex_api_key_status] = useState('active')
  const [yandex_client_id_status, setYandex_client_id_status] = useState('active')
  const [yandex_park_id_status, setYandex_park_id_status] = useState('active')

  function row_click(id) {
    for (let aggregator of props.aggregators) {
      if (aggregator.id == id) {
        props.setAggregator(aggregator)
        props.setPage('edit')
      }
    }
  }

  function open_add_aggregator() {
    setActive_modal_add(true)
  }

  function close_add() {
    setActive_modal_add(false)
  }

  async function submite_add() {
    if (name == '') {
      setName_status('error')
      setTimeout(() => {
        setName_status('active')
      }, 1000)
      return
    }
    if (yandex_api_key == '') {
      setYandex_api_key_status('error')
      setTimeout(() => {
        setYandex_api_key_status('active')
      }, 1000)
      return
    }
    if (yandex_client_id == '') {
      setYandex_client_id_status('error')
      setTimeout(() => {
        setYandex_client_id_status('active')
      }, 1000)
      return
    }
    if (yandex_park_id == '') {
      setYandex_park_id_status('error')
      setTimeout(() => {
        setYandex_park_id_status('active')
      }, 1000)
      return
    }

    setLoading_add(true)
    let response_add = await axios_api.post_new_aggregator(
      name,
      yandex_api_key,
      yandex_client_id,
      yandex_park_id,
      cookies.csrftoken,
    )
    setLoading_add(false)

    if (response_add == 'success') {
      setStatus_modal_add('success')

      setTimeout(function () {
        setStatus_modal_add('')
        close_add()
        setName('')
        setYandex_api_key('')
        setYandex_client_id('')
        setYandex_park_id('')
        props.update_all()
      }, 1000)
    } else {
      setStatus_modal_add('error')
      setYandex_api_key_status('error')
      setYandex_client_id_status('error')
      setYandex_park_id_status('error')

      setTimeout(function () {
        setStatus_modal_add('')
      }, 1000)

      setTimeout(function () {
        setYandex_api_key_status('active')
        setYandex_client_id_status('active')
        setYandex_park_id_status('active')
      }, 3000)
    }

    props.update_all()
  }

  return (
    <div className='div_main_setting'>
      <div className='header_user_settings'>
        <div className='text_header_user_settings'>Агрегаторы</div>
        <Icon_btn
          color={'#6486FB'}
          hover_color={'#5179fc'}
          icon={plus_white}
          height={'36px'}
          width={'60px'}
          onClick={open_add_aggregator}
        />
      </div>
      {props.aggregators ? (
        <div style={{ width: '700px', marginTop: '24px', marginLeft: '5px' }}>
          {props.aggregators.length ? (
            <div className='table_aggregators_settings' style={{ marginTop: '16px' }}>
              <SimpleBar style={{ maxHeight: simplebar_height }}>
                {props.aggregators.map((aggregator) => (
                  <Table_item key={aggregator.id} aggregator={aggregator} row_click={row_click} />
                ))}
              </SimpleBar>
            </div>
          ) : (
            <div className='text_checkbox_with_text'>Агрегаторы отсутствуют</div>
          )}
        </div>
      ) : (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ReactLoading type={'spin'} color='#6486FB' />
        </div>
      )}
      <Modal_add_aggregator active={modal_add_active} setActive={close_add}>
        {!loading_add ? (
          <>
            <div className='text_create_group'>Добавить агрегатора</div>
            <div style={{ marginTop: '30px' }}>
              <Basic_input
                value={name}
                text={'Название'}
                status={name_status}
                width={'100%'}
                type={'text'}
                onChange={setName}
              />
            </div>
            <div style={{ marginTop: '30px' }}>
              <Basic_input
                value={yandex_client_id}
                text={'Client ID'}
                status={yandex_client_id_status}
                width={'100%'}
                type={'text'}
                onChange={setYandex_client_id}
              />
            </div>
            <div style={{ marginTop: '30px' }}>
              <Basic_input
                value={yandex_api_key}
                text={'API KEY'}
                status={yandex_api_key_status}
                width={'100%'}
                type={'text'}
                onChange={setYandex_api_key}
              />
            </div>
            <div style={{ marginTop: '30px' }}>
              <Basic_input
                value={yandex_park_id}
                text={'Park ID'}
                status={yandex_park_id_status}
                width={'100%'}
                type={'text'}
                onChange={setYandex_park_id}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '35px' }}>
              <Btn_defolt
                color={'#EFF3FF'}
                text_color={'#6486FB'}
                hover_color={'#dbe4ff'}
                text={'Отмена'}
                height={'40px'}
                width={'180px'}
                onClick={close_add}
              />
              <Btn_defolt
                color={'#6486FB'}
                text_color={'#FFFFFF'}
                hover_color={'#5179fc'}
                text={'Добавить'}
                height={'40px'}
                width={'180px'}
                onClick={submite_add}
              />
            </div>
            <div
              className={
                status_modal_add == 'success'
                  ? 'status_modal_success active'
                  : 'status_modal_success'
              }
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='text_status_main'>Успешно!</div>
              </div>
            </div>
            <div
              className={
                status_modal_add == 'error' ? 'status_modal_error active' : 'status_modal_error'
              }
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='text_status_main'>Ошибка!</div>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              height: '542px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ReactLoading type={'spin'} color='#6486FB' />
          </div>
        )}
      </Modal_add_aggregator>
    </div>
  )
}
