import React, { useEffect, useState, useContext } from 'react';

import '../../styles/dashboard/dashboard_main.css'

import { useCookies } from 'react-cookie';
import axios_api from "../../API/axios_api"
import map_api from "../../API/map_api"
import { DashboardContext } from '../../context/index';
import { MapContext } from '../../context/index';
import SimpleBar from 'simplebar-react';
import Info_card from "./info_card"
import Card_top_mil from "./card_top_mil"
import Card_driving_issues from "./card_driving_issues"
import Card_payment_history from "./card_payment_history"
import Сharts_card from "./charts_card"
import Groups_cars_filter from '../UI/groups_cars_filter.jsx'
import Notifications from './notifications.jsx'

export default function Draw_dashboard() {
    let {dashboard_object} = useContext(DashboardContext)
    const [cookies] = useCookies(['csrftoken']);

    const [groups, setGroups] = useState(null)
    const [cars_delailed, setCars_delailed] = useState(null)
    const [notifications, setNotifications] = useState(null)

    //groups_filter
    const [group_sort_id, setGroup_sort_id] = useState('ALL')
    //end groups_filter


    useEffect(() => {
        get_groups()
        get_cars_detailed()
        get_notifications()
    }, []);

    if (dashboard_object.first_init_dashboard){
        setInterval(() => {
            get_groups()
            get_cars_detailed()
            get_notifications()
        }, 60000);
        dashboard_object.first_init_dashboard = false
    }

    return (
        <div className='div_main_dashboard'>
            <div className='group_cars_text'>
                Группа автомобилей
            </div>
            <div>
                <div style={{'width': '340px', 'marginTop': '25px'}}>
                    {cars_delailed && groups
                        ?
                            <Groups_cars_filter groups={groups} cars={cars_delailed} group_sort_id={group_sort_id} setGroup_sort_id={setGroup_sort_id} text_all={'Выбрать группу'}/>
                        :   
                            <Groups_cars_filter groups={[]} cars={[]} group_sort_id={group_sort_id} setGroup_sort_id={setGroup_sort_id} text_all={'Выбрать группу'}/>
                    }
                </div>
            </div>
            <div className='cards_info_div' style={{'marginTop': '30px'}}>
                <div>
                    <Info_card bg_color={'#DEF2FF'} text_color={'#366397'} text={'Всего'} value={'ALL_CARS'} cars={cars_delailed} group_sort_id={group_sort_id}/>
                </div>
                <div style={{'marginLeft': '24px'}}>
                    <Info_card bg_color={'#DCF2E8'} text_color={'#128756'} text={'На линии'} value={'ONLINE_CARS'} cars={cars_delailed} group_sort_id={group_sort_id}/>
                </div>
                <div style={{'marginLeft': '24px'}}>
                    <Info_card bg_color={'#FEE3E3'} text_color={'#CF4660'} text={'В ожидании'} value={'OFFLINE_CARS'} cars={cars_delailed} group_sort_id={group_sort_id}/>
                </div>
            </div>
            <div style={{'display': 'flex', 'marginTop': '24px'}}>
                <div style={{'width': 'calc(100% - 400px - 24px)'}}>
                    <div style={{'display': 'flex'}}>
                        <Card_top_mil group_sort_id={group_sort_id}/>
                        <Card_driving_issues group_sort_id={group_sort_id}/>
                    </div>
                    <div style={{'marginTop': '24px'}}>
                        <Сharts_card group_sort_id={group_sort_id}/>
                    </div>
                    {/* <div style={{'marginTop': '24px'}}>
                        <Card_payment_history group_sort_id={group_sort_id}/>
                    </div> */}
                </div>
                <div style={{'marginLeft': '24px', 'width': '400px'}}>
                    <Notifications notifications={notifications} get_notifications={get_notifications} group_sort_id={group_sort_id}/>
                </div>
            </div>
        </div>
    )

    async function get_groups(){
        let groups_new = await axios_api.get_groups(cookies.csrftoken)
        console.log('dashboard new groups : ', groups_new)
        setGroups(groups_new)
    }

    async function get_cars_detailed(){
        let cars = await axios_api.get_cars_detailed(cookies.csrftoken)
        console.log('dashboard new cars detailed : ', cars)
        setCars_delailed(cars)
    }

    // async function get_cars_top_mil(){
    //     let cars_top_mil = await axios_api.get_cars_top_mil(cookies.csrftoken)
    //     console.log('dashboard new cars_top_mil : ', cars_top_mil)
    //     setCars_top_mil(cars_top_mil)
    // }

    // async function get_cars_payment_history(){
    //     let cars_payment_history = await axios_api.get_cars_payment_history(cookies.csrftoken)
    //     console.log('dashboard new cars_payment_history : ', cars_payment_history)
    //     setCars_payment_history(cars_payment_history)
    // }

    async function get_notifications(){
        //console.log('reload_notifications')
        let notifications_new = await axios_api.get_notifications(cookies.csrftoken)
        console.log('dashboard new notifications : ', notifications_new)
        setNotifications(notifications_new)
    }

}