import React, { useEffect, useState } from 'react';
import '../../../styles/routes_map/routes.css'

import Navbar_routes_item from "./navbar_routes_item" 

import route_icon_routes from '../../../styles/icons/route_icon_routes.svg';
import car_icon_routes from '../../../styles/icons/car_icon_routes.svg';
import zone_icon_routes from '../../../styles/icons/zone_icon_routes.svg';
import { MapContext } from '../../../context/index';

export default function Navbar_routes(props) {
    
    let {map_object} = React.useContext(MapContext)

    function change_to_cars() {
        map_object.info_draw_routes.reload = false
        props.setNavBar_item('cars')
    }

    function change_to_routes() {
        //map_object.info_draw_routes.reload = false
        map_object.info_draw_routes.draw_routes = true
        props.setNavBar_item('routes')
    }
    
    function change_to_zones() {
        map_object.info_draw_routes.reload = false
        props.setNavBar_item('zones')
    }

    return (
        <div className='navbar_routes_div'>
            <Navbar_routes_item current_item={props.navbar_item} img={car_icon_routes} item='cars' onClick={change_to_cars}/>
            <Navbar_routes_item current_item={props.navbar_item} img={route_icon_routes} item='routes' onClick={change_to_routes}/>
            <Navbar_routes_item current_item={props.navbar_item} img={zone_icon_routes} item='zones' onClick={change_to_zones}/>            
        </div>
    )
}