import React, { useEffect, useState, useContext } from 'react';

import '../../../styles/notifications/notifications.css'

import Btn_defolt from '../../UI/btn_defolt'

import axios_api from "../../../API/axios_api"
import { useCookies } from 'react-cookie';
import SimpleBar from 'simplebar-react';

import Modal_with_accept from '../../UI/modal_with_accept'
import { MapContext } from '../../../context/index';
import Notification_card from './notification_card'

import {useDispatch, useSelector} from 'react-redux'

export default function Main_notifications() {
    const window_height = useSelector(state => state.window_height)
    const simplebar_height = window_height - 70 - 24 - 24 - 56

    let {map_object} = useContext(MapContext)
    const [cookies] = useCookies(['csrftoken']);
    //const [resize, setResize] = useState(false)

    useEffect(() => {
        reload_notifications()
    }, []);

    if (map_object.first_init_notifications){
        setInterval(() => {
            reload_notifications()
        }, 60000);
        map_object.first_init_notifications = false
    }


    const [notifications, setNotifications] = useState(null)
    const [open_notifications, setOpen_notifications] = useState(false)
    const [show_btn_slideup, setShow_btn_slideup] = useState('none')

    //modal accept_hide
    const [modal_hide_notification_active, setActive_modal_hide_notification] = useState(false)
    const [status_modal_hide_notification, setStatus_modal_hide_notification] = useState('')

    const [notification_id, setNotification_id] = useState(null)
    const [notification_message, setNotification_message] = useState(null)
    const [notification_car_number, setNotification_car_number] = useState(null)
    //

    let notification_count = 0
    if (notifications){
        for (let notification of notifications){
            if (open_notifications){
                notification.visible_status = 'active' 
            } else {
                if (notification_count > 2){
                    notification.visible_status = 'not_active' 
                }
                if (notification_count ==  2){
                    notification.visible_status = 'last' 
                }
                if (notification_count < 2){
                    notification.visible_status = 'active' 
                }
                notification_count = notification_count + 1
            }
        }
    }

    function open_all_notifications(){
        setOpen_notifications(true)
        setShow_btn_slideup('flex')
    }

    function close_all_notifications(){
        setOpen_notifications(false)
        setShow_btn_slideup('none')
    }

    function hide_notification(e){
        e.stopPropagation()
        setNotification_id(e.currentTarget.getAttribute('data_id'))
        setNotification_message(e.currentTarget.getAttribute('data_message'))
        setNotification_car_number(e.currentTarget.getAttribute('data_car_number'))

        setActive_modal_hide_notification(true)
    }

    async function accept_hide_notification(){
        let response = await axios_api.hide_notification(notification_id, cookies.csrftoken)

        if (response.status == '200'){
            setStatus_modal_hide_notification('success')
        } else {
            setStatus_modal_hide_notification('error')
        }

        setTimeout(
            function(){
                setActive_modal_hide_notification(false)
                setStatus_modal_hide_notification('')
            }, 1000
        );

        reload_notifications()
    }

    return (
        <div className=''>
            {notifications
                ?
                    <div className='main_div_notifications'>
                        <SimpleBar style={{ maxHeight: simplebar_height }}>
                            {notifications.map((notification) => 
                                <Notification_card key={notification.id} notification={notification} open_all_notifications={open_all_notifications} hide_notification={hide_notification}/>
                            )}
                        </SimpleBar>
                        <div style={{'display' : show_btn_slideup}}>
                            <div style={{'display' : 'flex', 'justifyContent': 'center', 'marginTop': '16px', 'width': '100%'}}>
                                <Btn_defolt color={'#EFF3FF'} text_color={'#6486FB'} hover_color={'#dbe4ff'} text={'Свернуть'} height={'40px'} width={'276px'} onClick={close_all_notifications}/>
                            </div>
                        </div>
                    </div>
                :
                    <></>
            }
            <Modal_with_accept active={modal_hide_notification_active} setActive={setActive_modal_hide_notification}>
                <div className='text_accept_delete'>
                    Вы уверены, что хотите скрыть уведомление "{notification_message}" у авто "{notification_car_number}"?
                </div>
                <div style={{'display' : 'flex', 'justifyContent': 'space-between', 'marginTop': '30px'}}>
                    <Btn_defolt color={'#EFF3FF'} text_color={'#6486FB'} hover_color={'#dbe4ff'} text={'Отмена'} height={'40px'} width={'150px'} onClick={(e) => setActive_modal_hide_notification(false)}/>
                    <Btn_defolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Скрыть'} height={'40px'} width={'150px'} onClick={accept_hide_notification}/>
                </div>
                <div className={status_modal_hide_notification == 'success' ? "status_modal_success active" : "status_modal_success"}>
                    <div style={{'display': 'flex', 'flexDirection': 'column'}}>   
                        <div className='text_status_main'>
                            Успешно!
                        </div>
                        <div className='text_status_info'>
                            Уведомление скрыто!
                        </div>
                    </div>
                </div>
                <div className={status_modal_hide_notification == 'error' ? "status_modal_error active" : "status_modal_error"}>
                    <div style={{'display': 'flex', 'flexDirection': 'column'}}>
                        <div className='text_status_main'>
                            Ошибка!
                        </div>
                        <div className='text_status_info'>
                            Не удалось скрыть уведомление!
                        </div>
                    </div>
                </div>
            </Modal_with_accept>
        </div>
    )

    async function reload_notifications(){
        //console.log('reload_notifications')
        let response = await axios_api.get_notifications(cookies.csrftoken)
        setNotifications(response)
    }
}