import React, { useEffect, useState, useContext } from 'react';

import '../../styles/car_list/car_list_main.css'

import { useCookies } from 'react-cookie';
import axios_api from "../../API/axios_api"
import map_api from "../../API/map_api"
import { CarListContext } from '../../context/index';
import { MapContext } from '../../context/index';
import ckeck_color from "../../styles/icons/ckeck_color_icon.svg" 

export default function Car_color_item(props) {
    let {car_list_object} = useContext(CarListContext)
    const [cookies] = useCookies(['csrftoken']);

    function color_click(e){
        let color_text = e.currentTarget.getAttribute('data_color_text')
        props.color_click(color_text)
    }

    if (props.color_checked == props.text_color){
        return (
            <div className='car_color_item' style={{'background': props.color}}>
                <img src={ckeck_color} style={{'marginLeft': '9px', 'marginBottom': '2px'}}/>
            </div>
        )
    } else {
        return (
            <div className='car_color_item' data_color_text={props.text_color} style={{'background': props.color}} onClick={(e) => color_click(e)}>
                
            </div>
        )
    }
}