/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react'
import './CarMobile.css'
import Basic_input from '../../UI/basic_input'
import Btn_defolt from '../../UI/btn_defolt'
import Basic_modal from '../../UI/basic_modal'
import axios_api from '../../../API/axios_api'
import { useCookies } from 'react-cookie'

export default function CarMobileAddModal(props) {
  const [cookies] = useCookies(['csrftoken'])
  const [carNumber, setCarNumber] = useState('')
  const [fio, setFio] = useState('')
  const [lifetime, setLifetime] = useState('1')

  const [carNumberStatus, setCarNumberStatus] = useState('active')
  const [fioStatus, setFioStatus] = useState('active')
  const [lifetimeStatus, setLifetimeStatus] = useState('active')

  const [modalStatus, setModalStatus] = useState('')

  const postCar = async () => {
    if (carNumber === '') {
      setCarNumberStatus('error')
      setTimeout(() => {
        setCarNumberStatus('active')
      }, 4000)
      return
    }
    if (fio === '') {
      setFioStatus('error')
      setTimeout(() => {
        setFioStatus('active')
      }, 4000)
      return
    }
    if (lifetime === '' || /[a-zа-яё]/i.test(lifetime)) {
      setLifetimeStatus('error')
      setTimeout(() => {
        setLifetimeStatus('active')
      }, 4000)
      return
    }
    let response = await axios_api.postMobileCar(carNumber, fio, lifetime, cookies.csrftoken)
    console.log('response postMobileCar : ', response)
    if (response.status !== 201) {
      setModalStatus('error')

      setTimeout(() => {
        setModalStatus('')
      }, 4000)
    } else {
      props.get_cars_detailed()
      props.get_cars_brands_and_models()
      setModalStatus('success')

      setTimeout(() => {
        setCarNumber('')
        setFio('')
        setLifetime('1')
        setModalStatus('')
        props.setActive(false)
      }, 4000)
    }
  }

  return (
    <Basic_modal active={props.active} setActive={props.setActive}>
      <div className='text_accept_delete' style={{ textAlign: 'center' }}>
        Добавить временное авто
      </div>
      <div style={{ width: '450px' }}>
        <div style={{ width: '100%', marginTop: '24px' }}>
          <Basic_input
            value={carNumber}
            text={'Гос. номер авто'}
            status={carNumberStatus}
            width={'100%'}
            type={'text'}
            onChange={setCarNumber}
          />
        </div>
        <div style={{ width: '100%', marginTop: '24px' }}>
          <Basic_input
            value={fio}
            text={'ФИО водителя'}
            status={fioStatus}
            width={'100%'}
            type={'text'}
            onChange={setFio}
          />
        </div>
        <div style={{ width: '100%', marginTop: '24px' }}>
          <Basic_input
            value={lifetime}
            text={'Продолжительность в днях'}
            status={lifetimeStatus}
            width={'100%'}
            type={'text'}
            onChange={setLifetime}
          />
        </div>
        <div
          style={{
            width: '100%',
            marginTop: '24px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Btn_defolt
            color={'#EFF3FF'}
            text_color={'#6486FB'}
            hover_color={'#dbe4ff'}
            text={'Закрыть'}
            height={'40px'}
            width={'150px'}
            onClick={(e) => props.setActive(false)}
          />
          <Btn_defolt
            color={'#6486FB'}
            text_color={'#FFFFFF'}
            hover_color={'#5179fc'}
            text={'Добавить'}
            height={'40px'}
            width={'150px'}
            onClick={postCar}
          />
        </div>
        <div
          className={
            modalStatus === 'success' ? 'status_modal_success active' : 'status_modal_success'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>Успешно!</div>
            <div className='text_status_info'>
              Код для водителя Вы можете посмотреть в профиле авто
            </div>
          </div>
        </div>
        <div
          className={modalStatus === 'error' ? 'status_modal_error active' : 'status_modal_error'}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>Ошибка!</div>
            <div className='text_status_info'></div>
          </div>
        </div>
      </div>
    </Basic_modal>
  )
}
