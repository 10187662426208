import React, { useEffect, useState, useContext } from 'react';
import '../../../../styles/park_settings/main_park_settings.css'
import arrow_left_white_icon from '../../../../styles/icons/arrow_left_white_icon.svg';
import arrow_left_blue_icon from '../../../../styles/icons/arrow_left_blue_icon.svg';
import Btn_arrow_for_settings from '../../btn_arrow_for_settings.jsx'
import Basic_input from '../../../UI/basic_input.jsx'
import Btn_defolt from '../../../UI/btn_defolt.jsx'
import { useCookies } from 'react-cookie';
import axios_api from "../../../../API/axios_api"
import Modal_with_accept from '../../../UI/modal_with_accept'

export default function Aggregator_edit(props) {
    const [cookies] = useCookies(['csrftoken']);
    const [btn_back_active, setBtn_back_active] = useState(false)
    const [modal_delete_active, setActive_modal_delete] = useState(false)
    const [status_modal_delete, setStatus_modal_delete] = useState('')

    function go_back_to_list(){
        props.setPage('list')
    }

    function fake(){

    }

    function delete_aggregator_open(){
        setActive_modal_delete(true)
    }

    async function accept_delete(){
        let response = await axios_api.delete_aggregator(props.aggregator.id, cookies.csrftoken)

        if (response.status == '204'){
            setStatus_modal_delete('success')
        } else {
            setStatus_modal_delete('error')
        }

        setTimeout(
            function(){
                setActive_modal_delete(false)
                setStatus_modal_delete('')
                if (response.status == '204'){
                    props.update_all()
                    props.setPage('list')
                }
            }, 1000
        );
    }

    return (
        <div className='div_main_setting'>
            <div className='header_group_edit_settings'>
                <div onMouseEnter={(e) => setBtn_back_active(true)} onMouseLeave={(e) => setBtn_back_active(false)} onClick={go_back_to_list} style={{'cursor': 'pointer'}}>
                    <Btn_arrow_for_settings active={btn_back_active} color={'#EFF3FF'} active_color={'#6486FB'} not_active_icon={arrow_left_blue_icon} active_icon={arrow_left_white_icon} height={'36px'} width={'36px'}/>
                </div>
                <div className='text_header_group_edit_settings'>
                    Агрегатор '{props.aggregator.name}'
                </div>
            </div>
            <div style={{'marginTop': '30px', 'marginLeft': '16px'}}>
                <Basic_input value={props.aggregator.yandex_client_id} text={'Client ID'} status={'readonly'} width={'700px'} type={'text'} onChange={fake}/>
            </div>
            <div style={{'marginTop': '30px', 'marginLeft': '16px'}}>
                <Basic_input value={props.aggregator.yandex_api_key} text={'API KEY'} status={'readonly'} width={'700px'} type={'text'} onChange={fake}/>
            </div>
            <div style={{'marginTop': '30px', 'marginLeft': '16px'}}>
                <Basic_input value={props.aggregator.yandex_park_id} text={'Park ID'} status={'readonly'} width={'700px'} type={'text'} onChange={fake}/>
            </div>

            <div style={{'marginLeft': '16px', 'marginTop': '35px'}}>
                <Btn_defolt color={'#fa5c7c'} text_color={'#FFFFFF'} hover_color={'#fa4166'} text={'Удалить агрегатора'} height={'40px'} width={'250px'} onClick={delete_aggregator_open}/>
            </div>

            <Modal_with_accept active={modal_delete_active} setActive={setActive_modal_delete}>
                <div className='text_accept_delete'>
                    Вы уверены, что хотите удалить агрегатора '{props.aggregator.name}'?
                </div>
                <div style={{'display' : 'flex', 'justifyContent': 'space-between', 'marginTop': '30px'}}>
                    <Btn_defolt color={'#EFF3FF'} text_color={'#6486FB'} hover_color={'#dbe4ff'} text={'Отмена'} height={'40px'} width={'150px'} onClick={(e) => setActive_modal_delete(false)}/>
                    <Btn_defolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Удалить'} height={'40px'} width={'150px'} onClick={accept_delete}/>
                </div>
                <div className={status_modal_delete == 'success' ? "status_modal_success active" : "status_modal_success"}>
                    <div style={{'display': 'flex', 'flexDirection': 'column'}}>   
                        <div className='text_status_main'>
                            Успешно!
                        </div>
                        <div className='text_status_info'>
                            Агрегатор удален!
                        </div>
                    </div>
                </div>
                <div className={status_modal_delete == 'error' ? "status_modal_error active" : "status_modal_error"}>
                    <div style={{'display': 'flex', 'flexDirection': 'column'}}>
                        <div className='text_status_main'>
                            Ошибка!
                        </div>
                        <div className='text_status_info'>
                            Не удалось удалить агрегатора!
                        </div>
                    </div>
                </div>
            </Modal_with_accept>
        </div>
    )
}