import React, { useEffect, useState, useContext } from 'react';
import '../../../../styles/park_settings/main_park_settings.css'
import Btn_arrow_for_settings from '../../btn_arrow_for_settings.jsx'
import arrow_right_blue_icon from '../../../../styles/icons/arrow_right_blue_icon.svg';
import arrow_right_white_icon from '../../../../styles/icons/arrow_right_white_icon.svg';



export default function Table_item(props) {

    let aggregator = props.aggregator

    const [active, setActive] = useState(false)

    function row_click(e){
        let id = e.currentTarget.getAttribute('data_id')
        props.row_click(id)
    }

    return (
        <div className=''>                                        
            <div className='table_row_aggregators_settings' data_id={aggregator.id} onMouseEnter={(e) => setActive(true)} onMouseLeave={(e) => setActive(false)} onClick={(e) => row_click(e)}>
                <div className='td_name_aggregators_settings'>
                    {aggregator.name}
                </div>
                {/* <div className='td_mail_aggregators_settings'>
                    {aggregator.type}
                </div> */}
                <div className='td_arrows_aggregators_settings'>
                    <Btn_arrow_for_settings active={active} color={'#EFF3FF'} active_color={'#6486FB'} not_active_icon={arrow_right_blue_icon} active_icon={arrow_right_white_icon} height={'36px'} width={'36px'}/>
                </div>
            </div>
            <div className='div_border_bottom_aggregators_settings'>
                <div className='border_bottom_aggregators_settings'></div>
            </div>
        </div>
    )
}