import React, { useEffect, useState, useContext } from 'react';

import '../../styles/report/charts.css'
import { useCookies } from 'react-cookie';
import axios_api from "../../API/axios_api"

export default function Info_chart(props) {
    
    let cars_with_all_metrics = []
    let all_inputs = []
    let arr = make_metrics(props.cars)
    cars_with_all_metrics = arr[0]
    all_inputs = arr[1]
    console.log('cars_with_all_metrics : ', cars_with_all_metrics)

    const [time, setTime] = useState(null)

    return (
        <div className='' style={{'width': '100%', 'marginBottom': '30px'}}>
            <div style={{'display': 'flex', 'alignItems': 'center'}}>
                <div className='title_chart' style={{'marginRight': 'auto'}}>
                    Основные данные
                </div>
            </div>
            <div style={{'width': '100%'}}>
                <table style={{'width': '100%'}}>
                    <thead>
                        <tr>
                            <th>
                            </th>
                            {all_inputs.map((input) =>
                                <th key={input.type} className='th_info_chart'>
                                    {input.verbose_name}
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {cars_with_all_metrics.map((car) =>
                            <tr key={car.id} style={{'backgroundColor': car.backgroud_color_info, 'height': '30px'}}>
                                <td className='car_info_chart'>
                                    {car.car_number}
                                </td>
                                {car.metrics_for_chart.map((input) =>
                                    <td key={input.type} className='input_info_chart'>
                                        {input.value + input.units}
                                    </td>
                                )}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )

    function make_metrics(cars){

        console.log('cars : ', cars)

        // type: "BALANCE"
        // units: "₽"
        // value: 18415615
        // verbose_name: "Баланс"

        let all_metrics = []
        let new_cars = []

        let position = 0

        for (let car of cars){
            if (!props.cars_for_report.includes(car.id)){
                continue
            }
            if (car.inputs != null){
                for (let input of car.inputs){
                    let find = false

                    for (let input_in_array of all_metrics){
                        if (input_in_array.type == input.type){
                            find = true
                        }
                    }

                    if (!find){
                        let metric = {
                            'type': input.type,
                            'units': input.units,
                            'verbose_name': input.verbose_name
                        }
                        all_metrics.push(metric)
                    }
                }
            }
            if (position % 2 == 0){
                car.backgroud_color_info = '#EFF3FF'
            } else {
                car.backgroud_color_info = '#FFFFFF'
            }
            position++
        }

        all_metrics.push({
            'type': 'mileage',
            'units': 'км',
            'verbose_name': 'Пробег'
        })

        // console.log('all_metrics : ', all_metrics)

        for (let car of cars){
            if (!props.cars_for_report.includes(car.id)){
                continue
            }

            let metrics_for_chart = []

            for (let metric of all_metrics){
                if (metric.type == 'mileage'){
                    let new_metric = {
                        'type': metric.type,
                        'units': metric.units,
                        'verbose_name': metric.verbose_name,
                        'value':  Math.round(parseFloat(car.mileage)),
                    }
                    metrics_for_chart.push(new_metric)
                    continue
                }
                let find = false
                if (car.inputs != null){
                    for (let input of car.inputs){
                        if (input.type == metric.type){
                            find = true
                            let new_metric = {
                                'type': input.type,
                                'units': input.units,
                                'verbose_name': input.verbose_name,
                                'value': Math.round(parseFloat(input.value)),
                            }
                            metrics_for_chart.push(new_metric)
                        }
                    }
                }
                if (find == false){
                    let new_metric = {
                        'type': metric.type,
                        'units': metric.units,
                        'verbose_name': metric.verbose_name,
                        'value': '-',
                    }
                    metrics_for_chart.push(new_metric)
                }
            }
            car.metrics_for_chart = metrics_for_chart
            new_cars.push(car)
        }

        let arr = [
            new_cars,
            all_metrics,
        ]

        return arr
    }
}