import React from "react";

import '../../../../styles/park_settings/main_park_settings.css'

const Modal_add_aggregator = ({active, setActive, children}) => {

    return (
        <div className={active ? "modal_add_user active" : "modal_add_user"} onClick={() => setActive()}>
            <div className="modal_add_user_content" onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );

}

export default Modal_add_aggregator;