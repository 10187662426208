import React, { useEffect, useState, useContext } from 'react';
import '../../../styles/routes_map/car_routes.css'
import Daterange_picker from '../../UI/daterange_picker'
import calendar from '../../../styles/icons/calendar.svg'

export default function Period_for_routes(props) {

    return (
        <div className='div_calendar'>
            <img src={calendar}/>
            <div className='div_datarange_pisker'>
                <Daterange_picker start_end={props.start_end} setStartEnd={props.setStartEnd}></Daterange_picker>
            </div>
        </div>
    )
}