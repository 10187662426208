import React, { useEffect, useState } from 'react';
import '../../styles/park_settings/main_park_settings.css'

export default function Btn_arrow_for_settings(props) {
    //console.log('props : ', props)

    let box_shadow = hexToRgb(props.color)

    let btn_style_defolt = {
        'background': props.color,
        'height': props.height,
        'width': props.width,
        'boxShadow': '0px 15px 35px rgba(' + box_shadow.r + ',' + box_shadow.g + ',' + box_shadow.b + ', 0.3)',
    }

    let btn_style_active = {
        'background': props.active_color,
        'height': props.height,
        'width': props.width,
        'boxShadow': '0px 15px 35px rgba(' + box_shadow.r + ',' + box_shadow.g + ',' + box_shadow.b + ', 0.4)',
    }

    let style
    let icon 
    if (props.active){
        style = btn_style_active
        icon = props.active_icon
    } else {
        style = btn_style_defolt
        icon = props.not_active_icon
    }

    function btn_click(){
        if (props.onClick){
            props.onClick()
        } else {
            
        }
    }

    return (
        <div className='div_btn_arrow_for_settings' style={style} onClick={btn_click}>
            <img src={icon}/>
        </div>
    )

    function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
    }
}