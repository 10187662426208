import React, { useEffect, useState, useContext } from 'react';
import Chart from "react-apexcharts";
import '../../styles/dashboard/charts.css'
import Basic_select from '../UI/basic_select.jsx'
import ReactLoading from "react-loading";

import { useCookies } from 'react-cookie';
import axios_api from "../../API/axios_api"

export default function Chart_activity(props) {
    const [cookies] = useCookies(['csrftoken']);

    const [cars_activity_history, setCars_activity_history] = useState([])

    const time_range_filter = [
        ['За день', 'DAY'],
        ['За неделю', 'WEEK'],
        ['За месяц', 'MONTH'],
    ]

    const [isLoad, setIsLoad] = useState(false)
    const [time_range_filter_value, setTime_range_filter_value] = useState('WEEK')

    let state = {
        options: {
          chart: {
            id: "basic-bar",
            zoom: {
                enabled: false
            },
            toolbar: {
                show: true,
                tools:{
                    download: false // <== line to add
                }
            },
            fontFamily: 'Gilroy, Gilroy, Gilroy',
          },
          tooltip: {
            enabled: true,
            marker: {
                show: false,
            },
          },
          xaxis: {
            labels: {
                rotate: -45
            },
            categories: [],
            tooltip: {
                enabled: false,
            },
            labels: {
                show: true,
                rotate: -45,
            }
          },
          yaxis: {
            min: 0,
            labels: {
              formatter: function (value) {
                return Math.round(parseFloat(value));
              }
            },
          },
          colors: ['#6486FB'],
        },
        series: [
          {
            name: "Активных авто",
            data: []
          }
        ],
    };

    useEffect(() => {
        get_cars_activity_history()
    }, [time_range_filter_value, props.group_sort_id]);


    let days_of_week = [
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
        'Воскресенье'
    ]

    let day_of_week_current = -1

    if (time_range_filter_value == 'WEEK'){
        for (let piece of cars_activity_history){
            // day_of_week: 2
            // label: "00:00"     ​​
            // value: 1
    
            if (piece.day_of_week != day_of_week_current){
                state.options.xaxis.categories.push(days_of_week[piece.day_of_week])
                day_of_week_current = piece.day_of_week
            } else {
                state.options.xaxis.categories.push('')
            }
            state.series[0].data.push(Math.round(parseFloat(piece.value)))
        }
    } else {
        for (let piece of cars_activity_history){
            // day_of_week: 2
            // label: "00:00"     ​​
            // value: 1
            state.options.xaxis.categories.push(piece.label)
            state.series[0].data.push(Math.round(parseFloat(piece.value)))
        }
    }

    //console.log('state : ', state)

    return (
        <div className=''>
            <div>
                <Basic_select current_value={time_range_filter_value} all_values={time_range_filter} setValue={setTime_range_filter_value} width={'180px'}/>
                {isLoad
                    ?
                        <Chart
                            options={state.options}
                            series={state.series}
                            type="line"
                            width="100%"
                            height='347px'
                        />
                    :
                    <div style={{'height': '323px', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center'}}><ReactLoading type={'spin'} color="#6486FB" /></div>
                }           
            </div>
        </div>
    )

    async function get_cars_activity_history(){
        //console.log('reload_notifications')
        setIsLoad(false)
        let cars_activity_history_new = await axios_api.get_cars_activity_history(cookies.csrftoken, time_range_filter_value, props.group_sort_id)
        console.log('dashboard new cars_activity_history : ', cars_activity_history_new)
        //setCars_activity_history([])
        setCars_activity_history(cars_activity_history_new)
        setIsLoad(true)
    }
}