import React, { useEffect, useState, useContext } from 'react'
import '../../../styles/park_settings/main_park_settings.css'

import Table_item from './item_car_table_create_group.jsx'

import car_profile_LIGHT_BLUE from '../../../styles/icons/car_profile_LIGHT_BLUE.svg'
import car_profile_BLUE from '../../../styles/icons/car_profile_BLUE.svg'
import car_profile_DARK_BLUE from '../../../styles/icons/car_profile_DARK_BLUE.svg'
import car_profile_DARKEST_BLUE from '../../../styles/icons/car_profile_DARKEST_BLUE.svg'
import car_profile_BROWN from '../../../styles/icons/car_profile_BROWN.svg'
import car_profile_ORANGE from '../../../styles/icons/car_profile_ORANGE.svg'
import car_profile_LIGHT_ORANGE from '../../../styles/icons/car_profile_LIGHT_ORANGE.svg'
import car_profile_LIME from '../../../styles/icons/car_profile_LIME.svg'
import car_profile_GREEN from '../../../styles/icons/car_profile_GREEN.svg'
import car_profile_BLACK from '../../../styles/icons/car_profile_BLACK.svg'
import car_profile_D_GRAY from '../../../styles/icons/car_profile_D_GRAY.svg'
import car_profile_WHITE from '../../../styles/icons/car_profile_WHITE.svg'
import car_profile_WAVE_BLUE from '../../../styles/icons/car_profile_WAVE_BLUE.svg'
import car_profile_CYAN from '../../../styles/icons/car_profile_CYAN.svg'
import car_profile_DARK_PURPLE from '../../../styles/icons/car_profile_DARK_PURPLE.svg'
import car_profile_PURPLE from '../../../styles/icons/car_profile_PURPLE.svg'
import car_profile_ROSE from '../../../styles/icons/car_profile_ROSE.svg'
import car_profile_ROSE_RED from '../../../styles/icons/car_profile_ROSE_RED.svg'
import car_profile_RED from '../../../styles/icons/car_profile_RED.svg'
import car_profile_YELLOW from '../../../styles/icons/car_profile_YELLOW.svg'
import car_profile_BLUE_GRAY from '../../../styles/icons/car_profile_BLUE_GRAY.svg'
import car_profile_GRAY from '../../../styles/icons/car_profile_GRAY.svg'
import car_profile_LIGHT_GRAY from '../../../styles/icons/car_profile_LIGHT_GRAY.svg'

export default function Car_table_create_group(props) {
  const [render_table, setRender_table] = useState(false)

  let searched_array = []

  props.cars.map((car) => {
    if (!props.search) {
      searched_array.push(car)
    } else {
      if (car.car_number && car.car_number.toUpperCase().includes(props.search.toUpperCase())) {
        searched_array.push(car)
      }
    }
  })

  function check_checked(car_id) {
    // console.log(props.group_id)
    // console.log(car_id)
    if (props.add_car_create_group.includes(car_id)) {
      return 'checked'
    } else {
      return 'unchecked'
    }
  }

  let imgs_colors = {
    null: car_profile_LIGHT_BLUE,
    LIGHT_BLUE: car_profile_LIGHT_BLUE,
    BLUE: car_profile_BLUE,
    DARK_BLUE: car_profile_DARK_BLUE,
    DARKEST_BLUE: car_profile_DARKEST_BLUE,
    BROWN: car_profile_BROWN,
    ORANGE: car_profile_ORANGE,
    LIGHT_ORANGE: car_profile_LIGHT_ORANGE,
    LIME: car_profile_LIME,
    GREEN: car_profile_GREEN,
    BLACK: car_profile_BLACK,
    D_GRAY: car_profile_D_GRAY,
    WHITE: car_profile_WHITE,
    WAVE_BLUE: car_profile_WAVE_BLUE,
    CYAN: car_profile_CYAN,
    DARK_PURPLE: car_profile_DARK_PURPLE,
    PURPLE: car_profile_PURPLE,
    ROSE: car_profile_ROSE,
    ROSE_RED: car_profile_ROSE_RED,
    RED: car_profile_RED,
    YELLOW: car_profile_YELLOW,
    BLUE_GRAY: car_profile_BLUE_GRAY,
    GRAY: car_profile_GRAY,
    LIGHT_GRAY: car_profile_LIGHT_GRAY,
  }

  return (
    <div>
      {searched_array.length ? (
        <>
          {searched_array.map((car) => (
            <Table_item
              car_img={imgs_colors[car.color]}
              car={car}
              checked={check_checked(car.id)}
              key={car.id}
              add_car_create_group={props.add_car_create_group}
              setAdd_car_create_group={props.setAdd_car_create_group}
              setRender_table={setRender_table}
            />
          ))}
        </>
      ) : (
        <div className='no_cars'>Нет авто</div>
      )}
    </div>
  )
}
