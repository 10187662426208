import React from "react";

import '../../../styles/park_settings/main_park_settings.css'

const ModalChangeParam = ({active, setActive, children}) => {

    return (
      <div
        className={active ? 'modalChangeParam active' : 'modalChangeParam'}
        onClick={() => setActive()}
      >
        <div
          className="modalChangeParamContent"
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    )

}

export default ModalChangeParam