import React, { useEffect, useState } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'

import arrow_blue_down from '../../styles/icons/arrow_input.svg'
import arrow_blue_up from '../../styles/icons/arrow_blue_input.svg'

export default function Basic_select(props) {
  const [open_select, setOpen_select] = useState(false)

  function set_value(e) {
    let id = e.currentTarget.getAttribute('data_value')
    props.setValue(id)
    setOpen_select(false)
  }

  function open_close_select() {
    if (props.status == 'readonly') return
    setOpen_select(!open_select)
  }

  function handleClickAway() {
    setOpen_select(false)
  }

  let class_name_for_select_btn
  let arrow_for_select_btn
  if (open_select) {
    class_name_for_select_btn = 'basic_select opened'
    arrow_for_select_btn = arrow_blue_up
  } else {
    class_name_for_select_btn = 'basic_select'
    arrow_for_select_btn = arrow_blue_down
  }

  if (props.status) {
    if (props.status == 'success') {
      class_name_for_select_btn = class_name_for_select_btn + ' success'
    }
    if (props.status == 'readonly') {
      class_name_for_select_btn = class_name_for_select_btn + ' readonly'
    }
  }

  let current_value_name
  for (let value of props.all_values) {
    if (value[1] == props.current_value) {
      current_value_name = value[0]
    }
  }

  let style = {
    width: props.width,
    position: 'relative',
    height: props.height,
  }

  let heigth_style = {
    width: '100%',
    height: props.height,
  }

  let width_style = {
    width: '100%',
  }

  return (
    <div className='' style={style}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={''} style={heigth_style}>
          <div
            className={class_name_for_select_btn}
            style={heigth_style}
            onClick={open_close_select}
          >
            <span>{current_value_name}</span>
            <img src={arrow_for_select_btn} className={open_select ? 'basic_select_icon' : ''} />
          </div>
          <div
            className={open_select ? 'basic_select_content opened' : 'basic_select_content'}
            style={width_style}
          >
            {props.all_values.map((value) => (
              <div
                className='basic_select_item'
                key={value[1]}
                data_value={value[1]}
                onClick={(e) => set_value(e)}
              >
                <span>{value[0]}</span>
              </div>
            ))}
          </div>
        </div>
      </ClickAwayListener>
    </div>
  )
}
