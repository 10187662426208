/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import arrow_input from '../../styles/icons/arrow_input.svg'
import arrow_blue_input from '../../styles/icons/arrow_blue_input.svg'
import SimpleBar from 'simplebar-react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import '../../styles/components/components.css'

export default function List_input(props) {
  let input_class
  let text_class
  let input_readonly = false

  const [focus, setFocus] = useState(false)
  const [searched, setSearched] = useState([])
  const [render, setRender] = useState(0)

  useEffect(() => {
    if (props.value !== '') {
      let newArr = []

      for (let item of props.source) {
        if (item && item !== '') {
          if (item.toUpperCase().includes(props.value.toUpperCase())) {
            newArr.push(item)
          }
        }
      }
      setSearched(newArr)
    } else {
      let newArr = []
      for (let item of props.source) {
        if (item && item !== '') {
          newArr.push(item)
        }
      }
      setSearched(newArr)
    }
    setRender(render + 1)
  }, [props.value])

  const itemClick = (item) => {
    props.onChange(item)
    setFocus(false)
  }

  const onChange = (event) => {
    props.onChange(event.target.value)
  }

  const onBlur = () => {
    setFocus(false)
  }

  check_status()

  return (
    <ClickAwayListener onClickAway={onBlur}>
      <div className='div_basic_input' style={{ width: props.width }}>
        <input
          value={props.value}
          onChange={(e) => onChange(e)}
          className={input_class}
          readOnly={input_readonly}
          type={props.type}
          onFocus={() => {
            if (props.status == 'readonly') return
            setFocus(true)
          }}
        />
        <div className={text_class}>{props.text}</div>
        {focus ? (
          <img className='arrow_list_input active' src={arrow_blue_input} height={'16px'} />
        ) : (
          <img className='arrow_list_input' src={arrow_input} height={'16px'} />
        )}
        <div
          className={focus && searched.length ? 'arrow_list_popup_active' : 'arrow_list_popup'}
          style={{ width: props.width }}
        >
          <SimpleBar style={{ maxHeight: 200 }}>
            {searched.map((item) => (
              <div key={item} className='arrow_list_item' onClick={() => itemClick(item)}>
                {item}
              </div>
            ))}
          </SimpleBar>
        </div>
      </div>
    </ClickAwayListener>
  )

  function check_status() {
    if (props.status == 'active') {
      input_class = 'input_basic_input'
      text_class = 'text_basic_input'
    }
    if (props.status == 'readonly') {
      input_readonly = true
      input_class = 'input_basic_input readonly'
      text_class = 'text_basic_input'
    }
    if (props.status == 'error') {
      input_class = 'input_basic_input error'
      text_class = 'text_basic_input error'
    }
    if (props.status == 'success') {
      input_class = 'input_basic_input success'
      text_class = 'text_basic_input success'
    }
  }
}
