import React, { useEffect, useState, useContext } from 'react';
import '../../styles/components/components.css'

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

export default function Daterange_picker(props) {

    function change_start_end(start, end, label){

        props.setStartEnd({
            'start': start.format('DD.MM.YYYY'),
            'end': end.format('DD.MM.YYYY')
        })
    }

    return (
        <div>
            <DateRangePicker
                onCallback={change_start_end}
                initialSettings={{     
                    autoApply: true,
                    locale: {
                        format: 'DD.MM.YYYY',
                        daysOfWeek: [
                            "Вс",
                            "Пн",
                            "Вт",
                            "Ср",
                            "Чт",
                            "Пт",
                            "Сб"
                        ],
                        monthNames: [
                            "Январь",
                            "Февраль",
                            "Март",
                            "Апрель",
                            "Май",
                            "Июнь",
                            "Июль",
                            "Август",
                            "Сентябрь",
                            "Октябрь",
                            "Ноябрь",
                            "Декабрь"
                        ],
                        firstDay: 1
                    },
                    startDate: props.start_end.start, // after open picker you'll see this dates as picked
                    endDate: props.start_end.end, 
                }}
            >
                <input className='date_range_picker' readOnly></input>
            </DateRangePicker>
        </div>
    )
}