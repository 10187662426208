import React from "react";
import '../../../styles/routes_map/routes.css'

const Modal_helper = ({active, setActive, children}) => {

    return (
        <div className={active ? "modal_helper active" : "modal_helper"} onClick={() => setActive()}>
            <div className="modal_helper_content">
                {children}
            </div>
        </div>
    );

}

export default Modal_helper;