import options from '../../styles/icons/dots.svg';
import * as React from 'react';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export default function Option_btn_scroll({children}) {

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);

    if(event.target.className == 'img_options'){ //На случай если кликнули по трем точкам, чтобы срабатывал event вместе с stopPropagation
      document.body.click()
    }
    
    let wrappers = document.getElementsByClassName('simplebar-content-wrapper');
    for (let i = 0; i < wrappers.length; ++i) {
        let item = wrappers[i];  
        item.addEventListener('scroll', function(){setOpen(false)});
    }
  };

  function handleClickAway(){
    setOpen(false)
  }

  const stopevent = (event) => {
    event.stopPropagation()
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  return (
    <div>
        <div>
            <img src={options} className='img_options' onClick={handleClick}/>
            <Popper placement="top-start" id={id} open={open} anchorEl={anchorEl} transition >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={0}>
                        <div className='popper_option_btn_scroll' onClick={stopevent}>
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <div className='' onClick={handleClickAway}>
                                    {children}    
                                </div>
                            </ClickAwayListener>
                        </div>  
                    </Fade>
                    )}
            </Popper>
        </div>
    </div>
  );
}