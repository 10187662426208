import React, { useEffect, useState } from 'react'
import '../../../styles/park_settings/main_park_settings.css'
import Basic_input from '../../UI/basic_input.jsx'

export default function Refill_settings(props) {
  const [external_api_key, setExternal_api_key] = useState('')

  useEffect(() => {
    if (props.payment_info) {
      setExternal_api_key(props.payment_info.external_api_key)
    }
  }, [props.payment_info])

  return (
    <div className='div_main_setting'>
      <div className='header_refill_settings'>
        <div className='text_header_refill_settings'>Внешний API ключ</div>
      </div>
      <div style={{ marginTop: '30px', marginLeft: '16px' }}>
        <Basic_input
          value={external_api_key}
          text={'API ключ'}
          status={'readonly'}
          width={'600px'}
          type={'text'}
          onChange={setExternal_api_key}
        />
      </div>
    </div>
  )
}
