import React from "react";

import '../../styles/dashboard/dashboard_main.css'

const Big_modal = ({active, setActive, children}) => {

    return (
        <div className={active ? "big_modal active" : "big_modal"} onClick={() => setActive()}>
            <div className="big_modal_content" onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );

}

export default Big_modal;