import React from 'react'
import { Link } from 'react-router-dom'
import '../../styles/dashboard/notifications.css'
import car_data from '../../API/car_data'
import Option_btn_scroll from '../UI/option_btn_scroll'

export default function Notification_card(props) {
  const makeDateStr = (datetime) => {
    let date = datetime.split('T')[0]
    let time = datetime.split('T')[1].split('.')[0]
    date = date.split('-')[2] + '.' + date.split('-')[1] + '.' + date.split('-')[0]
    time = time.split(':')[0] + ':' + time.split(':')[1]
    return {
      date: date,
      time: time,
    }
  }

  return (
    <div className='notification_card_dashboard'>
      <div className='label_card_dashboard'>{props.group.notifications[0].message}</div>
      <div>
        {props.group.notifications.map((notification) => (
          <div key={notification.id} className='row_notification_card_dashboard'>
            <div className='td_time_notification_card_dashboard'>
              <div className='date_td_time_notification_card_dashboard'>
                {makeDateStr(notification.datetime).date}
              </div>
              <div className='time_td_time_notification_card_dashboard'>
                {makeDateStr(notification.datetime).time}
              </div>
            </div>
            <div className='td_icon_notification_card_dashboard'>
              <img src={car_data.get_svg_car(notification.car_color)} style={{ height: '25px' }} />
            </div>
            <div className='td_number_notification_card_dashboard'>
              <div className='car_number_notification_card_dashboard'>
                {notification.car_number}
              </div>
              <div className='car_brand_notification_card_dashboard'>{notification.car_brand}</div>
            </div>
            <div className='td_options_notification_card_dashboard'>
              <Option_btn_scroll>
                <div
                  className='item_option_btn_scroll'
                  data_id={notification.id}
                  data_message={notification.message}
                  data_car_number={notification.car_number}
                  onClick={(e) => props.hide_notification(e)}
                >
                  Скрыть уведомление
                </div>
                <Link to={'/car_list?car=' + notification.car} style={{ textDecoration: 'none' }}>
                  <div className='item_option_btn_scroll'>Перейти в профиль авто</div>
                </Link>
              </Option_btn_scroll>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
