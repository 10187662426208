/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import './CarMobile.css'
import Basic_input from '../../UI/basic_input'
import Icon_btn from '../../UI/icon_btn'
import Btn_defolt from '../../UI/btn_defolt'
import content_copy from '../../../styles/icons/content-copy.svg'
import Tippy from '@tippyjs/react/headless'
import Basic_modal from '../../UI/basic_modal'
import moment from 'moment'

export default function CodeInfoModal(props) {
  const copy_text = () => {
    navigator.clipboard.writeText(props.car.imei)
  }

  if (!props.car || !props.car.is_mobile) {
    return <></>
  }

  const convertData = (data) => {
    return moment(data).format('DD.MM.YYYY HH:mm')
  }

  return (
    <Basic_modal active={props.active} setActive={props.setActive}>
      <div className='text_accept_delete' style={{ textAlign: 'center' }}>
        Данные временнего авто '{props.car.car_number}'
      </div>
      <div style={{ width: '450px' }}>
        <div style={{ width: '100%', marginTop: '24px' }}>
          <div style={{ display: 'flex', alignItems: 'end' }}>
            <Basic_input
              value={props.car.imei}
              text={'Код для водителя'}
              status={'readonly'}
              width={'100%'}
              type={'text'}
            />
            <Tippy
              render={(attrs) => (
                <div className='box tippy_content' tabIndex='-1' {...attrs}>
                  Скопировать
                </div>
              )}
            >
              <div style={{ marginTop: '6px', marginLeft: '8px' }}>
                <Icon_btn
                  color={'#EFF3FF'}
                  hover_color={'#dbe4ff'}
                  icon={content_copy}
                  height={'46px'}
                  width={'46px'}
                  onClick={copy_text}
                />
              </div>
            </Tippy>
          </div>
        </div>
        <div style={{ width: '100%', marginTop: '24px' }}>
          <Basic_input
            value={props.car.mobile_driver.fio || '-'}
            text={'ФИО водителя'}
            status={'readonly'}
            width={'100%'}
            type={'text'}
          />
        </div>
        <div style={{ width: '100%', marginTop: '24px' }}>
          <Basic_input
            value={convertData(props.car.mobile_driver.dt)}
            text={'Дата создания'}
            status={'readonly'}
            width={'100%'}
            type={'text'}
          />
        </div>
        <div style={{ width: '100%', marginTop: '24px' }}>
          <Basic_input
            value={convertData(props.car.mobile_driver.expiry_dt)}
            text={'Дата удаления'}
            status={'readonly'}
            width={'100%'}
            type={'text'}
          />
        </div>
        <div
          style={{ width: '100%', marginTop: '24px', display: 'flex', justifyContent: 'center' }}
        >
          <Btn_defolt
            color={'#EFF3FF'}
            text_color={'#6486FB'}
            hover_color={'#dbe4ff'}
            text={'Закрыть'}
            height={'40px'}
            width={'150px'}
            onClick={(e) => props.setActive(false)}
          />
        </div>
      </div>
    </Basic_modal>
  )
}
