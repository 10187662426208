import React, { useEffect, useState, useContext } from 'react';

import SimpleBar from 'simplebar-react';

import Zone_current_card_car from './zone_current_card_car'

import zone_icon_zones_list from "../../../styles/icons/zone_icon_zones_list.svg" 
import icon_plus_zone_list from '../../../styles/icons/icon_plus_zone_list.svg';

import Option_btn_scroll from '../../UI/option_btn_scroll'

import { MapContext } from '../../../context/index';

export default function Zone_current_card(props) {
    let {map_object} = useContext(MapContext)
    let geozone = props.geozone

    function open_add_car(e){
        let id = e.currentTarget.getAttribute('data_id')
        map_object.open_add_car_to_zone(id)
    }

    return (
        <div className='card_zone_list'>
            <div className='div_name_zone_list'>
                <img src={zone_icon_zones_list} className="zone_icon_zones_list"/>
                <div className='text_name_zone_list'>
                    {geozone.name}
                </div>
                {/* <img src={options} className="options_zones_list"/> */}
                <div className="div_options_zones_list">
                    <Option_btn_scroll>
                            <div className='item_option_btn_scroll' data_id={geozone.id} onClick={(e) => map_object.edit_zone(e.currentTarget.getAttribute('data_id'))}>Редактировать геозону</div>
                            <div className='item_option_btn_scroll' data_id={geozone.id} onClick={(e) => map_object.remove_zone(e.currentTarget.getAttribute('data_id'))}>Удалить геозону</div>   
                            {/* <div className='item_option_btn_scroll'>Добавить в сравнение маршрута</div>
                            <div className='item_option_btn_scroll'>Настройки</div> */}
                    </Option_btn_scroll>
                </div>
            </div>
            <div className='div_alert_zone_list'>

            </div>
            <div className='div_add_car_zone_list' data_id={geozone.id} onClick={(e) => open_add_car(e)}>
                <div className='text_add_car_zone_list'>
                    Добавить авто
                </div>
                <div className='div_icon_plus_zone_list'>
                    <img src={icon_plus_zone_list} className="icon_plus_zone_list"/>
                </div>
            </div>
            <div className='div_cars_zone_list'>
                <SimpleBar style={{ maxHeight: 190 }}>
                    {geozone.cars.map((car) => 
                        <Zone_current_card_car key={car} car_id={car} cars={props.cars} geozone={geozone}/>
                    )}
                </SimpleBar>
            </div>
        </div>
    )
}