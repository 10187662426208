/* eslint-disable react/jsx-pascal-case */
import React from 'react'

export default function tableItemParamModal(props) {
  let inspection = props.inspection

  function row_click(e) {
    let id = e.currentTarget.getAttribute('data_id')
    props.row_click(id)
  }

  const carsCount = () => {
    let count = 0
    if (props.cars_delailed) {
      for (let car of props.cars_delailed) {
        if (car.inspection_group === inspection.id) {
          count++
        }
      }
    }
    return count
  }

  const checkChecked = (id) => {
    if (props.choosenInspection == id) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="">
      <div
        className="table_row_car_inspections"
        data_id={inspection.id}
        onClick={(e) => row_click(e)}
      >
        <div className="td_radio_car_inspections">
          <input
            type="radio"
            className="radio_car_inspections"
            checked={checkChecked(inspection.id)}
            onChange={() => {}}
          />
        </div>
        <div className="td_name_car_inspections">{inspection.verbose_name}</div>
        <div className="td_brand_car_inspections">
          {inspection.brand}{' '}
          <span className="model_car_inspections">{inspection.model}</span>
        </div>
        <div className="td_count_category_car_inspections">
          <div>{inspection.categories.length} пар.</div>
        </div>
        <div className="td_count_cars_car_inspections">
          <div>{carsCount()} авто</div>
        </div>
      </div>
      <div className="div_border_bottom_car_inspections">
        <div className="border_bottom_car_inspections"></div>
      </div>
    </div>
  )
}
