import React, { useRef, useEffect, useContext } from 'react'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import MapboxLanguage from '@mapbox/mapbox-gl-language'
import { MapContext } from '../../context/index'
import '../../index.css'
import '../../styles/map/map.css'

mapboxgl.accessToken =
  'pk.eyJ1IjoidHR0dHR2IiwiYSI6ImNsN2t1aHNxdTBhYzkzd284Z3Nmams4eHAifQ.0sdD04xpHPIxNEqZpbpdhw'

export default function MapShare(props) {
  const mapContainer = useRef(null)
  const map = useRef(null)
  let { map_object } = useContext(MapContext)

  useEffect(() => {
    if (map.current) return // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [37.617618, 55.755799],
      zoom: 8.0,
      language: 'ru',
    })

    const language = new MapboxLanguage()
    map.current.addControl(language)
    map_object.map_share = map.current
  })

  return <div ref={mapContainer} className='map-container' />
}
