/* eslint-disable react/jsx-pascal-case */
import React, { useContext } from 'react'
import '../../../styles/routes_map/routes.css'
import Pieces_of_way_routes_info from './pieces_of_way_routes_info'

import { MapContext } from '../../../context/index'

export default function Routes_info_list_item(props) {
  let { map_object } = useContext(MapContext)
  let colors = map_object.info_draw_routes.colors
  let color_id_start = map_object.info_draw_routes.color_id_for_cards
  let color_id = color_id_start

  let style_border = {
    borderTop: '3px solid ' + props.color,
  }

  let style_text = {
    color: props.color,
  }

  let distance = props.day.distance.toFixed(1)
  let day_distance = 0

  let id = 0

  for (let piece_of_way of props.day.route) {
    piece_of_way.id = id
    id = id + 1

    piece_of_way.color_id = color_id
    if (piece_of_way.type == 'move') {
      color_id = color_id + 1
      map_object.info_draw_routes.color_id_for_cards = color_id

      if (color_id > colors.length - 2) {
        color_id = 0
        map_object.info_draw_routes.color_id_for_cards = color_id
      }
    }

    day_distance = day_distance + piece_of_way.distance
  }
  return (
    <div className='card_routes_info_list' style={style_border}>
      <div className='date_and_distance' style={style_text}>
        <div>{date_string(props.day.date)}</div>
        <div>{distance} км.</div>
      </div>
      <div className='pieces_of_way'>
        {props.day.route.map((piece_of_way) => (
          <Pieces_of_way_routes_info
            key={piece_of_way.id}
            piece={piece_of_way}
            color={colors[piece_of_way.color_id]}
            bg_color={hexToRgb(colors[piece_of_way.color_id])}
            day_distance={day_distance}
          />
        ))}
      </div>
    </div>
  )

  function date_string(date) {
    let days_of_week = ['вс.', 'пн.', 'вт.', 'ср.', 'чт.', 'пт.', 'сб.']

    let months = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ]

    let day = parseFloat(date.split('.')[0])
    let month = parseFloat(date.split('.')[1])
    let year = parseFloat(date.split('.')[2])

    let new_date = new Date(year, month - 1, day)
    let day_of_week = days_of_week[new_date.getDay()]
    let month_str = months[new_date.getMonth()]

    return day + ' ' + month_str + ', ' + day_of_week
  }

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  }
}
