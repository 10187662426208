import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './components/AppRouter'
import { useCookies } from 'react-cookie'
import { AuthContext } from './context'
import { MapContext } from './context'
import { DashboardContext } from './context'
import { CarListContext } from './context'
import { ParkSettingsContext } from './context'
import { ReportContext } from './context'
import { DriversContext } from './context'
import './index.css'
import 'simplebar/dist/simplebar.min.css'
import { useDispatch } from 'react-redux'

import circle_6486FB from './styles/icons/circle_6486FB.svg'
import circle_21AD7B from './styles/icons/circle_21AD7B.svg'
import circle_FBA364 from './styles/icons/circle_FBA364.svg'
import circle_FF0000 from './styles/icons/circle_FF0000.svg'
import circle_FFEB3B from './styles/icons/circle_FFEB3B.svg'
import circle_FB6464 from './styles/icons/circle_FB6464.svg'
import circle_D464FB from './styles/icons/circle_D464FB.svg'
import circle_FBD164 from './styles/icons/circle_FBD164.svg'
import circle_FB64C8 from './styles/icons/circle_FB64C8.svg'
import circle_64FBA9 from './styles/icons/circle_64FBA9.svg'
import circle_000F43 from './styles/icons/circle_000F43.svg'
import circle_64FBFB from './styles/icons/circle_64FBFB.svg'
import circle_8B64FB from './styles/icons/circle_8B64FB.svg'
import circle_002FD0 from './styles/icons/circle_002FD0.svg'

import resize_action from './actions/window_actions.js'

import user_loaded from './actions/users_actions.js'
import park_loaded from './actions/park_actions.js'
import axios_api from './API/axios_api'

import $ from 'jquery'

export default function App() {
  const dispatch = useDispatch()
  const [cookies] = useCookies(['csrftoken'])

  const [Auth] = useState({
    isAuth: false,
    user: null,
  })

  const [isLoading, setIsLoading] = useState(true)

  //colors_for_routes
  let colors = [
    '#6486FB',
    '#21AD7B',
    '#FBA364',
    '#FF0000',
    '#FFEB3B',
    '#FB6464',
    '#D464FB',
    '#FBD164',
    '#FB64C8',
    '#8B64FB',
    '#000F43',
    '#64FBA9',
    '#64FBFB',
    '#002FD0',
  ]

  let dots_for_speed = {
    '#6486FB': circle_6486FB,
    '#21AD7B': circle_21AD7B,
    '#FBA364': circle_FBA364,
    '#FF0000': circle_FF0000,
    '#FFEB3B': circle_FFEB3B,
    '#FB6464': circle_FB6464,
    '#D464FB': circle_D464FB,
    '#FBD164': circle_FBD164,
    '#FB64C8': circle_FB64C8,
    '#8B64FB': circle_8B64FB,
    '#000F43': circle_000F43,
    '#64FBA9': circle_64FBA9,
    '#64FBFB': circle_64FBFB,
    '#002FD0': circle_002FD0,
  }

  //end colors_for_routes

  let info_draw_routes = {
    car: null,
    reload: false,
    draw_routes: true,
    color_id_for_cards: 0,
    colors: colors,
    dots_for_speed: dots_for_speed,
    first_init_img_routes: true,
  }

  let resized = {
    car_list_map: false,
    car_routes_map: false,
    car_zones_map: false,
    notifications_map: false,
  }

  const [map_object, set_map_object] = useState({
    map: null,
    markers: [],
    cars: null,
    first_init: true,
    first_init_window_routes: true,
    frame_index: 0,
    info_draw_routes: info_draw_routes,
    lines: [],
    stop_markers_on_lines: [],
    speed_layers_id: [],
    speed_sourses_id: [],
    new_markers_zones: [],
    new_lines_zones: [],
    active_click_new_zone: false,
    current_geozones: [],
    current_geozones_hiden: true,
    resized: resized,
    first_init_notifications: true,
    user_current: null,
  })

  const [dashboard_object, set_dashboard_object] = useState({
    first_init_dashboard: true,
  })

  const [car_list_object, set_car_list_object] = useState({
    first_car_list: true,
  })

  const [park_settings_object, set_park_settings_object] = useState({
    first_park_settings: true,
  })

  const [drivers_object, set_drivers_object] = useState({
    first_drivers: true,
  })

  const [report_object, set_report_object] = useState({
    first_report: true,
    colors: colors,
  })

  useEffect(() => {
    if (cookies.csrftoken && cookies.csrftoken != 'undefined') {
      Auth.isAuth = true
    }
    setIsLoading(false)
    reload_user_info()
    reload_park_info()
  }, [])

  window.onresize = window_resize

  function window_resize() {
    // let height = document.documentElement.clientHeight
    // let width = document.documentElement.clientWidth
    let height = $(document).height()
    let width = $(document).width()
    dispatch(resize_action(height, width))
    // console.log('height : ', height)
    // console.log('width : ', width)
  }

  async function reload_user_info() {
    let user = await axios_api.get_user_info(cookies.csrftoken)
    dispatch(user_loaded(user))
  }

  async function reload_park_info() {
    let park_info = await axios_api.get_settings_current(cookies.csrftoken)
    dispatch(park_loaded(park_info))
  }

  return (
    <DriversContext.Provider
      value={{
        drivers_object,
      }}
    >
      <ReportContext.Provider
        value={{
          report_object,
        }}
      >
        <ParkSettingsContext.Provider
          value={{
            park_settings_object,
          }}
        >
          <CarListContext.Provider
            value={{
              car_list_object,
            }}
          >
            <DashboardContext.Provider
              value={{
                dashboard_object,
              }}
            >
              <MapContext.Provider
                value={{
                  map_object,
                  set_map_object,
                }}
              >
                <AuthContext.Provider
                  value={{
                    Auth,
                    isLoading,
                  }}
                >
                  {/* <Draw_nav_bar_main/> */}
                  <BrowserRouter>
                    <AppRouter />
                  </BrowserRouter>
                </AuthContext.Provider>
              </MapContext.Provider>
            </DashboardContext.Provider>
          </CarListContext.Provider>
        </ParkSettingsContext.Provider>
      </ReportContext.Provider>
    </DriversContext.Provider>
  )
}
