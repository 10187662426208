/* eslint-disable react/jsx-pascal-case */
import React, { useState, useContext } from 'react'

import '../../../styles/park_settings/main_park_settings.css'
import ReactLoading from 'react-loading'
import SimpleBar from 'simplebar-react'
import { useCookies } from 'react-cookie'
import axios_api from '../../../API/axios_api'
import { ParkSettingsContext } from '../../../context/index'

import Icon_btn from '../../UI/icon_btn.jsx'
import Btn_defolt from '../../UI/btn_defolt.jsx'
import Fake_btn_defolt from '../../UI/fake_btn_defolt.jsx'
import Basic_input from '../../UI/basic_input.jsx'
import Search_input from '../../UI/search_input.jsx'

import Btn_arrow_for_settings from '../btn_arrow_for_settings.jsx'
import Table_item_groups from './table_item_groups.jsx'
import Car_table_edit_cars_group from './car_table_edit_cars_group.jsx'
import Car_table_create_group from './car_table_create_group.jsx'

import Modal_add_group from './modal_add_group.jsx'

import arrow_left_white_icon from '../../../styles/icons/arrow_left_white_icon.svg'
import arrow_left_blue_icon from '../../../styles/icons/arrow_left_blue_icon.svg'

import plus_white from '../../../styles/icons/plus_white.svg'

import { useSelector } from 'react-redux'

import Modal_with_accept from '../../UI/modal_with_accept'

export default function Groups_settings(props) {
  const window_height = useSelector((state) => state.window_height)
  const height_edit = window_height - 70 - 24 - 24 - 350
  const height_list = window_height - 70 - 24 - 24 - 140

  const [cookies] = useCookies(['csrftoken'])

  const [search_add_cars, setSearch_add_cars] = useState('')
  const [add_car_to_group, setAdd_car_to_group] = useState([])

  const [search_create_group, setSearch_create_group] = useState('')
  const [add_car_create_group, setAdd_car_create_group] = useState([])
  const [modal_group_add_active, setActive_modal_group_add] = useState(false)
  const [status_modal_group_add, setStatus_modal_group_add] = useState('')
  const [short_name, setShort_name] = useState('')
  const [full_name, setFull_name] = useState('')
  const [short_name_status, setShort_name_status] = useState('active')
  const [full_name_status, setFull_name_status] = useState('active')

  const [group_edit, setGroup_edit] = useState(null)
  const [short_name_create_group, setShort_name_create_group] = useState('')
  const [full_name_create_group, setFull_name_create_group] = useState('')

  const [btn_back_active, setBtn_back_active] = useState(false)

  const [modal_delete_active, setActive_modal_delete] = useState(false)
  const [name_delete, setName_delete] = useState('')
  const [status_modal_delete, setStatus_modal_delete] = useState('')

  const [page, setPage] = useState('list')

  let groups = null
  if (props.groups && props.cars_delailed) {
    groups = props.groups
  }

  function row_click(id) {
    console.log(id)

    for (let group of groups) {
      if (group.id == id) {
        setGroup_edit(group)
        setShort_name(group.short_name)
        setFull_name(group.name)
      }
    }
    setAdd_car_to_group([])
    for (let car of props.cars_delailed) {
      if (car.car_group == id || car.car_group.includes(parseFloat(id))) {
        add_car_to_group.push(car.id)
      }
    }
    console.log(add_car_to_group)
    setAdd_car_to_group(add_car_to_group)
    setPage('group_edit')
  }

  function go_back_to_list() {
    setPage('list')
    setBtn_back_active(false)
    setAdd_car_to_group([])
  }
  const [status_save_edit_btn, setStatus_save_edit_btn] = useState(null)

  async function save_edit_group() {
    console.log('save_edit_group : ', group_edit.id)
    console.log('add_car_to_group : ', add_car_to_group)

    let response_edit_group = await axios_api.edit_group(
      group_edit.id,
      full_name,
      short_name,
      add_car_to_group,
      cookies.csrftoken,
    )

    if (response_edit_group.status == '200') {
      setStatus_save_edit_btn('success')
      setShort_name_status('success')
      setFull_name_status('success')
    } else {
      setStatus_save_edit_btn('error')
    }

    setTimeout(function () {
      setStatus_save_edit_btn(null)
      setShort_name_status('active')
      setFull_name_status('active')
    }, 2000)

    props.update_all()
  }

  async function submite_create_group() {
    console.log('add_car_create_group : ', add_car_create_group)

    let response_create_group = await axios_api.post_new_group(
      full_name_create_group,
      short_name_create_group,
      add_car_create_group,
      cookies.csrftoken,
    )

    if (response_create_group.status == '201') {
      setStatus_modal_group_add('success')
    } else {
      setStatus_modal_group_add('error')
    }

    setTimeout(function () {
      setActive_modal_group_add(false)
      setStatus_modal_group_add('')
      close_create_group()
    }, 1000)

    props.update_all()
  }

  function close_create_group() {
    console.log('close_create_group')
    setActive_modal_group_add(false)
    setAdd_car_create_group([])
    setShort_name_create_group('')
    setFull_name_create_group('')
  }

  function delete_group() {
    for (let group of props.groups) {
      if (group.id == group_edit.id) {
        setName_delete(group.name)
      }
    }
    setActive_modal_delete(true)
  }

  async function accept_delete() {
    let response = await axios_api.delete_group(group_edit.id, cookies.csrftoken)

    if (response.status == '204') {
      setStatus_modal_delete('success')
    } else {
      setStatus_modal_delete('error')
    }

    setTimeout(function () {
      setActive_modal_delete(false)
      setStatus_modal_delete('')
      if (response.status == '204') {
        props.update_all()
        setPage('list')
      }
    }, 1000)
  }

  if (page == 'list') {
    return (
      <div className='div_main_setting'>
        <div className='header_groups_settings'>
          <div className='text_header_groups_settings'>Группы авто</div>
          <Icon_btn
            color={'#6486FB'}
            hover_color={'#5179fc'}
            icon={plus_white}
            height={'36px'}
            width={'60px'}
            onClick={(e) => setActive_modal_group_add(true)}
          />
        </div>
        {groups && props.cars_delailed ? (
          <div className='div_table_groups_settings'>
            <div className='header_table_groups_settings'>
              <div className='th_short_name_groups_settings'>Сокращенное название</div>
              <div className='th_full_name_groups_settings'>Полное название</div>
              <div className='th_count_cars_groups_settings'>Количество</div>
              <div className='th_arrows_groups_settings'></div>
            </div>
            <SimpleBar style={{ maxHeight: height_list }}>
              <div className='table_groups_settings'>
                {groups.map((group) => (
                  <Table_item_groups key={group.id} group={group} row_click={row_click} />
                ))}
              </div>
            </SimpleBar>
          </div>
        ) : (
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ReactLoading type={'spin'} color='#6486FB' />
          </div>
        )}
        <Modal_add_group active={modal_group_add_active} setActive={close_create_group}>
          <div className='text_create_group'>Создать группу авто</div>
          <div style={{ marginTop: '30px' }}>
            <div>
              <Basic_input
                value={full_name_create_group}
                text={'Полное название'}
                status={'active'}
                width={'100%'}
                type={'text'}
                onChange={setFull_name_create_group}
              />
            </div>
            <div style={{ marginTop: '24px' }}>
              <Basic_input
                value={short_name_create_group}
                text={'Сокращенное название'}
                status={'active'}
                width={'100%'}
                type={'text'}
                onChange={setShort_name_create_group}
              />
            </div>
          </div>
          <div style={{ height: '340px' }}>
            {props.cars_delailed ? (
              <>
                <div style={{ marginTop: '30px' }}>
                  <Search_input
                    placeholder={'Введите  гос. номер'}
                    change={setSearch_create_group}
                  />
                </div>
                <SimpleBar style={{ maxHeight: 300 }}>
                  <Car_table_create_group
                    search={search_create_group}
                    cars={props.cars_delailed}
                    add_car_create_group={add_car_create_group}
                    setAdd_car_create_group={setAdd_car_create_group}
                  />
                </SimpleBar>
              </>
            ) : (
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ReactLoading type={'spin'} color='#6486FB' />
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px',
            }}
          >
            <Btn_defolt
              color={'#EFF3FF'}
              text_color={'#6486FB'}
              hover_color={'#dbe4ff'}
              text={'Отмена'}
              height={'40px'}
              width={'180px'}
              onClick={close_create_group}
            />
            <Btn_defolt
              color={'#6486FB'}
              text_color={'#FFFFFF'}
              hover_color={'#5179fc'}
              text={'Создать'}
              height={'40px'}
              width={'180px'}
              onClick={submite_create_group}
            />
          </div>
          <div
            className={
              status_modal_group_add == 'success'
                ? 'status_modal_success active'
                : 'status_modal_success'
            }
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='text_status_main'>Успешно!</div>
            </div>
          </div>
          <div
            className={
              status_modal_group_add == 'error' ? 'status_modal_error active' : 'status_modal_error'
            }
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='text_status_main'>Ошибка!</div>
            </div>
          </div>
        </Modal_add_group>
      </div>
    )
  } else {
    return (
      <div className='div_main_setting'>
        <div className='header_group_edit_settings'>
          <div
            onMouseEnter={(e) => setBtn_back_active(true)}
            onMouseLeave={(e) => setBtn_back_active(false)}
            onClick={go_back_to_list}
            style={{ cursor: 'pointer' }}
          >
            <Btn_arrow_for_settings
              active={btn_back_active}
              color={'#EFF3FF'}
              active_color={'#6486FB'}
              not_active_icon={arrow_left_blue_icon}
              active_icon={arrow_left_white_icon}
              height={'36px'}
              width={'36px'}
            />
          </div>
          <div className='text_header_group_edit_settings'>Редактирование группы авто</div>
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '35px',
            marginLeft: '16px',
            marginRight: '16px',
          }}
        >
          <div>
            <Basic_input
              value={full_name}
              text={'Полное название'}
              status={full_name_status}
              width={'300px'}
              type={'text'}
              onChange={setFull_name}
            />
          </div>
          <div style={{ marginLeft: '24px' }}>
            <Basic_input
              value={short_name}
              text={'Сокращенное название'}
              status={short_name_status}
              width={'300px'}
              type={'text'}
              onChange={setShort_name}
            />
          </div>
        </div>
        <div>
          <div
            className='search_new_zone'
            style={{
              marginTop: '30px',
              marginLeft: '16px',
              marginRight: '16px',
            }}
          >
            <Search_input placeholder={'Введите  гос. номер'} change={setSearch_add_cars} />
          </div>
          <div className='header_table_edit_cars_group'>
            <div className='th_number_table_edit_cars_group'>Номер и марка</div>
            <div className='th_phone_table_edit_cars_group'>Номер телефона</div>
            <div className='th_mileage_table_edit_cars_group'>Пробег</div>
          </div>
          <SimpleBar style={{ maxHeight: height_edit }}>
            <Car_table_edit_cars_group
              search={search_add_cars}
              cars={props.cars_delailed}
              group_id={group_edit.id}
              add_car_to_group={add_car_to_group}
              setAdd_car_to_group={setAdd_car_to_group}
            />
          </SimpleBar>
          <div style={{ display: 'flex', marginTop: '24px', marginLeft: '16px' }}>
            <div>
              {status_save_edit_btn ? (
                <>
                  {status_save_edit_btn == 'success' ? (
                    <Fake_btn_defolt
                      color={'#0acf97'}
                      text_color={'#FFFFFF'}
                      hover_color={'#0acf97'}
                      text={'Сохранено!'}
                      height={'40px'}
                      width={'250px'}
                    />
                  ) : (
                    <Fake_btn_defolt
                      color={'#fa5c7c'}
                      text_color={'#FFFFFF'}
                      hover_color={'#fa5c7c'}
                      text={'Ошибка!'}
                      height={'40px'}
                      width={'250px'}
                    />
                  )}
                </>
              ) : (
                <Btn_defolt
                  color={'#0acf97'}
                  text_color={'#FFFFFF'}
                  hover_color={'#07b584'}
                  text={'Сохранить изменения'}
                  height={'40px'}
                  width={'250px'}
                  onClick={save_edit_group}
                />
              )}
            </div>
            <div style={{ marginLeft: '24px' }}>
              <Btn_defolt
                color={'#fa5c7c'}
                text_color={'#FFFFFF'}
                hover_color={'#fa4166'}
                text={'Удалить группу'}
                height={'40px'}
                width={'250px'}
                onClick={delete_group}
              />
            </div>
          </div>
        </div>
        <Modal_with_accept active={modal_delete_active} setActive={setActive_modal_delete}>
          <div className='text_accept_delete'>
            Вы уверены, что хотите удалить группу "{name_delete}"?
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '30px',
            }}
          >
            <Btn_defolt
              color={'#EFF3FF'}
              text_color={'#6486FB'}
              hover_color={'#dbe4ff'}
              text={'Отмена'}
              height={'40px'}
              width={'150px'}
              onClick={(e) => setActive_modal_delete(false)}
            />
            <Btn_defolt
              color={'#6486FB'}
              text_color={'#FFFFFF'}
              hover_color={'#5179fc'}
              text={'Удалить'}
              height={'40px'}
              width={'150px'}
              onClick={accept_delete}
            />
          </div>
          <div
            className={
              status_modal_delete == 'success'
                ? 'status_modal_success active'
                : 'status_modal_success'
            }
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='text_status_main'>Успешно!</div>
              <div className='text_status_info'>Группа удалена!</div>
            </div>
          </div>
          <div
            className={
              status_modal_delete == 'error' ? 'status_modal_error active' : 'status_modal_error'
            }
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='text_status_main'>Ошибка!</div>
              <div className='text_status_info'>Не удалось удалить группу!</div>
            </div>
          </div>
        </Modal_with_accept>
      </div>
    )
  }
}
