import React, { useEffect, useState, useContext } from 'react'
import '../../../styles/park_settings/main_park_settings.css'
import ReactLoading from 'react-loading'
import { useCookies } from 'react-cookie'
import axios_api from '../../../API/axios_api'
import map_api from '../../../API/map_api'
import { MapContext } from '../../../context/index'
import { ParkSettingsContext } from '../../../context/index'
import Icon_btn from '../../UI/icon_btn.jsx'
import Btn_defolt from '../../UI/btn_defolt.jsx'
import Fake_btn_defolt from '../../UI/fake_btn_defolt.jsx'
import Basic_input from '../../UI/basic_input.jsx'
import Checkbox_with_text from '../../UI/checkbox_with_text.jsx'
import Btn_arrow_for_settings from '../btn_arrow_for_settings.jsx'
import plus_white from '../../../styles/icons/plus_white.svg'
import Switch from 'react-switch'
import Modal_add_user from './modal_add_user.jsx'
import Modal_with_accept from '../../UI/modal_with_accept'
import arrow_left_white_icon from '../../../styles/icons/arrow_left_white_icon.svg'
import arrow_left_blue_icon from '../../../styles/icons/arrow_left_blue_icon.svg'

import Table_item_users from './table_item_users.jsx'

import { SlideDown } from 'react-slidedown'
import SimpleBar from 'simplebar-react'
import 'react-slidedown/lib/slidedown.css'

import Slider from 'react-input-slider'
import user_loaded from '../../../actions/users_actions.js'

import { useDispatch, useSelector } from 'react-redux'

export default function Users_settings(props) {
  const dispatch = useDispatch()
  let { map_object, set_map_object } = useContext(MapContext)

  const window_height = useSelector((state) => state.window_height)
  const simplebar_height = window_height - 70 - 24 - 24 - 140

  let { park_settings_object } = useContext(ParkSettingsContext)
  const [cookies] = useCookies(['csrftoken'])

  const [last_name_create, setLast_name_create] = useState('')
  const [first_name_create, setFirst_name_create] = useState('')
  const [middle_name_create, setMiddle_name_create] = useState('')
  const [mail_create, setMail_create] = useState('')
  const [password_create, setPassword_create] = useState('')

  const [modal_user_add_active, setActive_modal_user_add] = useState(false)
  const [status_modal_user_add, setStatus_modal_user_add] = useState('')

  const [page, setPage] = useState('list')
  const [user_edit, setUser_edit] = useState(null)
  const [btn_back_active, setBtn_back_active] = useState(false)

  const [last_name, setLast_name] = useState('')
  const [first_name, setFirst_name] = useState('')
  const [middle_name, setMiddle_name] = useState('')
  const [mail, setMail] = useState('')
  const [new_password, setNew_password] = useState('')

  const [last_name_status, setLast_name_status] = useState('active')
  const [first_name_status, setFirst_name_status] = useState('active')
  const [middle_name_status, setMiddle_name_status] = useState('active')
  const [mail_status, setMail_status] = useState('active')
  const [new_password_status, setNew_password_status] = useState('active')

  const [is_groups, setIs_groups] = useState(false)
  const [is_car_edit, setIs_car_edit] = useState(false)
  const [is_settings, setIs_settings] = useState(false)
  const [is_engine, setIs_engine] = useState(false)
  const [checkboxes_groups, setCheckboxes_groups] = useState([])

  const [is_groups_create, setIs_groups_create] = useState(true)
  const [is_car_edit_create, setIs_car_edit_create] = useState(false)
  const [is_engine_create, setIs_engine_create] = useState(true)
  const [is_settings_create, setIs_settings_create] = useState(true)
  const [checkboxes_groups_create, setCheckboxes_groups_create] = useState([])

  const [render, setRender] = useState(new Date())

  const [modal_delete_active, setActive_modal_delete] = useState(false)
  const [name_first_delete, setName_first_delete] = useState('')
  const [name_last_delete, setName_last_delete] = useState('')
  const [name_middle_delete, setName_middle_delete] = useState('')
  const [status_modal_delete, setStatus_modal_delete] = useState('')

  const [slider, setSlider] = useState({ x: 1 })
  const [slider_status, setSlider_status] = useState('')

  function row_click(id) {
    console.log(id)
    console.log('props.groups : ', props.groups)

    for (let user of props.users) {
      if (user.id == id) {
        setUser_edit(user)
        setLast_name(user.last_name)
        setFirst_name(user.first_name)
        setMiddle_name(user.middle_name)
        setMail(user.email)
        setNew_password('')
        setSlider({ x: user.icons_size })

        if (user.permissions.includes('GROUPS')) {
          setIs_groups(true)
        } else {
          setIs_groups(false)
        }

        if (user.permissions.includes('SETTINGS')) {
          setIs_settings(true)
        } else {
          setIs_settings(false)
        }

        if (user.permissions.includes('ENGINE_BLOCK')) {
          setIs_engine(true)
        } else {
          setIs_engine(false)
        }

        if (user.permissions.includes('EDIT_CAR')) {
          setIs_car_edit(true)
        } else {
          setIs_car_edit(false)
        }

        let new_array = []
        for (let group of props.groups) {
          let find = false

          for (let user_group of user.car_groups) {
            if (user_group == group.id) {
              find = true
            }
          }

          let obj = {
            name: group.name,
            id: group.id,
            checked: false,
            cars: group.cars,
          }

          if (find) {
            obj.checked = true
          }

          new_array.push(obj)
        }
        setCheckboxes_groups(new_array)
      }
    }

    setPage('user_edit')
  }

  function go_back_to_list() {
    setPage('list')
    setBtn_back_active(false)
  }

  const [status_save_edit_btn, setStatus_save_edit_btn] = useState(null)

  async function save_edit_user() {
    let car_groups_send = []
    let permissions = []

    if (!is_groups) {
      for (let group of checkboxes_groups) {
        if (group.checked) {
          car_groups_send.push(group.id)
        }
      }
    } else {
      permissions.push('GROUPS')
    }

    if (is_settings) {
      permissions.push('SETTINGS')
    }

    if (is_engine) {
      permissions.push('ENGINE_BLOCK')
    }

    if (is_car_edit) {
      permissions.push('EDIT_CAR')
    }

    let response_edit_user = await axios_api.edit_user(
      user_edit.id,
      last_name,
      first_name,
      middle_name,
      mail,
      car_groups_send,
      permissions,
      slider.x,
      cookies.csrftoken,
    )

    if (response_edit_user.status == '200') {
      if (new_password != '') {
        let response_edit_password = await axios_api.edit_password_user(
          user_edit.id,
          new_password,
          cookies.csrftoken,
        )
        if (response_edit_password.status == '200') {
          setNew_password_status('success')
        } else {
          setNew_password_status('error')
        }
        setTimeout(function () {
          setNew_password_status('active')
        }, 2000)
      }

      setStatus_save_edit_btn('success')
      setLast_name_status('success')
      setFirst_name_status('success')
      setMiddle_name_status('success')
      setMail_status('success')
      setSlider_status('success')

      let user = await axios_api.get_user_info(cookies.csrftoken)
      dispatch(user_loaded(user))
    } else {
      setStatus_save_edit_btn('error')
      // setLast_name_status('error')
      // setFirst_name_status('error')
      // setMiddle_name_status('error')
      setMail_status('error')
      // setSlider_status('error')
    }

    setTimeout(function () {
      setStatus_save_edit_btn(null)
      setLast_name_status('active')
      setFirst_name_status('active')
      setMiddle_name_status('active')
      setMail_status('active')
      setSlider_status('')
    }, 2000)

    props.update_all()
  }

  async function submite_create_user() {
    let car_groups_send = []
    let permissions = []

    if (!is_groups_create) {
      for (let group of checkboxes_groups_create) {
        if (group.checked) {
          car_groups_send.push(group.id)
        }
      }
    } else {
      permissions.push('GROUPS')
    }

    if (is_settings_create) {
      permissions.push('SETTINGS')
    }

    if (is_engine_create) {
      permissions.push('ENGINE_BLOCK')
    }

    if (is_car_edit_create) {
      permissions.push('EDIT_CAR')
    }

    let last_name_create2 = last_name_create
    let first_name_create2 = first_name_create
    let middle_name_create2 = middle_name_create

    if (last_name_create == '') {
      last_name_create2 = '-'
    }

    if (first_name_create == '') {
      first_name_create2 = '-'
    }

    if (middle_name_create == '') {
      middle_name_create2 = '-'
    }

    if (mail_create == '' || password_create == '') {
      setStatus_modal_user_add('error')
      setTimeout(function () {
        setStatus_modal_user_add('')
      }, 1000)
    }

    let response_create_user = await axios_api.post_new_user(
      last_name_create2,
      first_name_create2,
      middle_name_create2,
      mail_create,
      password_create,
      car_groups_send,
      permissions,
      cookies.csrftoken,
    )

    console.log('response_create_user : ', response_create_user)

    if (response_create_user == '201') {
      setStatus_modal_user_add('success')
      setTimeout(function () {
        setStatus_modal_user_add('')
        close_create_user()
      }, 1000)
      props.update_all()
    } else {
      setStatus_modal_user_add('error')
      setTimeout(function () {
        setStatus_modal_user_add('')
      }, 1000)
    }
  }

  function open_create_user() {
    setActive_modal_user_add(true)
    let new_array_create = []
    for (let group of props.groups) {
      let obj = {
        name: group.name,
        id: group.id,
        checked: false,
        cars: group.cars,
      }
      new_array_create.push(obj)
    }
    setCheckboxes_groups_create(new_array_create)
  }

  function close_create_user() {
    setActive_modal_user_add(false)
    setLast_name_create('')
    setFirst_name_create('')
    setMiddle_name_create('')
    setMail_create('')
    setPassword_create('')
  }

  function check_checked(id) {
    //console.log('checkboxes_groups : ', checkboxes_groups)
    for (let group of checkboxes_groups) {
      if (group.id == id) {
        // console.log('group : ', group)
        if (group.checked) {
          // console.log('return true')
          return true
        } else {
          // console.log('return false')
          return false
        }
      }
    }
  }

  function check_checked_create(id) {
    //console.log('checkboxes_groups : ', checkboxes_groups)
    for (let group of checkboxes_groups_create) {
      if (group.id == id) {
        // console.log('group : ', group)
        if (group.checked) {
          // console.log('return true')
          return true
        } else {
          // console.log('return false')
          return false
        }
      }
    }
  }

  function change_group_checkbox(e) {
    let id = e.currentTarget.getAttribute('data_id')
    let checked = e.currentTarget.checked

    let new_array = checkboxes_groups

    for (let group of new_array) {
      if (group.id == parseFloat(id)) {
        group.checked = checked
      }
    }
    // console.log(new_array)
    // setCheckboxes_groups(null)
    setCheckboxes_groups(new_array)
    setRender(new Date())
    // setIs_groups(false)
  }

  function change_group_checkbox_create(e) {
    let id = e.currentTarget.getAttribute('data_id')
    let checked = e.currentTarget.checked

    let new_array = checkboxes_groups_create

    for (let group of new_array) {
      if (group.id == parseFloat(id)) {
        group.checked = checked
      }
    }
    // console.log(new_array)
    // setCheckboxes_groups(null)
    setCheckboxes_groups_create(new_array)
    setRender(new Date())
    // setIs_groups(false)
  }

  function delete_user() {
    for (let user of props.users) {
      if (user.id == user_edit.id) {
        setName_first_delete(user.first_name)
        setName_last_delete(user.last_name)
        setName_middle_delete(user.middle_name)
      }
    }
    setActive_modal_delete(true)
  }

  async function accept_delete() {
    let response = await axios_api.delete_user(user_edit.id, cookies.csrftoken)

    if (response.status == '204') {
      setStatus_modal_delete('success')
    } else {
      setStatus_modal_delete('error')
    }

    setTimeout(function () {
      setActive_modal_delete(false)
      setStatus_modal_delete('')
      if (response.status == '204') {
        props.update_all()
        setPage('list')
      }
    }, 1000)
  }

  if (page == 'list') {
    return (
      <div className='div_main_setting'>
        <div className='header_user_settings'>
          <div className='text_header_user_settings'>Пользователи</div>
          <Icon_btn
            color={'#6486FB'}
            hover_color={'#5179fc'}
            icon={plus_white}
            height={'36px'}
            width={'60px'}
            onClick={open_create_user}
          />
        </div>
        {props.users && props.groups ? (
          <div className='div_table_users_settings'>
            <div className='header_table_users_settings'>
              <div className='th_name_users_settings'>Имя и должность</div>
              <div className='th_mail_users_settings'>Почта</div>
              <div className='th_groups_users_settings'>Группы авто</div>
              <div className='th_arrows_users_settings'></div>
            </div>
            <div className='table_users_settings'>
              <SimpleBar style={{ maxHeight: simplebar_height }}>
                {props.users.map((user) => (
                  <Table_item_users key={user.id} user={user} row_click={row_click} />
                ))}
              </SimpleBar>
            </div>
          </div>
        ) : (
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ReactLoading type={'spin'} color='#6486FB' />
          </div>
        )}
        <Modal_add_user active={modal_user_add_active} setActive={close_create_user}>
          <div className='text_create_group'>Создать пользователя</div>
          <div>
            <div style={{ display: 'flex', marginTop: '35px' }}>
              <div>
                <Basic_input
                  value={last_name_create}
                  text={'Фамилия'}
                  status={'active'}
                  width={'300px'}
                  type={'text'}
                  onChange={setLast_name_create}
                />
              </div>
              <div style={{ marginLeft: '24px' }}>
                <Basic_input
                  value={first_name_create}
                  text={'Имя'}
                  status={'active'}
                  width={'300px'}
                  type={'text'}
                  onChange={setFirst_name_create}
                />
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: '35px' }}>
              <div>
                <Basic_input
                  value={middle_name_create}
                  text={'Отчество'}
                  status={'active'}
                  width={'300px'}
                  type={'text'}
                  onChange={setMiddle_name_create}
                />
              </div>
              <div style={{ marginLeft: '24px' }}>
                <Basic_input
                  value={mail_create}
                  text={'Почта'}
                  status={'active'}
                  width={'300px'}
                  type={'text'}
                  onChange={setMail_create}
                />
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: '35px' }}>
              <div>
                <Basic_input
                  value={password_create}
                  text={'Пароль'}
                  status={'active'}
                  width={'624px'}
                  type={'text'}
                  onChange={setPassword_create}
                />
              </div>
            </div>
            <div
              style={{
                marginTop: '35px',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <Switch
                onChange={(e) => setIs_settings_create(!is_settings_create)}
                checked={is_settings_create}
                uncheckedIcon={false}
                checkedIcon={false}
                height={20}
                width={36}
                offColor={'#E7ECFB'}
                onColor={'#6486FB'}
                activeBoxShadow={'0 0 2px 3px #6486FB'}
              />
              <div
                className={
                  is_settings_create ? 'text_checkbox_with_text active' : 'text_checkbox_with_text'
                }
                style={{ marginLeft: '10px' }}
              >
                Доступ к настройкам
              </div>
            </div>
            <div
              style={{
                marginTop: '25px',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <Switch
                onChange={(e) => setIs_engine_create(!is_engine_create)}
                checked={is_engine_create}
                uncheckedIcon={false}
                checkedIcon={false}
                height={20}
                width={36}
                offColor={'#E7ECFB'}
                onColor={'#6486FB'}
                activeBoxShadow={'0 0 2px 3px #6486FB'}
              />
              <div
                className={
                  is_engine_create ? 'text_checkbox_with_text active' : 'text_checkbox_with_text'
                }
                style={{ marginLeft: '10px' }}
              >
                Доступ к глушению авто
              </div>
            </div>
            <div
              style={{
                marginTop: '25px',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <Switch
                onChange={(e) => setIs_car_edit_create(!is_car_edit_create)}
                checked={is_car_edit_create}
                uncheckedIcon={false}
                checkedIcon={false}
                height={20}
                width={36}
                offColor={'#E7ECFB'}
                onColor={'#6486FB'}
                activeBoxShadow={'0 0 2px 3px #6486FB'}
              />
              <div
                className={
                  is_car_edit_create ? 'text_checkbox_with_text active' : 'text_checkbox_with_text'
                }
                style={{ marginLeft: '10px' }}
              >
                Доступ к редактированию авто
              </div>
            </div>
            <div
              style={{
                marginTop: '25px',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <Switch
                onChange={(e) => setIs_groups_create(!is_groups_create)}
                checked={is_groups_create}
                uncheckedIcon={false}
                checkedIcon={false}
                height={20}
                width={36}
                offColor={'#E7ECFB'}
                onColor={'#6486FB'}
                activeBoxShadow={'0 0 2px 3px #6486FB'}
              />
              <div
                className={
                  is_groups_create ? 'text_checkbox_with_text active' : 'text_checkbox_with_text'
                }
                style={{ marginLeft: '10px' }}
              >
                Доступ ко всем группам
              </div>
            </div>
            <SlideDown className={'my-dropdown-slidedown'}>
              {!is_groups_create ? (
                <div className=''>
                  {/* <div className='text_header_group_edit_settings' style={{'fontSize': '18px', 'marginTop': '10px', 'marginLeft': '0px'}}>
                                            Группы авто:
                                        </div> */}
                  {checkboxes_groups_create.map((group) => (
                    <div
                      className='div_checkbox_with_text'
                      key={group.id}
                      style={{
                        width: '624px',
                        height: '45px',
                        borderBottom: '1px solid #EFF2F5',
                      }}
                    >
                      <input
                        className='form-check-input'
                        type='checkbox'
                        data_id={group.id}
                        checked={check_checked_create(group.id)}
                        onChange={(e) => change_group_checkbox_create(e)}
                        style={{ marginLeft: '8px' }}
                      />
                      <div
                        className={
                          check_checked_create(group.id)
                            ? 'text_checkbox_with_text active'
                            : 'text_checkbox_with_text'
                        }
                        style={{ marginLeft: '20px' }}
                      >
                        {group.name}
                      </div>
                      <div
                        className={
                          check_checked_create(group.id)
                            ? 'text_checkbox_with_text active'
                            : 'text_checkbox_with_text'
                        }
                        style={{ marginLeft: 'auto' }}
                      >
                        {group.cars.length} авто
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </SlideDown>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '35px',
            }}
          >
            <Btn_defolt
              color={'#EFF3FF'}
              text_color={'#6486FB'}
              hover_color={'#dbe4ff'}
              text={'Отмена'}
              height={'40px'}
              width={'180px'}
              onClick={close_create_user}
            />
            <Btn_defolt
              color={'#6486FB'}
              text_color={'#FFFFFF'}
              hover_color={'#5179fc'}
              text={'Создать'}
              height={'40px'}
              width={'180px'}
              onClick={submite_create_user}
            />
          </div>
          <div
            className={
              status_modal_user_add == 'success'
                ? 'status_modal_success active'
                : 'status_modal_success'
            }
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='text_status_main'>Успешно!</div>
            </div>
          </div>
          <div
            className={
              status_modal_user_add == 'error' ? 'status_modal_error active' : 'status_modal_error'
            }
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='text_status_main'>
                Ошибка!
                <div>Пожалуйста, проверьте правильность заполнения данных!</div>
              </div>
            </div>
          </div>
        </Modal_add_user>
      </div>
    )
  } else {
    return (
      <div className='div_main_setting'>
        <div className='header_group_edit_settings'>
          <div
            onMouseEnter={(e) => setBtn_back_active(true)}
            onMouseLeave={(e) => setBtn_back_active(false)}
            onClick={go_back_to_list}
            style={{ cursor: 'pointer' }}
          >
            <Btn_arrow_for_settings
              active={btn_back_active}
              color={'#EFF3FF'}
              active_color={'#6486FB'}
              not_active_icon={arrow_left_blue_icon}
              active_icon={arrow_left_white_icon}
              height={'36px'}
              width={'36px'}
            />
          </div>
          <div className='text_header_group_edit_settings'>Редактирование пользователя</div>
        </div>
        <div
          style={{ display: 'flex', marginTop: '35px', marginLeft: '16px', marginRight: '16px' }}
        >
          <div>
            <Basic_input
              value={last_name}
              text={'Фамилия'}
              status={last_name_status}
              width={'300px'}
              type={'text'}
              onChange={setLast_name}
            />
          </div>
          <div style={{ marginLeft: '24px' }}>
            <Basic_input
              value={first_name}
              text={'Имя'}
              status={first_name_status}
              width={'300px'}
              type={'text'}
              onChange={setFirst_name}
            />
          </div>
        </div>
        <div
          style={{ display: 'flex', marginTop: '35px', marginLeft: '16px', marginRight: '16px' }}
        >
          <div>
            <Basic_input
              value={middle_name}
              text={'Отчество'}
              status={middle_name_status}
              width={'300px'}
              type={'text'}
              onChange={setMiddle_name}
            />
          </div>
          <div style={{ marginLeft: '24px' }}>
            <Basic_input
              value={mail}
              text={'Почта'}
              status={mail_status}
              width={'300px'}
              type={'text'}
              onChange={setMail}
            />
          </div>
        </div>
        <div
          style={{ display: 'flex', marginTop: '35px', marginLeft: '16px', marginRight: '16px' }}
        >
          <div>
            <Basic_input
              value={new_password}
              text={'Введите новый пароль, если хотите его изменить'}
              status={new_password_status}
              width={'624px'}
              type={'text'}
              onChange={setNew_password}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: '35px',
            marginLeft: '16px',
            marginRight: '16px',
            marginBottom: '20px',
          }}
        >
          <div className={'text_checkbox_with_text ' + slider_status} style={{ marginLeft: '0px' }}>
            Размер авто на карте
          </div>
          <div style={{ width: '200px', marginTop: '7px', marginLeft: '10px' }}>
            <Slider
              xmax={2}
              axis='x'
              xstep={0.1}
              x={slider.x}
              xmin={0.3}
              onChange={setSlider}
              styles={{
                active: {
                  backgroundColor: '#6486FB',
                },
              }}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <div className='text_basic_input'>0.3x</div>
            <div className='text_basic_input' style={{ marginLeft: '55px' }}>
              1.0x
            </div>
            <div className='text_basic_input' style={{ marginLeft: '95px' }}>
              2.0x
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: '35px',
            display: 'flex',
            alignItems: 'center',
            marginLeft: '16px',
            marginBottom: '20px',
          }}
        >
          <Switch
            onChange={(e) => setIs_settings(!is_settings)}
            checked={is_settings}
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={36}
            offColor={'#E7ECFB'}
            onColor={'#6486FB'}
            activeBoxShadow={'0 0 2px 3px #6486FB'}
          />
          <div
            className={is_settings ? 'text_checkbox_with_text active' : 'text_checkbox_with_text'}
            style={{ marginLeft: '10px' }}
          >
            Доступ к настройкам
          </div>
        </div>
        <div
          style={{
            marginTop: '25px',
            display: 'flex',
            alignItems: 'center',
            marginLeft: '16px',
            marginBottom: '20px',
          }}
        >
          <Switch
            onChange={(e) => setIs_engine(!is_engine)}
            checked={is_engine}
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={36}
            offColor={'#E7ECFB'}
            onColor={'#6486FB'}
            activeBoxShadow={'0 0 2px 3px #6486FB'}
          />
          <div
            className={is_engine ? 'text_checkbox_with_text active' : 'text_checkbox_with_text'}
            style={{ marginLeft: '10px' }}
          >
            Доступ к глушению авто
          </div>
        </div>
        <div
          style={{
            marginTop: '25px',
            display: 'flex',
            alignItems: 'center',
            marginLeft: '16px',
            marginBottom: '20px',
          }}
        >
          <Switch
            onChange={(e) => setIs_car_edit(!is_car_edit)}
            checked={is_car_edit}
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={36}
            offColor={'#E7ECFB'}
            onColor={'#6486FB'}
            activeBoxShadow={'0 0 2px 3px #6486FB'}
          />
          <div
            className={is_car_edit ? 'text_checkbox_with_text active ' : 'text_checkbox_with_text '}
            style={{ marginLeft: '10px' }}
          >
            Доступ к редактированию авто
          </div>
        </div>
        <div
          style={{
            marginTop: '25px',
            display: 'flex',
            alignItems: 'center',
            marginLeft: '16px',
            marginBottom: '20px',
          }}
        >
          <Switch
            onChange={(e) => setIs_groups(!is_groups)}
            checked={is_groups}
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={36}
            offColor={'#E7ECFB'}
            onColor={'#6486FB'}
            activeBoxShadow={'0 0 2px 3px #6486FB'}
          />
          <div
            className={is_groups ? 'text_checkbox_with_text active ' : 'text_checkbox_with_text '}
            style={{ marginLeft: '10px' }}
          >
            Доступ ко всем группам
          </div>
        </div>
        <SlideDown className={'my-dropdown-slidedown'}>
          {!is_groups ? (
            <div className=''>
              {/* <div className='text_header_group_edit_settings' style={{'fontSize': '18px', 'marginTop': '10px'}}>
                                    Группы авто:
                                </div> */}
              {checkboxes_groups.map((group) => (
                <div
                  className='div_checkbox_with_text'
                  key={group.id}
                  style={{
                    marginLeft: '16px',
                    width: '624px',
                    height: '45px',
                    borderBottom: '1px solid #EFF2F5',
                  }}
                >
                  <input
                    className='form-check-input'
                    type='checkbox'
                    data_id={group.id}
                    checked={check_checked(group.id)}
                    onChange={(e) => change_group_checkbox(e)}
                    style={{ marginLeft: '8px' }}
                  />
                  <div
                    className={
                      check_checked(group.id)
                        ? 'text_checkbox_with_text active'
                        : 'text_checkbox_with_text'
                    }
                    style={{ marginLeft: '20px' }}
                  >
                    {group.name}
                  </div>
                  <div
                    className={
                      check_checked(group.id)
                        ? 'text_checkbox_with_text active'
                        : 'text_checkbox_with_text'
                    }
                    style={{ marginLeft: 'auto' }}
                  >
                    {group.cars.length} авто
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </SlideDown>

        {/* <Checkbox_with_text checked={mileage_checkbox} text={'Пробег'} onChange={setMileage_checkbox}/> */}
        <div style={{ display: 'flex', marginTop: '24px', marginLeft: '16px' }}>
          <div>
            {status_save_edit_btn ? (
              <>
                {status_save_edit_btn == 'success' ? (
                  <Fake_btn_defolt
                    color={'#0acf97'}
                    text_color={'#FFFFFF'}
                    hover_color={'#0acf97'}
                    text={'Сохранено!'}
                    height={'40px'}
                    width={'250px'}
                  />
                ) : (
                  <Fake_btn_defolt
                    color={'#fa5c7c'}
                    text_color={'#FFFFFF'}
                    hover_color={'#fa5c7c'}
                    text={'Ошибка!'}
                    height={'40px'}
                    width={'250px'}
                  />
                )}
              </>
            ) : (
              <Btn_defolt
                color={'#0acf97'}
                text_color={'#FFFFFF'}
                hover_color={'#07b584'}
                text={'Сохранить изменения'}
                height={'40px'}
                width={'250px'}
                onClick={save_edit_user}
              />
            )}
          </div>
          <div style={{ marginLeft: '24px' }}>
            <Btn_defolt
              color={'#fa5c7c'}
              text_color={'#FFFFFF'}
              hover_color={'#fa4166'}
              text={'Удалить пользователя'}
              height={'40px'}
              width={'250px'}
              onClick={delete_user}
            />
          </div>
        </div>
        <Modal_with_accept active={modal_delete_active} setActive={setActive_modal_delete}>
          <div className='text_accept_delete'>
            Вы уверены, что хотите удалить пользователя "{name_last_delete} {name_first_delete}{' '}
            {name_middle_delete}"?
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <Btn_defolt
              color={'#EFF3FF'}
              text_color={'#6486FB'}
              hover_color={'#dbe4ff'}
              text={'Отмена'}
              height={'40px'}
              width={'150px'}
              onClick={(e) => setActive_modal_delete(false)}
            />
            <Btn_defolt
              color={'#6486FB'}
              text_color={'#FFFFFF'}
              hover_color={'#5179fc'}
              text={'Удалить'}
              height={'40px'}
              width={'150px'}
              onClick={accept_delete}
            />
          </div>
          <div
            className={
              status_modal_delete == 'success'
                ? 'status_modal_success active'
                : 'status_modal_success'
            }
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='text_status_main'>Успешно!</div>
              <div className='text_status_info'>Пользователь удален!</div>
            </div>
          </div>
          <div
            className={
              status_modal_delete == 'error' ? 'status_modal_error active' : 'status_modal_error'
            }
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='text_status_main'>Ошибка!</div>
              <div className='text_status_info'>Не удалось удалить пользователя!</div>
            </div>
          </div>
        </Modal_with_accept>
      </div>
    )
  }
}
