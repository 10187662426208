import React from "react";
import '../../../styles/routes_map/routes.css'

const Modal_mask_window = ({active, children}) => {

    return (
        <div className={active ? "modal_mask_window active" : "modal_mask_window"}>
            <div className="modal_mask_window_content">
                {children}
            </div>
        </div>
    );

}

export default Modal_mask_window;