/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react'
import '../../../styles/park_settings/main_park_settings.css'
import Btn_defolt from '../../UI/btn_defolt.jsx'
import axios_api from '../../../API/axios_api'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import Modal_add_license from './modal_add_license.jsx'
import SimpleBar from 'simplebar-react'
import mdi_download from '../../../styles/icons/mdi_download.svg'
import Basic_input from '../../UI/basic_input.jsx'
import Tippy from '@tippyjs/react/headless'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import moment from 'moment'

export default function Bills_settings(props) {
  const [cookies] = useCookies(['csrftoken'])
  const window_height = useSelector((state) => state.window_height)
  const simplebar_height = window_height - 70 - 24 - 24 - 140

  const [addActive, setAddActive] = useState(false)
  const [addAmount, setAddAmount] = useState(1)
  const [addAmountStatus, setAddAmountStatus] = useState('active')
  const [addType, setAddType] = useState('invoice')
  const [addStatus, setAddStatus] = useState('')
  const [isLoadingAdd, setIsLoadingAdd] = useState(false)

  async function download_pdf(bill, date) {
    await axios_api.download_bill(bill.url, convert_(date), cookies.csrftoken)
  }

  async function pay_bill(id) {
    let pay_bill_url = await axios_api.pay_bill_url(id, cookies.csrftoken)
    const link = document.createElement('a')
    link.href = pay_bill_url
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  function convert(date) {
    return moment(date, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY')
  }

  function convert_(date) {
    return moment(date, 'YYYY-MM-DDTHH:mm:ss').format('DD_MM_YYYY')
  }

  const submiteAdd = async () => {
    if (isLoadingAdd) return

    if (!addAmount || /[a-zа-яё]/i.test(addAmount)) {
      setAddAmountStatus('error')
      setTimeout(() => {
        setAddAmountStatus('active')
      }, 2000)
      return
    }

    setIsLoadingAdd(true)

    let responseAddLicense = await axios_api.addLicense(addType, addAmount, cookies.csrftoken)

    if (responseAddLicense.status === 201) {
      setAddStatus('success')
    } else {
      setAddStatus('error')
    }

    setTimeout(() => {
      setAddStatus(null)
      setAddActive(false)
    }, 2000)

    props.update_all()

    setIsLoadingAdd(false)
  }

  return (
    <div className='div_main_setting'>
      <div className='header_refill_settings'>
        <div className='text_header_refill_settings'>Лицензии</div>
      </div>
      <div className='licenses_balance_div'>
        <div className='text_licenses_balance'>{`У Вас ${props.user_current.partner.licenses.toFixed()} лицензий`}</div>
        <div
          style={{ marginTop: '8px' }}
          className={
            props.user_current.partner.days_left > 7
              ? 'text_licenses_balance'
              : 'text_licenses_balance error'
          }
        >{`Подписка активна на ${props.user_current.partner.days_left} дн`}</div>
        <div style={{ marginTop: '8px' }}>
          <Btn_defolt
            color={'#6486FB'}
            hover_color={'#5179fc'}
            text_color={'#FFFFFF'}
            text={'Купить лицензии'}
            height={'30px'}
            width={'100%'}
            onClick={() => setAddActive(true)}
          />
        </div>
      </div>
      <div className='header_refill_settings'>
        <div className='text_header_refill_settings'>Счета</div>
      </div>
      {props.bills != null && props.bills && props.bills.length !== 0 ? (
        <div style={{ marginTop: '30px', width: '860px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
              paddingLeft: '16px',
              paddingRight: '16px',
            }}
          >
            <div className='thead_table_bills_list' style={{ width: '100px' }}>
              Дата
            </div>
            <div className='thead_table_bills_list' style={{ width: '100px' }}>
              Сумма
            </div>
            <div className='thead_table_bills_list' style={{ width: '100px' }}>
              Статус
            </div>
            <div className='thead_table_bills_list' style={{ width: '200px' }}>
              Комментарий
            </div>
            <div className='thead_table_bills_list' style={{ width: '120px' }}></div>
          </div>
          <SimpleBar style={{ maxHeight: simplebar_height }}>
            {props.bills.map((bill) => (
              <div key={bill.id}>
                <div className={'row_table_bills_list'}>
                  <div className='td_date_table_bills_list'>{convert(bill.date)}</div>
                  <div className='td_amount_table_bills_list'>{bill.amount}₽</div>
                  <div className='td_paid_table_bills_list'>
                    {bill.is_paid ? (
                      <div className='true_paid_table_bills_list'>Оплачен</div>
                    ) : (
                      <div className='false_paid_table_bills_list'>Не оплачен</div>
                    )}
                  </div>
                  <div className='td_comment_table_bills_list'>
                    {bill.comment === '' ? (
                      <div className=''>-</div>
                    ) : (
                      <div className=''>{bill.comment}</div>
                    )}
                  </div>
                  <div className='td_download_table_bills_list'>
                    {bill.use_acquiring ? (
                      <>
                        {bill.is_paid ? (
                          <div className=''></div>
                        ) : (
                          <Btn_defolt
                            color={'#0acf97'}
                            text_color={'#FFFFFF'}
                            hover_color={'#0acf97'}
                            text={'Оплатить'}
                            height={'30px'}
                            width={'120px'}
                            onClick={() => pay_bill(bill.id)}
                          />
                        )}
                      </>
                    ) : (
                      <Tippy
                        render={(attrs) => (
                          <div className='box tippy_content' tabIndex='-1' {...attrs}>
                            Скачать счет
                          </div>
                        )}
                      >
                        <img src={mdi_download} onClick={() => download_pdf(bill, bill.date)} />
                      </Tippy>
                    )}
                  </div>
                </div>
                <div className='div_border_bottom_table_bills_list'>
                  <div className='border_bottom_table_bills_list'></div>
                </div>
              </div>
            ))}
          </SimpleBar>
        </div>
      ) : (
        <div style={{ marginTop: '30px', marginLeft: '16px' }} className='no_bills'>
          Счета отсутсвуют
        </div>
      )}
      <Modal_add_license active={addActive} setActive={setAddActive}>
        <div className='text_add_license'>Купить лицензии</div>
        <div
          className='div_radio_license'
          style={{
            marginTop: '32px',
          }}
          onClick={() => setAddType('invoice')}
        >
          <input type='radio' className='radio_add_license' checked={addType === 'invoice'} />
          <div className='text_radio_add_license'>Расчетным счетом</div>
        </div>
        <div className='div_radio_license' onClick={() => setAddType('payment')}>
          <input type='radio' className='radio_add_license' checked={addType === 'payment'} />
          <div className='text_radio_add_license'>Банковской картой</div>
        </div>
        <SlideDown className={'my-dropdown-slidedown'}>
          {addType === 'invoice' ? (
            <>
              <div
                style={{
                  marginTop: '24px',
                }}
              >
                <Basic_input
                  value={props.user_current.partner.inn}
                  text={'ИНН'}
                  status={'readonly'}
                  width={'450px'}
                  type={'text'}
                  onChange={() => {}}
                />
              </div>
              <div
                style={{
                  marginTop: '24px',
                }}
              >
                <Basic_input
                  value={props.user_current.partner.kpp}
                  text={'КПП'}
                  status={'readonly'}
                  width={'450px'}
                  type={'text'}
                  onChange={() => {}}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </SlideDown>
        <div
          style={{
            marginTop: '24px',
          }}
        >
          <Basic_input
            value={addAmount}
            text={'Количество лицензий'}
            width={'450px'}
            status={addAmountStatus}
            type={'text'}
            onChange={setAddAmount}
          />
        </div>
        <div
          className='text_cost_add_license'
          style={{
            marginTop: '24px',
          }}
        >
          {props.cars && props.user_current && (
            <>
              Итого к оплате:
              <span className='text_dig_cost_add_license'>
                {parseFloat(addAmount) * props.user_current.partner.license_cost || '-'}₽{' '}
                {`(на ${
                  (parseFloat(addAmount) / props.cars.length).toFixed() >= 0
                    ? (parseFloat(addAmount) / props.cars.length).toFixed() * 30
                    : '-'
                } дн)`}
              </span>
            </>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '32px',
          }}
        >
          <Btn_defolt
            color={'#EFF3FF'}
            text_color={'#6486FB'}
            hover_color={'#dbe4ff'}
            text={'Отмена'}
            height={'40px'}
            width={'180px'}
            onClick={() => setAddActive(false)}
          />
          <Btn_defolt
            color={'#6486FB'}
            text_color={'#FFFFFF'}
            hover_color={'#5179fc'}
            text={'Купить'}
            height={'40px'}
            width={'180px'}
            onClick={submiteAdd}
          />
        </div>
        <div
          className={
            addStatus === 'success' ? 'status_modal_success active' : 'status_modal_success'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>Успешно!</div>
            <div className='text_status_info'>Лицензии куплены!</div>
          </div>
        </div>
        <div className={addStatus === 'error' ? 'status_modal_error active' : 'status_modal_error'}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>Ошибка!</div>
            <div className='text_status_info'>Не удалось купить лицензии!</div>
          </div>
        </div>
      </Modal_add_license>
    </div>
  )
}
