import car_profile_LIGHT_BLUE from "../styles/icons/car_profile_LIGHT_BLUE.svg"
import car_profile_BLUE from "../styles/icons/car_profile_BLUE.svg"
import car_profile_DARK_BLUE from "../styles/icons/car_profile_DARK_BLUE.svg"
import car_profile_DARKEST_BLUE from "../styles/icons/car_profile_DARKEST_BLUE.svg"
import car_profile_BROWN from "../styles/icons/car_profile_BROWN.svg"
import car_profile_ORANGE from "../styles/icons/car_profile_ORANGE.svg"
import car_profile_LIGHT_ORANGE from "../styles/icons/car_profile_LIGHT_ORANGE.svg"
import car_profile_LIME from "../styles/icons/car_profile_LIME.svg"
import car_profile_GREEN from "../styles/icons/car_profile_GREEN.svg"
import car_profile_BLACK from "../styles/icons/car_profile_BLACK.svg"
import car_profile_D_GRAY from "../styles/icons/car_profile_D_GRAY.svg"
import car_profile_WHITE from "../styles/icons/car_profile_WHITE.svg"
import car_profile_WAVE_BLUE from "../styles/icons/car_profile_WAVE_BLUE.svg"
import car_profile_CYAN from "../styles/icons/car_profile_CYAN.svg"
import car_profile_DARK_PURPLE from "../styles/icons/car_profile_DARK_PURPLE.svg"
import car_profile_PURPLE from "../styles/icons/car_profile_PURPLE.svg"
import car_profile_ROSE from "../styles/icons/car_profile_ROSE.svg"
import car_profile_ROSE_RED from "../styles/icons/car_profile_ROSE_RED.svg"
import car_profile_RED from "../styles/icons/car_profile_RED.svg"
import car_profile_YELLOW from "../styles/icons/car_profile_YELLOW.svg"
import car_profile_BLUE_GRAY from "../styles/icons/car_profile_BLUE_GRAY.svg"
import car_profile_GRAY from "../styles/icons/car_profile_GRAY.svg"
import car_profile_LIGHT_GRAY from "../styles/icons/car_profile_LIGHT_GRAY.svg"

export default class car_data{

    static get_svg_car(color){
        switch(color) {
            case 'LIGHT_BLUE':
                return car_profile_LIGHT_BLUE
            case 'BLUE':
                return car_profile_BLUE
            case 'DARK_BLUE':
                return car_profile_DARK_BLUE
            case 'DARKEST_BLUE':
                return car_profile_DARKEST_BLUE
            case 'BROWN':
                return car_profile_BROWN
            case 'ORANGE':
                return car_profile_ORANGE
            case 'LIGHT_ORANGE':
                return car_profile_LIGHT_ORANGE
            case 'LIME':
                return car_profile_LIME
            case 'GREEN':
                return car_profile_GREEN
            case 'BLACK':
                return car_profile_BLACK
            case 'D_GRAY':
                return car_profile_D_GRAY
            case 'WHITE':
                return car_profile_WHITE
            case 'WAVE_BLUE':
                return car_profile_WAVE_BLUE
            case 'CYAN':
                return car_profile_CYAN
            case 'DARK_PURPLE':
                return car_profile_DARK_PURPLE
            case 'PURPLE':
                return car_profile_PURPLE
            case 'ROSE':
                return car_profile_ROSE
            case 'ROSE_RED':
                return car_profile_ROSE_RED
            case 'RED':
                return car_profile_RED
            case 'YELLOW':
                return car_profile_YELLOW
            case 'BLUE_GRAY':
                return car_profile_BLUE_GRAY
            case 'GRAY':
                return car_profile_GRAY
            case 'LIGHT_GRAY':
                return car_profile_LIGHT_GRAY
    
            default:
                return car_profile_LIGHT_BLUE
        }
    }

    static get_hex_color_car(car_color){
        switch(car_color) {
            case 'LIGHT_BLUE':
                return '#6486FB'
            case 'BLUE':
                return '#3661F4'
            case 'DARK_BLUE':
                return '#002FD0'
            case 'DARKEST_BLUE':
                return '#000F43'
            case 'BROWN':
                return '#FB6464'
            case 'ORANGE':
                return '#FBA364'
            case 'LIGHT_ORANGE':
                return '#FBD164'
            case 'LIME':
                return '#C2FB64'
            case 'GREEN':
                return '#94FB64'
            case 'BLACK':
                return '#000000'
            case 'D_GRAY':
                return '#4E4E4E'
            case 'WHITE':
                return '#FFFFFF'
            case 'WAVE_BLUE':
                return '#64FBA9'
            case 'CYAN':
                return '#64FBFB'
            case 'DARK_PURPLE':
                return '#8B64FB'
            case 'PURPLE':
                return '#D464FB'
            case 'ROSE':
                return '#FB64C8'
            case 'ROSE_RED':
                return '#EE2964'
            case 'RED':
                return '#FF0000'
            case 'YELLOW':
                return '#FFEB3B'
            case 'BLUE_GRAY':
                return '#B0B9DB'
            case 'GRAY':
                return '#A2A2A2'
            case 'LIGHT_GRAY':
                return '#D5D5D5'
    
            default:
                return '#6486FB'
        }
    }
}