/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react'
import '../../../styles/park_settings/main_park_settings.css'
import Icon_btn from '../../UI/icon_btn.jsx'
import ReactLoading from 'react-loading'
import SimpleBar from 'simplebar-react'
import plus_white from '../../../styles/icons/plus_white.svg'
import TableItemInspections from './tableItemInspections.jsx'
import Search_input from '../../UI/search_input.jsx'
import { useSelector } from 'react-redux'

export default function List(props) {
  const inspectionsGroups = props.inspectionsGroups
  const window_height = useSelector((state) => state.window_height)
  const height_list = window_height - 70 - 24 - 24 - 140 - 35

  const [search, setSearch] = useState('')

  const row_click = (id) => {
    for (let item of inspectionsGroups) {
      if (parseInt(id) === item.id) {
        props.setInspection(item)
      }
    }
    props.setPage('inspection')
  }

  const btn_click = () => {
    props.setInspection(null)
    props.setPage('inspection')
  }

  let inspectionsGroupsSearched = inspectionsGroups

  if (search !== '') {
    inspectionsGroupsSearched = []

    for (let item of inspectionsGroups) {
      if (
        item.brand.toUpperCase().includes(search.toUpperCase()) ||
        item.model.toUpperCase().includes(search.toUpperCase()) ||
        item.verbose_name.toUpperCase().includes(search.toUpperCase())
      ) {
        inspectionsGroupsSearched.push(item)
      }
    }
  }

  return (
    <div className="div_main_setting">
      <div className="header_groups_settings">
        <div className="text_header_groups_settings">Планы ТО</div>
        <Icon_btn
          color={'#6486FB'}
          hover_color={'#5179fc'}
          icon={plus_white}
          height={'36px'}
          width={'60px'}
          onClick={btn_click}
        />
      </div>
      {inspectionsGroups !== null ? (
        <div className="div_table_inspections_settings">
          <div style={{ marginLeft: '13px' }}>
            <Search_input
              placeholder={
                'Введите название плана ТО, марку или модель автомобиля'
              }
              change={setSearch}
            />
          </div>
          <div className="header_table_inspections_settings">
            <div className="th_name_inspections_settings">
              Название плана ТО
            </div>
            <div className="th_brang_inspections_settings">Марка и модель</div>
            <div className="th_count_category_inspections_settings">
              Параметры ТО
            </div>
            <div className="th_count_cars_inspections_settings">Авто</div>
            <div className="th_arrows_inspections_settings"></div>
          </div>
          <SimpleBar style={{ maxHeight: height_list }}>
            <div className="table_inspections_settings">
              {inspectionsGroupsSearched.map((inspection) => (
                <TableItemInspections
                  key={inspection.id}
                  inspection={inspection}
                  cars_delailed={props.cars_delailed}
                  row_click={row_click}
                />
              ))}
            </div>
          </SimpleBar>
        </div>
      ) : (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ReactLoading type={'spin'} color="#6486FB" />
        </div>
      )}
    </div>
  )
}
