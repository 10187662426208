import React, { useEffect, useContext } from 'react'
import Draw_nav_bar_main from '../components/navbar/draw_nav_bar'
import Draw_park_settings from '../components/park_settings/draw_park_settings.jsx'

import { useDispatch, useSelector } from 'react-redux'
import resize_action from '../actions/window_actions.js'
import page_change from '../actions/router_actions.js'
import { MapContext } from '../context/index'
import $ from 'jquery'

const MainPark_settings = () => {
  let { map_object } = useContext(MapContext)
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = 'Настройки | ControlGPS'
    let height = $('#root').height()
    let width = $('#root').width()
    dispatch(resize_action(height, width))
    dispatch(page_change('park_settings'))
    map_object.markers = []
    map_object.map = null
  }, [])

  return <Draw_park_settings />
}

export default MainPark_settings
