import React, { useRef, useEffect, useState, useContext } from 'react'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import MapboxLanguage from '@mapbox/mapbox-gl-language'
import '../../index.css'
import '../../styles/map/map.css'

import { MapContext } from '../../context/index'
import map_api from '../../API/map_api'

mapboxgl.accessToken =
  'pk.eyJ1IjoidHR0dHR2IiwiYSI6ImNsN2t1aHNxdTBhYzkzd284Z3Nmams4eHAifQ.0sdD04xpHPIxNEqZpbpdhw'

export default function Draw_map(props) {
  let { map_object } = useContext(MapContext)

  const mapContainer = useRef(null)
  const map = useRef(null)
  const [lng, setLng] = useState(props.park_current.map_default_lon)
  const [lat, setLat] = useState(props.park_current.map_default_lat)
  const [zoom, setZoom] = useState(props.park_current.map_default_zoom)

  // map_object.markers = []
  // map_object.map = null

  useEffect(() => {
    if (map.current) return // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom,
      language: 'ru',
    })

    const language = new MapboxLanguage()
    map.current.addControl(language)
    map_object.lines = []
    map_object.speed_layers_and_sourses_id = []
    map_object.info_draw_routes.first_init_img_routes = true

    function click_on_marker_div(e) {
      if (map_object.active_click_new_zone) {
        if (e.target.data_id == 0) {
          if (map_object.new_markers_zones.length > 2) {
            console.log('figure done')
            map_api.draw_last_line_new_zone(map_object)
            map_object.map.off('click', click_on_map)
            map_object.figure_done()

            for (let marker of map_object.new_markers_zones) {
              marker.setDraggable(true)
              marker.on('drag', (e) => on_drag_marker_new_zone(e))
            }

            map_object.active_click_new_zone = false
          }
        }
      }

      e.stopPropagation()
    }

    async function click_on_map(e) {
      let arr = await map_api.add_marker_zone(e.lngLat.lng, e.lngLat.lat, map_object)
      let new_marker = arr[0]
      let new_marker_div = arr[1]
      map_object.new_markers_zones.push(new_marker)

      new_marker_div.addEventListener('click', (e) => click_on_marker_div(e))

      await map_api.draw_lines_new_zone(map_object)
      map_api.draw_layer_new_zone(map_object)
    }

    async function on_drag_marker_new_zone(e) {
      map_api.drag_marker_new_zone(map_object, e.target)
      map_api.draw_layer_new_zone(map_object)
    }

    async function close_new_zone() {
      await map_api.close_new_zone(map_object)
    }

    map_object.map = map.current
    map_object.click_on_map = click_on_map
    map_object.close_new_zone = close_new_zone
  })

  useEffect(() => {
    if (!map.current) return // wait for map to initialize
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4))
      setLat(map.current.getCenter().lat.toFixed(4))
      setZoom(map.current.getZoom().toFixed(2))
    })
  })

  return <div ref={mapContainer} className='map-container' />
}
