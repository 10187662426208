import React, { useEffect, useState, useContext } from 'react';
import '../../styles/car_list/car_list_main.css'
import { useCookies } from 'react-cookie';
import { CarListContext } from '../../context/index';
import car_data from "../../API/car_data"



import Car_color_item from './car_color_item.jsx'

export default function Car_color(props) {
    let {car_list_object} = useContext(CarListContext)
    const [cookies] = useCookies(['csrftoken']);

    const [current_color, setColor] = useState('')

    let text_color

    if (!props.car.color){
        text_color = 'LIGHT_BLUE'
    } else {
        text_color = props.car.color
    }

    async function color_click(color_name){
        console.log('color_name click: ', color_name)
        setColor(color_name)
        props.setColor(color_name)

    }

    useEffect(() => {
        if (!props.car.color){
            setColor('LIGHT_BLUE')
        } else {
            setColor(props.car.color)
        }
    }, [props.car.id]);

    return (
        <div className=''>
            <div className = {props.status=='success' ? 'text_car_color success' : 'text_car_color'}>
                Иконка автомобиля
            </div>
            <div className = {props.status=='success' ? 'div_car_colors success' : 'div_car_colors'}>
                <div>
                    <img src={car_data.get_svg_car(current_color)} style={{'marginLeft': '54px', 'marginRight': '54px'}}/>
                </div>
                <div className='div_car_colors_items'>
                    <Car_color_item color={car_data.get_hex_color_car('LIGHT_BLUE')} text_color={'LIGHT_BLUE'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('BLUE')} text_color={'BLUE'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('DARK_BLUE')} text_color={'DARK_BLUE'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('DARKEST_BLUE')} text_color={'DARKEST_BLUE'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('BROWN')} text_color={'BROWN'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('ORANGE')} text_color={'ORANGE'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('LIGHT_ORANGE')} text_color={'LIGHT_ORANGE'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('LIME')} text_color={'LIME'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('GREEN')} text_color={'GREEN'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('BLACK')} text_color={'BLACK'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('D_GRAY')} text_color={'D_GRAY'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('WHITE')} text_color={'WHITE'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('WAVE_BLUE')} text_color={'WAVE_BLUE'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('CYAN')} text_color={'CYAN'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('DARK_PURPLE')} text_color={'DARK_PURPLE'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('PURPLE')} text_color={'PURPLE'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('ROSE')} text_color={'ROSE'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('ROSE_RED')} text_color={'ROSE_RED'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('RED')} text_color={'RED'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('YELLOW')} text_color={'YELLOW'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('BLUE_GRAY')} text_color={'BLUE_GRAY'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('GRAY')} text_color={'GRAY'} color_checked={current_color} color_click={color_click}/>
                    <Car_color_item color={car_data.get_hex_color_car('LIGHT_GRAY')} text_color={'LIGHT_GRAY'} color_checked={current_color} color_click={color_click}/>
                </div>
            </div>
            
        </div>
    )

}