/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import SimpleBar from 'simplebar-react'
import React from 'react'
import { useSelector } from 'react-redux'
import './Share.css'

const Inputs = (props) => {
  const windowHeight = useSelector((state) => state.window_height)
  const simplebarHeight = props.isMobile
    ? windowHeight - 140 - 55 - 24 - 24
    : windowHeight - 70 - 24 - 24 - 55 - 24 - 24

  return (
    <div className='inputsDiv'>
      <SimpleBar style={{ maxHeight: simplebarHeight }}>
        {props.car.inputs.map((input) => (
          <div className='inputItem' key={input.verbose_name + input.value}>
            <div className='inputName'>{input.verbose_name}</div>
            <div className='inputValue'>{input.value + ' ' + input.units}</div>
          </div>
        ))}
      </SimpleBar>
    </div>
  )
}

export default Inputs
