/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState, useContext } from 'react'

import '../../styles/car_list/car_list_main.css'

import { useCookies } from 'react-cookie'
import axios_api from '../../API/axios_api'
import map_api from '../../API/map_api'
import { CarListContext } from '../../context/index'
import { MapContext } from '../../context/index'

import Car_list from './car_list.jsx'
import Car_profile from './car_profile.jsx'

export default function Draw_car_list() {
  let { car_list_object } = useContext(CarListContext)
  const [cookies] = useCookies(['csrftoken'])

  const [groups, setGroups] = useState(null)
  const [geozones, setGeozones] = useState(null)
  const [aggregators_for_select, setAggregators_for_select] = useState(null)
  const [cars_delailed, setCars_delailed] = useState(null)

  const [car_profile_id, setCar_profile_id] = useState(null)
  const [car_profile, setCar_profile] = useState(null)
  const [rerender, setRerender] = useState(new Date().getTime())
  const [cars_models, setCars_models] = useState([])
  const [cars_brands, setCars_brands] = useState([])

  useEffect(() => {
    get_groups()
    get_cars_detailed()
    get_aggregators()
    get_geozones()
    get_cars_brands_and_models()
  }, [])

  if (car_list_object.first_car_list) {
    setInterval(() => {
      get_groups()
      get_cars_detailed()
      get_aggregators()
      get_geozones()
      get_cars_brands_and_models()
    }, 30000)
    car_list_object.first_car_list = false
  }

  function click_car(id) {
    console.log('click_car_id : ', id)
    setCar_profile_id(id)

    for (let car of cars_delailed) {
      if (car.id == id) {
        setCar_profile(car)
      }
    }
  }

  if (!car_profile_id) {
    if (cars_delailed) {
      if (cars_delailed[0]) {
        click_car(cars_delailed[0].id)
      }
    }
  }

  if (cars_delailed) {
    check_car_url()
  }

  return (
    <div className='div_main_car_list'>
      <div className='div_car_list'>
        <Car_list
          groups={groups}
          cars={cars_delailed}
          car_profile_id={car_profile_id}
          click_car={click_car}
          get_cars_detailed={get_cars_detailed}
          get_cars_brands_and_models={get_cars_brands_and_models}
        />
      </div>
      <div className='div_car_profile' car_profile_id={car_profile_id}>
        <Car_profile
          car={car_profile}
          car_profile_id={car_profile_id}
          get_cars_brands_and_models={get_cars_brands_and_models}
          cars={cars_delailed}
          geozones={geozones}
          get_geozones={get_geozones}
          get_cars_detailed={get_cars_detailed}
          setCar_profile={setCar_profile}
          aggregators_for_select={aggregators_for_select}
          setRerender={setRerender}
          cars_models={cars_models}
          cars_brands={cars_brands}
        />
      </div>
    </div>
  )

  function check_car_url() {
    const queryParams = new URLSearchParams(window.location.search)
    const car_id_open = queryParams.get('car')
    //console.log('car_id_show : ', car_id_open)
    if (car_id_open) {
      const url = new URL(document.location)
      const searchParams = url.searchParams
      searchParams.delete('car')
      window.history.pushState({}, '', url.toString())
      click_car(parseFloat(car_id_open))
    }
  }

  async function get_groups() {
    let groups_new = await axios_api.get_groups(cookies.csrftoken)
    console.log('car_list new groups : ', groups_new)
    setGroups(groups_new)
  }

  async function get_geozones() {
    let geozones_new = await axios_api.get_geozones(cookies.csrftoken)
    console.log('car_list geozones_new : ', geozones_new)
    setGeozones(geozones_new)
  }

  async function get_aggregators() {
    let aggregators_new = await axios_api.get_aggregators(cookies.csrftoken)
    console.log('aggregators_new : ', aggregators_new)

    let aggregators_for_select_new = []

    aggregators_for_select_new.push(['Без агрегатора', null])

    for (let aggregator of aggregators_new) {
      aggregators_for_select_new.push([aggregator.name, aggregator.id])
    }

    setAggregators_for_select(aggregators_for_select_new)
  }

  async function get_cars_detailed() {
    let cars = await axios_api.get_cars_detailed(cookies.csrftoken)
    console.log('car_list new cars detailed : ', cars)
    setCars_delailed(cars)
  }

  async function get_cars_brands_and_models() {
    let cars_brands_and_models = await axios_api.get_cars_brands_and_models(cookies.csrftoken)
    console.log('park_settings new cars_brands_and_models : ', cars_brands_and_models)
    if (cars_brands_and_models) {
      setCars_models(cars_brands_and_models.models)
      setCars_brands(cars_brands_and_models.brands)
    }
  }
}
