import React, { useEffect, useState, useContext } from 'react'

import '../../styles/car_list/car_list_main.css'

import { useCookies } from 'react-cookie'
import axios_api from '../../API/axios_api'
import map_api from '../../API/map_api'
import { CarListContext } from '../../context/index'
import { MapContext } from '../../context/index'
import ReactLoading from 'react-loading'
import Modal_with_accept from '../UI/modal_with_accept'
import Btn_defolt from '../UI/btn_defolt'

import Switch from 'react-switch'
import Tippy from '@tippyjs/react/headless'

import { useSelector } from 'react-redux'

export default function Car_commands(props) {
  const user_current = useSelector((state) => state.user_current)
  let { car_list_object } = useContext(CarListContext)
  const [cookies] = useCookies(['csrftoken'])

  const [modal_active, setActive_modal] = useState(false)
  const [modal_text, setModal_text] = useState('')
  const [modal_text_error, setModal_text_error] = useState('')
  const [status_modal, setStatus_modal] = useState('')

  const [modal_microphone_active, setActive_microphone_modal] = useState(false)
  const [modal_microphone_text, setModal_microphone_text] = useState('')
  const [status_microphone_modal, setStatus_microphone_modal] = useState('')

  const [comand_accept, setComand_accept] = useState(null)
  const [is_comand_cancel, setIs_comand_cancel] = useState(false)

  function command_click(command) {
    console.log('command_id : ', command.id)
    console.log('command_name : ', command.verbose_name)
    setComand_accept(command)
    if (command.pending) {
      setIs_comand_cancel(true)
      setModal_text(
        'Вы уверены, что хотите отменить выполнение команды "' + command.verbose_name + '" у',
      )
    } else {
      setIs_comand_cancel(false)
      setModal_text(
        `Вы уверены, что хотите ${command.state ? 'отменить' : 'применить'} команду "${
          command.verbose_name
        }" у`,
      )
    }

    if (command.type == 'MICROPHONE') {
      setActive_microphone_modal(true)
    } else {
      setActive_modal(true)
    }
  }

  async function accept_command(option) {
    console.log('accept_command : ', comand_accept)

    if (!user_current.permissions.includes('ENGINE_BLOCK') && comand_accept.type === 'ENGINE') {
      setModal_text_error('У Вас нет прав доступа')
      setStatus_modal('error')
      setActive_modal(true)
      setTimeout(function () {
        setActive_modal(false)
        setStatus_modal('')
        setModal_text_error('')
      }, 2000)
      props.get_cars_detailed()
      return
    }

    let response

    if (is_comand_cancel) {
      response = await axios_api.cancel_command_to_car(
        comand_accept.id,
        props.car.id,
        cookies.csrftoken,
      )
    } else {
      response = await axios_api.send_command_to_car(
        comand_accept.id,
        props.car.id,
        cookies.csrftoken,
      )
    }

    if (comand_accept.type == 'MICROPHONE') {
      if (response.status == '200') {
        setStatus_microphone_modal('success')
      } else {
        setStatus_microphone_modal('error')
      }

      setTimeout(function () {
        if (option == 'turn_off_mic') {
          setActive_microphone_modal(false)
        }
        setStatus_microphone_modal('')
      }, 2000)
    } else {
      if (response.status == '200') {
        if (is_comand_cancel && response.data.message === 'Nothing to cancel') {
          setModal_text_error('Ошибка')
          setStatus_modal('error')
        } else {
          setStatus_modal('success')
        }
      } else {
        setModal_text_error('Ошибка')
        setStatus_modal('error')
      }

      setTimeout(function () {
        setActive_modal(false)
        setStatus_modal('')
        setModal_text_error('')
      }, 2000)
    }

    props.get_cars_detailed()
  }

  let microphone_command = {
    pending: false,
    state: false,
  }

  for (let command of props.car.commands) {
    if (command.type == 'MICROPHONE') {
      microphone_command = command
    }
  }

  return (
    <div className='car_commands_main'>
      <div className='header_car_commands'>Команды автомобиля</div>
      <div style={{ marginTop: '15px' }}>
        {props.car.commands.length != 0 ? (
          <>
            {props.car.commands.map((command) => (
              <div key={command.id} className='car_command_item'>
                <div className='car_command_name'>{command.verbose_name}</div>
                <div>
                  {command.pending ? (
                    <Tippy
                      render={(attrs) => (
                        <div className='box tippy_content' tabIndex='-1' {...attrs}>
                          Выполнение команды в процессе
                        </div>
                      )}
                    >
                      <div>
                        <Switch
                          onChange={(e) => command_click(command)}
                          checked={command.state}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={36}
                          offColor={'#ffbc00'}
                          onColor={'#ffbc00'}
                          activeBoxShadow={'0 0 2px 3px #ffbc00'}
                        />
                      </div>
                    </Tippy>
                  ) : (
                    <Switch
                      onChange={(e) => command_click(command)}
                      checked={command.state}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={36}
                      offColor={'#E7ECFB'}
                      onColor={'#6486FB'}
                      activeBoxShadow={'0 0 2px 3px #6486FB'}
                    />
                  )}
                </div>
              </div>
            ))}
          </>
        ) : (
          <div style={{ marginTop: '15px' }}>
            <div className='car_info_item'>
              <div className='car_info_name'>Нет команд</div>
            </div>
          </div>
        )}
      </div>
      <Modal_with_accept active={modal_active} setActive={setActive_modal}>
        <div className='text_accept_delete'>
          {modal_text} "{props.car.car_number}"?
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
          <Btn_defolt
            color={'#EFF3FF'}
            text_color={'#6486FB'}
            hover_color={'#dbe4ff'}
            text={'Отмена'}
            height={'40px'}
            width={'150px'}
            onClick={(e) => setActive_modal(false)}
          />
          <Btn_defolt
            color={'#6486FB'}
            text_color={'#FFFFFF'}
            hover_color={'#5179fc'}
            text={'Да'}
            height={'40px'}
            width={'150px'}
            onClick={accept_command}
          />
        </div>
        <div
          className={
            status_modal == 'success' ? 'status_modal_success active' : 'status_modal_success'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>Успешно!</div>
          </div>
        </div>
        <div
          className={status_modal == 'error' ? 'status_modal_error active' : 'status_modal_error'}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>{modal_text_error}!</div>
          </div>
        </div>
      </Modal_with_accept>
      <Modal_with_accept active={modal_microphone_active} setActive={setActive_microphone_modal}>
        {!microphone_command.pending ? (
          <>
            {!microphone_command.state ? (
              <>
                <div className='text_accept_delete'>
                  Вы уверены, что хотите включить запись звука у "{props.car.car_number}"?
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}
                >
                  <Btn_defolt
                    color={'#EFF3FF'}
                    text_color={'#6486FB'}
                    hover_color={'#dbe4ff'}
                    text={'Закрыть'}
                    height={'40px'}
                    width={'150px'}
                    onClick={(e) => setActive_microphone_modal(false)}
                  />
                  <Btn_defolt
                    color={'#6486FB'}
                    text_color={'#FFFFFF'}
                    hover_color={'#5179fc'}
                    text={'Включить'}
                    height={'40px'}
                    width={'150px'}
                    onClick={accept_command}
                  />
                </div>
              </>
            ) : (
              <>
                <div className='text_accept_delete'>
                  Вы можете прослушать запись звука, позвонив по номеру:
                </div>
                <div
                  className='text_accept_delete'
                  style={{ textAlign: 'center', marginTop: '20px' }}
                >
                  {props.car.phone_number}
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}
                >
                  <Btn_defolt
                    color={'#EFF3FF'}
                    text_color={'#6486FB'}
                    hover_color={'#dbe4ff'}
                    text={'Закрыть'}
                    height={'40px'}
                    width={'150px'}
                    onClick={(e) => setActive_microphone_modal(false)}
                  />
                  <Btn_defolt
                    color={'#6486FB'}
                    text_color={'#FFFFFF'}
                    hover_color={'#5179fc'}
                    text={'Выключить'}
                    height={'40px'}
                    width={'150px'}
                    onClick={() => accept_command('turn_off_mic')}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className='text_accept_delete'>Выполнение команды в процессе</div>
            <div style={{ height: '100px' }}>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ReactLoading type={'spin'} color='#6486FB' />
              </div>
            </div>
            {!microphone_command.state ? (
              <>
                <div className='text_accept_delete'>
                  После выполнения команды Вы cможете прослушать запись звука, позвонив по номеру:
                </div>
                <div
                  className='text_accept_delete'
                  style={{ textAlign: 'center', marginTop: '20px' }}
                >
                  {props.car.phone_number}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                  <Btn_defolt
                    color={'#EFF3FF'}
                    text_color={'#6486FB'}
                    hover_color={'#dbe4ff'}
                    text={'Закрыть'}
                    height={'40px'}
                    width={'390px'}
                    onClick={(e) => setActive_microphone_modal(false)}
                  />
                  {/* <Btn_defolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Выключить'} height={'40px'} width={'150px'} onClick={accept_command}/> */}
                </div>
              </>
            ) : (
              <>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0px' }}>
                  <Btn_defolt
                    color={'#EFF3FF'}
                    text_color={'#6486FB'}
                    hover_color={'#dbe4ff'}
                    text={'Закрыть'}
                    height={'40px'}
                    width={'390px'}
                    onClick={(e) => setActive_microphone_modal(false)}
                  />
                  {/* <Btn_defolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Выключить'} height={'40px'} width={'150px'} onClick={accept_command}/> */}
                </div>
              </>
            )}
          </>
        )}
        <div
          className={
            status_microphone_modal == 'success'
              ? 'status_modal_success active'
              : 'status_modal_success'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>Успешно!</div>
          </div>
        </div>
        <div
          className={
            status_microphone_modal == 'error' ? 'status_modal_error active' : 'status_modal_error'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>Ошибка!</div>
          </div>
        </div>
      </Modal_with_accept>
    </div>
  )
}
