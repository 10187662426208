import React from 'react'

import '../../../styles/park_settings/main_park_settings.css'

const Modal_add_license = ({ active, setActive, children }) => {
  return (
    <div
      className={active ? 'modal_add_license active' : 'modal_add_license'}
      onClick={() => setActive()}
    >
      <div className='modal_add_license_content' onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  )
}

export default Modal_add_license
