import React, { useContext, useState, useEffect, useRef } from 'react'
import { AuthContext } from '../context'
import { useCookies } from 'react-cookie'
import logo from '../styles/icons/logo_login.svg'
import axios_api from '../API/axios_api'
import { Link } from 'react-router-dom'
import Btn_defolt from '../components/UI/btn_defolt'

const Login = () => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const demo = queryParams.get('demo')
    if (demo == 'demo') {
      console.log(demo)
      go_demo()
    }
    document.title = 'Вход | ControlGPS'
    window.history.replaceState(null, 'New Page Title', '/auth')
  }, [])

  const { Auth } = useContext(AuthContext)

  const [cookies, setCookie] = useCookies(['csrftoken'])

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [status_login, setStatus_login] = useState('')

  const inputEmail = useRef(null)
  const inputPassword = useRef(null)

  async function go_demo() {
    console.log('go_demo')
    let login_response = await axios_api.login('demo@controlgps.org', 'demo')
    console.log('login_response : ', login_response)
    if (login_response != 'error') {
      setCookie('csrftoken', login_response, { path: '/' })
      window.history.replaceState(null, 'New Page Title', '/map')
      Auth.isAuth = true
      window.location.reload()
      console.log('cookie : ', document.cookie)
    } else {
      setStatus_login('error')
    }
  }

  function go_license_agreement() {
    axios_api.license_agreement()
  }

  function submit_login(e) {
    if (e.charCode === 13) {
      if (email != '' && password != '') {
        login()
      } else {
        if (email != '') {
          inputPassword.current.focus()
        }
      }
    }
  }

  function submit_password(e) {
    if (e.charCode === 13) {
      if (email != '' && password != '') {
        login()
      } else {
        if (password != '') {
          inputEmail.current.focus()
        }
      }
    }
  }

  function go_landing() {
    document.location.href = 'http://controlgps.org/'
  }

  async function login() {
    if (email != '' && password != '') {
      let login_response = await axios_api.login(email, password)
      console.log('login_response : ', login_response)
      if (login_response != 'error') {
        setCookie('csrftoken', login_response, { path: '/' })
        window.history.replaceState(null, 'New Page Title', '/map')
        Auth.isAuth = true
        window.location.reload()
        console.log('cookie : ', document.cookie)
      } else {
        setStatus_login('error')
      }
    } else {
      setStatus_login('error')
    }

    setTimeout(function () {
      setStatus_login('')
    }, 3000)
  }

  return (
    <>
      <div className='login_div_big'>
        <div className='login_div_medium'>
          <div className='div_logo_login'>
            <img src={logo} className='logo_login' />
          </div>
          <div className='login_div_small'>
            <div>
              <div className={status_login == 'error' ? 'text_inputs error' : 'text_inputs'}>
                Логин
              </div>
              <input
                ref={inputEmail}
                className='login_inputs'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                // onChange={e => setEmail(e.target.value)}
                onKeyPress={submit_login}
                type='text'
              />
            </div>

            <div>
              <div className={status_login == 'error' ? 'text_inputs error' : 'text_inputs'}>
                Пароль
              </div>
              <input
                ref={inputPassword}
                className='login_inputs'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={submit_password}
                type='password'
              />
            </div>

            <div style={{ height: '25px', marginTop: '5px' }}>
              <div
                className={status_login == 'error' ? 'text_error_login active' : 'text_error_login'}
              >
                Неверно введен логин и/или пароль
              </div>
            </div>

            <div className='div_btn'>
              <Btn_defolt
                color={'#6486FB'}
                text_color={'#FFFFFF'}
                hover_color={'#5179fc'}
                text={'Вход'}
                height={'40px'}
                width={'150px'}
                onClick={login}
              />
            </div>
            <div className='border_login'></div>
            <div className='forgot_password'>Забыли пароль?</div>
          </div>
        </div>
      </div>
      <div className='footer_login'>
        <div className='tag_login' onClick={go_landing}>
          © Control GPS
        </div>
        <div className='politika_login'>
          <Link to={'/privacy_policy'} style={{ textDecoration: 'none', color: '#5F7F9D' }}>
            Политика конфиденциальности
          </Link>
        </div>
        <div className='politika_login' onClick={go_license_agreement}>
          Лицензионное соглашение
        </div>
      </div>
    </>
  )
}

export default Login
