import React, { useEffect, useState, useContext } from 'react';
import Chart from "react-apexcharts";
import '../../styles/dashboard/charts.css'
import Basic_select from '../UI/basic_select.jsx'
export default function Chart_fuel(props) {

    const time_range_filter = [
        ['За день', 'DAY'],
        ['За неделю', 'WEEK'],
        ['За месяц', 'MONTH'],
    ]

    const [time_range_filter_value, setTime_range_filter_value] = useState('WEEK')

    return (
        <div className=''>
            <div>
                <Basic_select current_value={time_range_filter_value} all_values={time_range_filter} setValue={setTime_range_filter_value} width={'180px'}/>
            </div>
        </div>
    )
}