/* eslint-disable react/jsx-pascal-case */
import { useState, useEffect } from 'react'
import Btn_defolt from '../../UI/btn_defolt.jsx'
import Search_input from '../../UI/search_input.jsx'
import Modal_with_accept from '../../UI/modal_with_accept.jsx'
import axios_api from '../../../API/axios_api'
import ReactLoading from 'react-loading'
import SimpleBar from 'simplebar-react'
import { useCookies } from 'react-cookie'
import ModalChangeParam from './modalChangeParam'
import TableItemParamModal from './tableItemParamModal'
import TableItemParam from './tableItemParam'

export default function CarInspections(props) {
  const [cookies] = useCookies(['csrftoken'])
  const [params, setParams] = useState([])
  const [inspections, setInspections] = useState([])

  const [loading, setLoading] = useState(false)

  const [search, setSearch] = useState('')
  const [modalActive, setModalActive] = useState(false)
  const [modalAcceptActive, setModalAcceptActive] = useState(false)
  const [saveStatus, setSaveStatus] = useState(null)
  const [acceptStatus, setAcceptStatus] = useState(null)

  const [choosenInspection, setChoosenInspection] = useState(null)
  const [choosenParam, setChoosenParam] = useState()
  const [choosenParamName, setChoosenParamName] = useState()

  const row_click = (id) => {
    setChoosenInspection(id)
  }

  const row_param_click = (id, name) => {
    setChoosenParam(id)
    setChoosenParamName(name)
    setModalAcceptActive(true)
  }

  const btnClick = () => {
    if (props.car) {
      setChoosenInspection(props.car.inspection_group)
    }
    setModalActive(true)
  }

  const btnAcceptClick = async () => {
    let responseInspectionParam = await axios_api.saveCarInspection(
      choosenInspection,
      props.car.id,
      cookies.csrftoken
    )
    if (responseInspectionParam.status === 200) {
      setSaveStatus('success')
    } else {
      setSaveStatus('error')
    }
    reloadParams()
    reloadInspections()
    setTimeout(() => {
      setSaveStatus(null)
      setModalActive(false)
    }, 2000)
  }

  const reloadParams = async () => {
    setLoading(true)

    let paramsNew = await axios_api.get_car_params(
      props.car.id,
      cookies.csrftoken
    )
    console.log('car_list paramsNew : ', paramsNew)

    paramsNew.sort((a, b) => makeTOPriority(b.state) - makeTOPriority(a.state))

    setParams(paramsNew)
    setLoading(false)
  }

  const reloadInspections = async () => {
    let inspections_groups = await axios_api.get_inspections_groups(
      cookies.csrftoken
    )
    console.log('car_list new inspections_groups : ', inspections_groups)
    setInspections(inspections_groups)
  }

  const makeTOPriority = (inspections_state) => {
    switch (inspections_state) {
      case 'NOT_CONFIGURED':
        return 0
      case 'PASSED':
        return 1
      case 'NEED':
        return 4
      case 'SKIPPED':
        return 5
      case 'FUTURE':
        return 3
      case 'NO_NEED':
        return 2
      default:
        return 0
    }
  }

  useEffect(() => {
    reloadParams()
    reloadInspections()
  }, [props.car.id])

  let inspectionsSearched = inspections

  if (search !== '') {
    inspectionsSearched = []

    for (let item of inspections) {
      if (
        item.brand.toUpperCase().includes(search.toUpperCase()) ||
        item.model.toUpperCase().includes(search.toUpperCase()) ||
        item.verbose_name.toUpperCase().includes(search.toUpperCase())
      ) {
        inspectionsSearched.push(item)
      }
    }
  }

  const acceptParamAccept = async () => {
    let responseParamAccept = await axios_api.carParamAccept(
      choosenParam,
      cookies.csrftoken
    )
    if (responseParamAccept.status === 200) {
      setAcceptStatus('success')
    } else {
      setAcceptStatus('error')
    }
    props.get_cars_detailed()
    reloadParams()
    setTimeout(() => {
      setAcceptStatus(null)
      setModalAcceptActive(false)
    }, 2000)
  }

  return (
    <div className="carInspection">
      <div className="carInspectionHead">
        <div className="carInspectionTitle">ТО</div>
        <div className="carInspectionChangeBtn">
          <Btn_defolt
            color={'#e7ecfb'}
            text_color={'#6486FB'}
            hover_color={'#dbe4ff'}
            text={'Выбрать план ТО'}
            height={'36px'}
            width={'190px'}
            onClick={btnClick}
          />
        </div>
      </div>
      <div className="carInspectionBody">
        {!loading ? (
          <>
            {params.length ? (
              <div className="carInspectionSimpleBar">
                <SimpleBar style={{ maxHeight: 232 }}>
                  {params.map((param) => (
                    <TableItemParam
                      key={param.id}
                      param={param}
                      row_click={row_param_click}
                    />
                  ))}
                </SimpleBar>
              </div>
            ) : (
              <div
                className="title_car_inspections_modal"
                style={{
                  textAlign: 'start',
                  width: '100%',
                  marginLeft: '16px',
                  marginTop: '16px',
                  marginBottom: '16px',
                  fontSize: '16px',
                }}
              >
                Виды работ отсутсвуют
              </div>
            )}
          </>
        ) : (
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ReactLoading type={'spin'} color="#6486FB" />
          </div>
        )}
      </div>
      <ModalChangeParam active={modalActive} setActive={setModalActive}>
        <div className="title_car_inspections_modal">Выбор плана ТО</div>
        <div style={{ marginTop: '32px' }}>
          <Search_input
            placeholder={
              'Введите название плана ТО, марку или модель автомобиля'
            }
            change={setSearch}
          />
        </div>
        <div style={{ height: '400px' }}>
          {inspections.length ? (
            <SimpleBar style={{ maxHeight: 400 }}>
              {inspectionsSearched.map((inspection) => (
                <TableItemParamModal
                  key={inspection.id}
                  inspection={inspection}
                  choosenInspection={choosenInspection}
                  row_click={row_click}
                  cars_delailed={props.cars}
                />
              ))}
            </SimpleBar>
          ) : (
            <div
              className="title_car_inspections_modal"
              style={{ fontSize: '16px', marginTop: '16px' }}
            >
              Планы ТО отсутвуют, Вы можете добавить их в настройках парка
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '32px',
          }}
        >
          <Btn_defolt
            color={'#EFF3FF'}
            text_color={'#6486FB'}
            hover_color={'#dbe4ff'}
            text={'Отмена'}
            height={'40px'}
            width={'200px'}
            onClick={(e) => setModalActive(false)}
          />
          <Btn_defolt
            color={'#6486FB'}
            text_color={'#FFFFFF'}
            hover_color={'#5179fc'}
            text={'Сохранить'}
            height={'40px'}
            width={'200px'}
            onClick={btnAcceptClick}
          />
        </div>
        <div
          className={
            saveStatus === 'success'
              ? 'status_modal_success active'
              : 'status_modal_success'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="text_status_main">Успешно!</div>
            <div className="text_status_info">План ТО выбран!</div>
          </div>
        </div>
        <div
          className={
            saveStatus === 'error'
              ? 'status_modal_error active'
              : 'status_modal_error'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="text_status_main">Ошибка!</div>
            <div className="text_status_info">Не удалось выбрать план ТО!</div>
          </div>
        </div>
      </ModalChangeParam>
      <Modal_with_accept
        active={modalAcceptActive}
        setActive={setModalAcceptActive}
      >
        <div className="text_accept_delete">
          Вы уверены, что хотите пройти вид работы "{choosenParamName}"?
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '30px',
          }}
        >
          <Btn_defolt
            color={'#EFF3FF'}
            text_color={'#6486FB'}
            hover_color={'#dbe4ff'}
            text={'Отмена'}
            height={'40px'}
            width={'150px'}
            onClick={(e) => setModalAcceptActive(false)}
          />
          <Btn_defolt
            color={'#6486FB'}
            text_color={'#FFFFFF'}
            hover_color={'#5179fc'}
            text={'Подтвердить'}
            height={'40px'}
            width={'150px'}
            onClick={acceptParamAccept}
          />
        </div>
        <div
          className={
            acceptStatus === 'success'
              ? 'status_modal_success active'
              : 'status_modal_success'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="text_status_main">Успешно!</div>
            <div className="text_status_info">ТО пройдено!</div>
          </div>
        </div>
        <div
          className={
            acceptStatus === 'error'
              ? 'status_modal_error active'
              : 'status_modal_error'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="text_status_main">Ошибка!</div>
            <div className="text_status_info">Не удалось пройти ТО!</div>
          </div>
        </div>
      </Modal_with_accept>
    </div>
  )
}
