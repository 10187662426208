/* eslint-disable react/jsx-pascal-case */
import React from 'react'

import '../../styles/report/charts.css'

import Chart_item from './chart_item.jsx'
import Working_chart from './working_chart.jsx'
import Info_chart from './info_chart.jsx'

export default function Charts(props) {
  let splited_cars_by_metrics = split_cars_by_metrics(props.cars)

  return (
    <div className="div_all_charts">
      <Info_chart cars={props.cars} cars_for_report={props.cars_for_report} />
      {props.checked_metrics.includes('working') ? (
        <Working_chart
          cars={props.cars}
          cars_for_report={props.cars_for_report}
        />
      ) : (
        <></>
      )}
      {splited_cars_by_metrics.map((metric_object) => (
        <Chart_item
          metric_object={metric_object}
          cars_for_report={props.cars_for_report}
          key={metric_object.type}
        />
      ))}
    </div>
  )

  function split_cars_by_metrics(cars) {
    let return_array = []

    if (cars == null) {
      return []
    }

    for (let metric of props.all_metrics) {
      if (metric.type === 'working') {
        continue
      }

      let obj = {}
      obj.type = metric.type
      obj.name = metric.name
      obj.units = metric.units
      obj.cars = []
      obj.cars_id = metric.cars_id

      for (let car of cars) {
        if (
          props.cars_for_report.includes(car.id) &&
          metric.cars_id.includes(car.id.toString()) &&
          props.checked_metrics.includes(metric.type)
        ) {
          obj.cars.push(car)
        }
      }

      if (obj.cars.length) {
        return_array.push(obj)
      }
    }

    console.log('return_array : ', return_array)

    return return_array
  }
}
