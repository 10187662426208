import '../../../styles/park_settings/main_park_settings.css'
import car_icon from '../../../styles/icons/car_icon_table_routes.svg';
import React, {useEffect, useState, useContext, useRef} from 'react';
import { MapContext } from '../../../context/index';
import { render } from 'react-dom';

export default function Item_car_table_edit_cars_group(props) {
    let car = props.car

    if (car.car_number == null){
        car.car_number = '-'
    }
    if (car.brand == null){
        car.brand = '-'
    }
    if (car.model == null){
        car.model = '-'
    }

    if (car.phone_number == null){
        car.phone_number = '-'
    }

    function check_box_click(e){
        let id = e.currentTarget.getAttribute('data_id')
        let checked = e.currentTarget.checked

        let count = 0
        let find = false

        for (let car_id_in_array of props.add_car_to_group){
            if (car_id_in_array == id){
                find = true
                break;
            }
            count = count + 1
        }

        if (checked){
            if (!find){
                props.add_car_to_group.push(parseFloat(id))
            }
        } else {
            if (find){
                props.add_car_to_group.splice(count, 1)
            }
        }
        props.setAdd_car_to_group(props.add_car_to_group)
        props.setRender_table(new Date)
    }

    let checkbox

    if (props.checked == 'checked'){
        checkbox = <input className='form-check-input' type='checkbox' checked={true} data_id={car.id} onChange={(e) => check_box_click(e)}/>
    }
    if (props.checked == 'unchecked'){
        checkbox = <input className='form-check-input' type='checkbox' checked={false} data_id={car.id} onChange={(e) => check_box_click(e)}/>
    }

    return (    
        <div className='row_table_edit_cars_group'>
            <div className='div_table_edit_cars_group'>
                <div className='table_edit_cars_group_td_checkbox'>
                    {checkbox}
                    <img src={props.car_img} style={{'marginLeft': '20px', 'height': '25px'}}/>
                    <div className='table_edit_cars_group_td_number'>
                        <div className='table_edit_cars_group_number'>
                            {car.car_number}
                        </div>
                        <div className='table_edit_cars_group_brand'>
                            {car.brand} {car.model}
                        </div>
                    </div>
                </div>
                <div className='table_edit_cars_group_td_phone_number'>
                    {car.phone_number}
                </div>
                <div className='table_edit_cars_group_td_mileage'>
                    {Math.round(parseFloat(car.mileage))} км
                </div>
            </div>
            <div className='div_border_bottom_table_edit_cars_group_row'>
                <div className='border_bottom_table_edit_cars_group_row'></div>
            </div>
        </div>
    )
}