/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react'
import '../../../styles/routes_map/routes.css'
import Btn_defolt from '../../UI/btn_defolt'
import axios_api from '../../../API/axios_api'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { useCookies } from 'react-cookie'

const Modal_bills = () => {
  const [cookies] = useCookies(['csrftoken'])
  const user_current = useSelector((state) => state.user_current)

  const [active, setActive] = useState(false)
  const [count, setCount] = useState(0)
  const [blockMap, setBlockMap] = useState(false)

  let text = ''

  if (count == 1) {
    text = 'неоплаченный счет'
  }

  if (count == 2 || count == 3 || count == 4) {
    text = 'неоплаченных счета'
  }

  if (count > 4) {
    text = 'неоплаченных счетов'
  }

  useEffect(() => {
    if (user_current) check_bills()
  }, [])

  async function check_bills() {
    let payments = await axios_api.get_payments(cookies.csrftoken)
    let invoices = await axios_api.get_invoices(cookies.csrftoken)

    let count_not_paid = 0
    let maxDiff = 0
    let dateNow = new Date()

    for (let payment of payments) {
      if (!payment.is_paid) {
        count_not_paid = count_not_paid + 1
        let dateBill = new Date(payment.date)
        let timeDiff = Math.abs(dateNow.getTime() - dateBill.getTime())
        let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
        if (diffDays > maxDiff) {
          maxDiff = diffDays
        }
      }
    }

    for (let invoice of invoices) {
      if (!invoice.is_paid) {
        count_not_paid = count_not_paid + 1
        let dateBill = new Date(invoice.date)
        let timeDiff = Math.abs(dateNow.getTime() - dateBill.getTime())
        let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
        if (diffDays > maxDiff) {
          maxDiff = diffDays
        }
      }
    }

    if (count_not_paid != 0) {
      if (maxDiff >= 7) {
        if (!user_current.is_admin) {
          setBlockMap(true)
        } else {
          setBlockMap(false)
        }
      }
      setCount(count_not_paid)
      setActive(true)
    }
  }

  return (
    <>
      {blockMap ? (
        <div className={active ? 'modal_bills_blocked active' : 'modal_bills_blocked'}>
          <div className='modal_bills_content' onClick={(e) => e.stopPropagation()}>
            <div>
              У Вас <span style={{ color: '#c84a63' }}>{count}</span> {text}.
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '375px',
                marginTop: '35px',
              }}
            >
              <Link to={'/park_settings?bills=true'} style={{ textDecoration: 'none' }}>
                <Btn_defolt
                  color={'#6486FB'}
                  text_color={'#FFFFFF'}
                  hover_color={'#5179fc'}
                  text={'Перейти к оплате'}
                  height={'40px'}
                  width={'200px'}
                />
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={active ? 'modal_bills active' : 'modal_bills'}
          onClick={() => setActive(false)}
        >
          <div className='modal_bills_content' onClick={(e) => e.stopPropagation()}>
            <div>
              У Вас <span style={{ color: '#c84a63' }}>{count}</span> {text}.
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '375px',
                marginTop: '35px',
              }}
            >
              <div>
                <Btn_defolt
                  color={'#EFF3FF'}
                  text_color={'#6486FB'}
                  hover_color={'#dbe4ff'}
                  text={'Закрыть'}
                  height={'40px'}
                  width={'150px'}
                  onClick={() => setActive(false)}
                />
              </div>
              <div>
                <Link to={'/park_settings?bills=true'} style={{ textDecoration: 'none' }}>
                  <Btn_defolt
                    color={'#6486FB'}
                    text_color={'#FFFFFF'}
                    hover_color={'#5179fc'}
                    text={'Перейти к оплате'}
                    height={'40px'}
                    width={'200px'}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Modal_bills
