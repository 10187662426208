import React, { useState } from 'react'
import '../../../styles/drivers/drivers_main.css'
import ReactLoading from 'react-loading'
import Search_input from '../../UI/search_input.jsx'
import Groups_cars_filter from '../../UI/groups_cars_filter.jsx'
import SimpleBar from 'simplebar-react'
import { useSelector } from 'react-redux'
import user_settings_icon from '../../../styles/icons/user_settings_icon.svg'
import { Link } from 'react-router-dom'

export default function Main_drivers_list(props) {
  const window_height = useSelector((state) => state.window_height)
  const simplebar_height = window_height - 70 - 24 - 24 - 24 - 24 - 21 - 60 - 55 - 28

  let searched_array = []
  //groups_filter
  const [group_sort_id, setGroup_sort_id] = useState('ALL')
  //end groups_filter

  //
  const [search, setSearch] = useState(null)
  //

  let drivers = []
  if (props.drivers) {
    drivers = props.drivers
  }

  let cars = []
  if (props.cars) {
    cars = props.cars
  }

  drivers.map((driver) => {
    if (driver.license_expire_date == null) {
      driver.license_expire_date = '-'
    }
    if (driver.license_issue_date == null) {
      driver.license_issue_date = '-'
    }
    if (driver.license_number == null) {
      driver.license_number = '-'
    }
    if (!driver.first_name) {
      driver.first_name = '-'
    }
    if (!driver.last_name) {
      driver.last_name = '-'
    }
    if (!driver.middle_name) {
      driver.middle_name = '-'
    }
    if (driver.phone_numbers == null) {
      driver.phone_numbers = ['Номер отсутсвует']
    } else {
      if (driver.phone_numbers[0]) {
        driver.phone_numbers = [driver.phone_numbers[0]]
      }
      for (let i = 0; i < driver.phone_numbers.length; i += 1) {
        driver.phone_numbers[i] = driver.phone_numbers[i].toString()
      }
    }
    if (driver.car_api_id == null) {
      driver.car_api_id = '-'
    }
    if (driver.engine_block_balance_threshold == null) {
      driver.engine_block_balance_threshold = '-'
    }
    if (driver.car == null) {
      driver.car = '-'
      driver.car_info = null
    } else {
      for (let car of cars) {
        if (car.id == driver.car) {
          driver.car_info = car
        }
      }
    }

    driver.fio =
      driver.last_name + ' ' + driver.first_name[0] + '.' + ' ' + driver.middle_name[0] + '.'
    if (!search) {
      if (group_sort_id == 'ALL' || group_sort_id == driver.aggregator) {
        searched_array.push(driver)
      }
    } else {
      if (
        driver.last_name.toUpperCase().includes(search.toUpperCase()) ||
        driver.license_number.toUpperCase().includes(search.toUpperCase()) ||
        driver.middle_name.toUpperCase().includes(search.toUpperCase()) ||
        driver.first_name.toUpperCase().includes(search.toUpperCase()) ||
        check_phones(driver.phone_numbers, search)
      ) {
        if (group_sort_id == 'ALL' || group_sort_id == driver.aggregator) {
          searched_array.push(driver)
        }
      }
    }
  })

  function check_phones(phones, phone_search) {
    for (let i = 0; i < phones.length; i += 1) {
      if (phones[i].includes(phone_search)) {
        return true
      }
    }
    return false
  }

  function driver_click(e) {
    let id = e.currentTarget.getAttribute('data_id')
    props.click_driver(id)
  }

  function make_row_class(driver) {
    let row_class = 'row_table_drivers_list'

    if (driver.engine_status == 'red') {
      row_class = row_class + ' red'
    }

    if (driver.engine_status == 'yellow') {
      row_class = row_class + ' yellow'
    }

    if (props.driver_profile_id == driver.id) {
      row_class = row_class + ' active'
    }
    // console.log(row_class)
    return row_class
  }

  return (
    <div className='' style={{ height: '100%' }}>
      <div className='text_drivers_list'>Список водителей</div>
      {props.drivers && props.aggregators && props.cars ? (
        <>
          <div
            style={{
              marginTop: '24px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '340px' }}>
              <Groups_cars_filter
                groups={props.aggregators}
                cars={props.drivers}
                group_sort_id={group_sort_id}
                setGroup_sort_id={setGroup_sort_id}
                type={'aggregators'}
                text_all={'Выбрать агрегатор'}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                className='form-check-input'
                type='checkbox'
                checked={props.show_all_drivers}
                onChange={(e) => props.setShow_all_drivers(!props.show_all_drivers)}
              />
              {/* <Switch onChange={(e) => props.setShow_all_drivers(!props.show_all_drivers)} checked={props.show_all_drivers} uncheckedIcon={false} checkedIcon={false} height={20} width={36} offColor={'#E7ECFB'} onColor={'#6486FB'} activeBoxShadow={'0 0 2px 3px #6486FB'}/> */}
              <div
                className={
                  props.show_all_drivers
                    ? 'text_checkbox_with_text active'
                    : 'text_checkbox_with_text'
                }
                style={{ marginLeft: '10px' }}
              >
                Отображать водителей без авто
              </div>
            </div>
          </div>
          <div style={{ marginTop: '20px' }}>
            <Search_input placeholder={'Введите ФИО или номер телефона'} change={setSearch} />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
              paddingLeft: '16px',
              paddingRight: '16px',
            }}
          >
            <div className='thead_table_drivers_list' style={{ width: '22px' }}></div>
            <div className='thead_table_drivers_list' style={{ width: '180px' }}>
              ФИО
            </div>
            <div className='thead_table_drivers_list' style={{ width: '100px' }}>
              Моб. телефон
            </div>
            <div className='thead_table_drivers_list' style={{ width: '110px' }}>
              Агрегатор
            </div>
            <div className='thead_table_drivers_list' style={{ width: '90px' }}>
              Авто
            </div>
            <div className='thead_table_drivers_list' style={{ width: '90px' }}>
              Баланс
            </div>
            <div className='thead_table_drivers_list' style={{ width: '90px' }}>
              Мин. баланс
            </div>
          </div>
          <SimpleBar style={{ maxHeight: simplebar_height }}>
            {searched_array.map((driver) => (
              <div key={driver.id}>
                <div
                  className={make_row_class(driver)}
                  key={driver.id}
                  data_id={driver.id}
                  onClick={(e) => driver_click(e)}
                >
                  <div className='td_driver_icon_table_car_list'>
                    <img src={user_settings_icon} style={{ height: '25px' }} />
                  </div>
                  <div className='td_fio_table__drivers_list'>
                    {driver.last_name + ' ' + driver.first_name + ' ' + driver.middle_name}
                  </div>
                  <div className='td_phone_numbers_table_drivers_list'>
                    {driver.phone_numbers.map((phone) => (
                      <div key={phone} className='phone_number_table_drivers_list'>
                        {phone}
                      </div>
                    ))}
                  </div>
                  <div className='td_aggregator_table_drivers_list'>
                    {driver.aggregator_name ? <>{driver.aggregator_name}</> : <>-</>}
                  </div>
                  <div className='td_car_table_drivers_list'>
                    {driver.car_info ? (
                      <Link
                        to={'/car_list?car=' + driver.car_info.id}
                        style={{ textDecoration: 'none' }}
                      >
                        <span>{driver.car_info.car_number}</span>
                      </Link>
                    ) : (
                      <>-</>
                    )}
                  </div>
                  {driver.engine_block_active ? (
                    <div
                      className={`td_balance_table_drivers_list ${
                        driver.balance < driver.engine_block_balance_threshold ? 'bad' : ''
                      }`}
                    >
                      {Math.round(parseFloat(driver.balance))}₽
                    </div>
                  ) : (
                    <div className='td_balance_table_drivers_list'>
                      {Math.round(parseFloat(driver.balance))}₽
                    </div>
                  )}
                  {driver.engine_block_active ? (
                    <div className='td_block_table_drivers_list yes'>
                      {driver.engine_block_balance_threshold}₽
                    </div>
                  ) : (
                    <div className='td_block_table_drivers_list no'>Неактивно</div>
                  )}
                </div>
                <div className='div_border_bottom_table_driver_list'>
                  <div className='border_bottom_table_driver_list'></div>
                </div>
              </div>
            ))}
          </SimpleBar>
        </>
      ) : (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ReactLoading type={'spin'} color='#6486FB' />
        </div>
      )}
    </div>
  )
}
