import React, { useEffect, useState, useContext } from 'react'
import Chart from 'react-apexcharts'
import '../../styles/report/charts.css'

import { useCookies } from 'react-cookie'
import axios_api from '../../API/axios_api'
import map_api from '../../API/map_api'
import { ReportContext } from '../../context/index'

import SimpleBar from 'simplebar-react'
import Basic_select from '../UI/basic_select.jsx'
import ReactLoading from 'react-loading'
import Tippy from '@tippyjs/react/headless'
import $ from 'jquery'

export default function Working_chart(props) {
  let { report_object } = useContext(ReportContext)

  let colors = report_object.colors

  const [cookies] = useCookies(['csrftoken'])

  const time_range_filter = [
    ['За день', 'DAY'],
    ['За неделю', 'WEEK'],
    ['За месяц', 'MONTH'],
  ]

  const [chart_data, setChart_data] = useState([])
  const [time_range_filter_value, setTime_range_filter_value] = useState('WEEK')
  const [current_time_range_filter_value, setCurrent_time_range_filter_value] =
    useState('WEEK')
  const [render, setRender] = useState(new Date())

  useEffect(() => {
    reload_chart()
  }, [time_range_filter_value])

  // console.log('chart_data : ', chart_data)
  // console.log('time_range_filter_value : ', time_range_filter_value)

  return (
    <div className="" style={{ width: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="title_chart" style={{ marginRight: 'auto' }}>
          Активность
        </div>
        <div
          style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
          className="work_relax_circles"
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="circle_work"></div>
            <div style={{ marginLeft: '8px' }}>Работа</div>
          </div>
          <div
            style={{
              marginLeft: '20px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div className="circle_relax"></div>
            <div style={{ marginLeft: '8px' }}>Отдых</div>
          </div>
        </div>
        <Basic_select
          current_value={time_range_filter_value}
          all_values={time_range_filter}
          setValue={setTime_range_filter_value}
          width={'180px'}
        />
      </div>
      <div style={{ display: 'flex', marginTop: '24px', width: '100%' }}>
        <div style={{ width: '100%' }}>
          <SimpleBar style={{ maxHeight: 200 }}>
            <table style={{ width: '100%' }}>
              <tbody>
                {chart_data.map((item) => (
                  <tr
                    className="tr_cars_chart_activity"
                    key={item.car.id}
                    style={{ backgroundColor: item.car.backgroud_color }}
                  >
                    <td className="car_td_cars_chart_activity">
                      <div
                        className="car_item_charts"
                        style={{ paddingLeft: '0px' }}
                      >
                        <div
                          className="car_number_item_charts"
                          style={{ color: '#3C4A59' }}
                          data_type={'working'}
                          data_id={item.car.id}
                        >
                          {item.car.car_number}
                        </div>
                      </div>
                    </td>
                    {current_time_range_filter_value ==
                    time_range_filter_value ? (
                      <>
                        {current_time_range_filter_value == 'DAY' ? (
                          <>
                            {item.data.map((period) => (
                              <td
                                key={item.car.id + '_' + period.id}
                                className="td_cars_chart_activity"
                              >
                                <div
                                  className={
                                    period.value == true
                                      ? 'period_cars_chart_activity work ' +
                                        period.class
                                      : 'period_cars_chart_activity relax ' +
                                        period.class
                                  }
                                ></div>
                              </td>
                            ))}
                          </>
                        ) : (
                          <>
                            {current_time_range_filter_value == 'MONTH' ? (
                              <>
                                {item.data.map((period) => (
                                  <td
                                    key={item.car.id + '_' + period.id}
                                    className="td_cars_chart_activity"
                                  >
                                    <div
                                      className={
                                        period.value == true
                                          ? 'period_cars_chart_activity work ' +
                                            period.class
                                          : 'period_cars_chart_activity relax ' +
                                            period.class
                                      }
                                    ></div>
                                  </td>
                                ))}
                              </>
                            ) : (
                              <>
                                {item.data.map((period) => (
                                  <td
                                    key={item.car.id + '_' + period.id}
                                    className="td_cars_chart_activity"
                                  >
                                    <div
                                      style={{
                                        width: '100%',
                                        display: 'flex',
                                        height: '100%',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {period.periods.map((piece) => (
                                        <div
                                          className="td_cars_chart_activity_div_week"
                                          key={piece.id}
                                          style={{
                                            width:
                                              'calc(100%/(' +
                                              period.periods.length +
                                              '))',
                                          }}
                                        >
                                          <Tippy
                                            render={(attrs) => (
                                              <div
                                                className="box tippy_content"
                                                tabIndex="-1"
                                                {...attrs}
                                              >
                                                {piece.label}
                                              </div>
                                            )}
                                          >
                                            <div
                                              key={piece.id}
                                              className={
                                                piece.value == true
                                                  ? 'period_cars_chart_activity work ' +
                                                    piece.class
                                                  : 'period_cars_chart_activity relax ' +
                                                    piece.class
                                              }
                                              style={{ width: '100%' }}
                                            ></div>
                                          </Tippy>
                                        </div>
                                      ))}
                                    </div>
                                  </td>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </SimpleBar>
          <div style={{ display: 'flex', height: '50px' }}>
            <div style={{ width: '150px' }}></div>
            <div style={{ width: 'calc(100% - 150px)', display: 'flex' }}>
              {current_time_range_filter_value == time_range_filter_value &&
              chart_data.length != 0 ? (
                <>
                  {current_time_range_filter_value == 'DAY' ? (
                    <>
                      {chart_data[chart_data.length - 1].data.map((period) => (
                        <div
                          key={'TEXT_' + period.id}
                          style={{
                            width:
                              'calc(100%/(' +
                              chart_data[chart_data.length - 1].data.length +
                              '))',
                          }}
                        >
                          <div className="div_label_text day">
                            {period.label}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {current_time_range_filter_value == 'MONTH' ? (
                        <>
                          {chart_data[chart_data.length - 1].data.map(
                            (period) => (
                              <div
                                key={'TEXT_' + period.id}
                                style={{
                                  width:
                                    'calc(100%/(' +
                                    chart_data[chart_data.length - 1].data
                                      .length +
                                    '))',
                                }}
                              >
                                <div className="div_label_text month">
                                  {period.label}
                                </div>
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          {chart_data[chart_data.length - 1].data.map(
                            (period) => (
                              <div
                                key={'TEXT_' + period.id}
                                className="div_label_text week"
                                style={{
                                  width:
                                    'calc((100%/(' +
                                    chart_data[chart_data.length - 1]
                                      .count_of_all +
                                    '/' +
                                    period.periods.length +
                                    ')) + 2px)',
                                }}
                              >
                                <div className="div_label_text week">
                                  {period.day_text}
                                </div>
                              </div>
                            )
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  async function prepare_data(old_data) {
    let days_of_week = [
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
      'Воскресеье',
    ]

    let id_for_all = 0
    let new_data = []
    let current_pos = 0

    for (let key in old_data) {
      let obj = {
        data: old_data[key],
      }

      for (let car of props.cars) {
        if (car.id == key) {
          obj.car = car
        }
      }

      if (current_pos % 2 == 0) {
        obj.car.backgroud_color = '#EFF3FF'
      } else {
        obj.car.backgroud_color = '#FFFFFF'
      }

      obj.is_last = false

      new_data.push(obj)
      current_pos++
    }

    if (new_data.length === 0) {
      return []
    }

    for (let item of new_data) {
      let new_id = 0
      let period_current = 'asd'

      for (let period of item.data) {
        if (period_current != period.value) {
          period_current = period.value
          if (new_id + 1 < item.data.length) {
            if (item.data[new_id + 1].value == period_current) {
              period.class = 'start'
            } else {
              period.class = 'start_end'
            }
          } else {
            period.class = 'start_end'
          }
        } else {
          if (new_id + 1 < item.data.length) {
            if (item.data[new_id + 1].value == period_current) {
              period.class = ''
            } else {
              period.class = 'end'
            }
          } else {
            period.class = 'end'
          }
        }
        period.id = id_for_all
        new_id++
        id_for_all++
      }
    }

    if (time_range_filter_value == 'WEEK') {
      let i = 0
      let current_day_of_week = -1
      let day_of_week_obj = {}
      let first_iter = true

      for (let item of new_data) {
        let new_week_data = []
        for (let period of item.data) {
          if (current_day_of_week != period.day_of_week) {
            if (!first_iter) {
              new_week_data.push(day_of_week_obj)
            }
            day_of_week_obj = {}
            day_of_week_obj.day_text = days_of_week[period.day_of_week]
            day_of_week_obj.id = id_for_all
            day_of_week_obj.periods = []
            day_of_week_obj.day_of_week = period.day_of_week
            day_of_week_obj.periods.push(period)
            current_day_of_week = period.day_of_week
            if (i + 1 == item.data.length) {
              new_week_data.push(day_of_week_obj)
            }
            id_for_all++
          } else {
            day_of_week_obj.day_of_week = period.day_of_week
            day_of_week_obj.periods.push(period)
            if (i + 1 == item.data.length) {
              new_week_data.push(day_of_week_obj)
            }
          }
          i++
          first_iter = false
        }
        i = 0
        item.data = new_week_data
        first_iter = true
        current_day_of_week = -1
      }

      let count_of_all = 0
      for (let day of new_data[new_data.length - 1].data) {
        count_of_all = count_of_all + day.periods.length
      }
      new_data[new_data.length - 1].count_of_all = count_of_all
    }

    new_data[new_data.length - 1].is_last = true

    return new_data
  }

  async function reload_chart() {
    // console.log('time_range_filter_value : ', time_range_filter_value)
    if (props.cars_for_report.length == 0) {
      setChart_data([])
    } else {
      let response = await axios_api.get_stats_chart(
        props.cars_for_report,
        time_range_filter_value,
        'working',
        cookies.csrftoken
      )

      let data = response.data
      let min_size
      let first_iter = true

      for (let key in data) {
        if (data[key] != null) {
          if (first_iter) {
            min_size = data[key].length
          } else {
            if (min_size > data[key].length) {
              min_size = data[key].length
            }
          }
          first_iter = false
        } else {
          delete data[key]
        }
      }

      // console.log('data : ', data)

      for (let key in data) {
        data[key] = data[key].slice(0, min_size)
      }
      // console.log('min_size : ', min_size)

      // console.log('data min_size: ', data)

      let prepared_data = await prepare_data(data)

      // console.log('prepared_data : ', prepared_data)

      setChart_data(prepared_data)
      setCurrent_time_range_filter_value(time_range_filter_value)
    }
  }
}
