import '../../styles/report/main_report.css'
import React, {useEffect, useState, useContext, useRef} from 'react';

export default function Table_item(props) {
    let car = props.car

    if (car.car_number == null){
        car.car_number = '-'
    }
    if (car.brand == null){
        car.brand = '-'
    }

    if (car.phone_number == null){
        car.phone_number = '-'
    }

    function check_box_click(e){
        let id = e.currentTarget.getAttribute('data_id')
        let checked = e.currentTarget.checked

        let count = 0
        let find = false

        for (let car_id_in_array of props.cars_for_report){
            if (car_id_in_array == id){
                find = true
                break;
            }
            count = count + 1
        }

        if (checked){
            if (!find){
                props.cars_for_report.push(parseFloat(id))
            }
        } else {
            if (find){
                props.cars_for_report.splice(count, 1)
            }
        }
        props.setCars_for_report(props.cars_for_report)
        props.setTime(new Date)
        // console.log(props.cars_for_report)
        props.setRender_table(new Date)
    }

    // let checkbox

    // if (props.checked == 'checked'){
    //     checkbox = <input className='form-check-input' type='checkbox' checked={true} data_id={car.id} onChange={(e) => check_box_click(e)}/>
    // }
    // if (props.checked == 'unchecked'){
    //     checkbox = <input className='form-check-input' type='checkbox' checked={false} data_id={car.id} onChange={(e) => check_box_click(e)}/>
    // }

    return (    
        <div>
            <div className={'row_table_car_list_report'} key={car.id} data_id={car.id} checked={props.checked == 'checked' ? false : true} onClick={(e) => check_box_click(e)}>
                <div className='td_car_checkbox_table_car_list_report'>
                    <input className='form-check-input' type='checkbox' checked={props.checked == 'checked' ? true : false} data_id={car.id} onChange={(e) => check_box_click(e)}/>
                </div>
                <div className='td_car_icon_table_car_list_report'>
                    <img src={props.car_img} style={{'height': '25px'}}/>
                </div>
                <div className='td_car_number_table_car_list_report'>
                    <div className='table_car_list_number_report'>
                        {car.car_number}
                    </div>
                    <div className='table_car_list_brand_report'>
                        {car.brand}
                    </div>
                </div>
                <div className='td_car_phone_table_car_list_report'>
                    {car.phone_number}
                </div>
            </div>
            <div className='div_border_bottom_table_car_list_report'>
                <div className='border_bottom_table_car_list_report'></div>
            </div>
        </div>
    )
}