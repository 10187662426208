import React, { useEffect, useState } from 'react';
import axios_api from "../../API/axios_api"
import '../../styles/dashboard/cards_fuel.css'
import Basic_select from '../UI/basic_select.jsx'
import { useCookies } from 'react-cookie';
import SimpleBar from 'simplebar-react';
import ReactLoading from "react-loading";
import icon_route_dashboard from "../../styles/icons/icon_route_dashboard.svg"
import car_data from "../../API/car_data"

export default function Card_top_mil(props) {
    const [cookies] = useCookies(['csrftoken']);
    const up_down_filter = [
        ['По возрастанию', 'UP'],
        ['По убыванию', 'DOWN'],
    ]
    const time_range_filter = [
        ['За день', 'DAY'],
        ['За неделю', 'WEEK'],
        ['За месяц', 'MONTH'],
    ]

    const [cars_top_mil, setCars_top_mil] = useState()
    const [up_down_filter_value, setUp_down_filter_value] = useState('DOWN')
    const [time_range_filter_value, setTime_range_filter_value] = useState('WEEK')

    useEffect(() => {
        get_cars_top_mil()
    }, [time_range_filter_value, props.group_sort_id]);

    let cars_top_mil_filtered = cars_top_mil
    if (cars_top_mil_filtered){
        if (up_down_filter_value == 'UP'){
            cars_top_mil_filtered.sort(function(a, b) {
                return a.mileage - b.mileage;
            });
        } else {
            cars_top_mil_filtered.sort(function(a, b) {
                return b.mileage - a.mileage;
            });
        }
        for (let car of cars_top_mil_filtered){
            if (!car.car_number){
                car.car_number = '-'
            }
            if (!car.brand){
                car.brand = '-'
            }
            if (!car.model){
                car.model = '-'
            }
        }
    }



    return (
        <div className='main_div_card_most_cars_fuel'>
            <div className='main_text_card_most_cars_fuel'>
                Топ авто по пробегу
            </div>
            <div style={{'marginTop': '20px', 'display': 'flex', 'marginBottom': '10px'}}>
                <div>
                    <Basic_select current_value={up_down_filter_value} all_values={up_down_filter} setValue={setUp_down_filter_value} width={'180px'}/>
                </div>
                <div style={{'marginLeft': '16px'}}>
                    <Basic_select current_value={time_range_filter_value} all_values={time_range_filter} setValue={setTime_range_filter_value} width={'180px'}/>
                </div>
            </div>
            {cars_top_mil_filtered
                ?
                    <SimpleBar style={{ maxHeight: '300px' }}>
                        {cars_top_mil_filtered.map((car) =>
                            <div key={car.id}>
                                <div className={'row_table_top_mil'} data_id={car.id}>
                                    <div className='td_car_number_table_top_mil'>
                                        <img src={car_data.get_svg_car(car.color)} style={{'height': '25px', 'marginRight': '15px'}}/>
                                        <div className=''>
                                            <div className='table_top_mil_number'>
                                                {car.car_number}
                                            </div>
                                            <div className='table_top_mil_brand'>
                                                {car.brand} {car.model}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='td_mil_table_top_mil'>
                                        <img src={icon_route_dashboard} style={{'marginRight': '5px'}}/>
                                        {Math.round(car.mileage)} км
                                    </div>
                                </div>
                                <div className='div_border_bottom_table_top_mil'>
                                    
                                    <div className='border_bottom_table_top_mil'></div>
                                </div>
                            </div>
                        )}
                    </SimpleBar>
                :   
                    <div style={{'height': '100%', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center'}}><ReactLoading type={'spin'} color="#6486FB" /></div>
            }
        </div>
    )

    async function get_cars_top_mil(){
        setCars_top_mil(null)
        let cars_top_mil_new = await axios_api.get_cars_top_mil(time_range_filter_value, props.group_sort_id, cookies.csrftoken)
        console.log('dashboard new cars_top_mil : ', cars_top_mil_new)
        //check_filter(cars_top_mil_new)
        setCars_top_mil(cars_top_mil_new)
    }
}