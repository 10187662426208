import React, { useEffect, useState, useContext } from 'react';

import '../../styles/car_list/car_list_main.css'

import { useCookies } from 'react-cookie';
import axios_api from "../../API/axios_api"
import map_api from "../../API/map_api"
import { CarListContext } from '../../context/index';
import { MapContext } from '../../context/index';
import Basic_select from '../UI/basic_select.jsx'
import ReactLoading from "react-loading";

import Tippy from '@tippyjs/react/headless'
import Chart from "react-apexcharts";

export default function Car_chart_activity(props) {
    let {car_list_object} = useContext(CarListContext)
    const [cookies] = useCookies(['csrftoken']);

    const [car_activity_history, setCar_activity_history] = useState(null)
    const [time_range_filter_value, setTime_range_filter_value] = useState('WEEK')

    const [is_loading, setIs_loading] = useState(true)

    const time_range_filter = [
        ['За день', 'DAY'],
        ['За неделю', 'WEEK'],
        ['За месяц', 'MONTH'],
    ]

    useEffect(() => {
        get_car_activity_history()
    }, [time_range_filter_value, props.car.id]);


    let new_id = 0
    let period_current = 'asd'
    let last_time
    let length_period = 0

    let border_week = ''
    let current_day_week = -1

    let array_week = []
    let day_obj = {}
    let i = 0

        
    let days_of_week = [
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
        'Воскресеье',
    ]

    if (car_activity_history != null){
        for (let period of car_activity_history){
            if (period_current != period.value){
                period_current = period.value
                if (new_id + 1 < car_activity_history.length){
                    if (car_activity_history[new_id + 1].value == period_current){
                        period.class = 'start'
                    } else {
                        period.class = 'start_end'
                    }
                } else {
                    period.class = 'start_end'
                }
            } else {
                if (new_id + 1 < car_activity_history.length){
                    if(car_activity_history[new_id + 1].value == period_current){
                        period.class = ''
                    } else {
                        period.class = 'end'
                    }
                } else {
                    period.class = 'end'
                }
            }
            period.id = new_id
            new_id++
        }
        if (time_range_filter_value == 'WEEK'){
            for (let period of car_activity_history){
                if (current_day_week != period.day_of_week){
                    if (i != 0){
                        array_week.push(day_obj)
                    }
                    period.id = i + 1000
                    day_obj = {}
                    day_obj.id = i
                    day_obj.periods = []
                    day_obj.day_of_week = period.day_of_week
                    day_obj.periods.push(period)
                    current_day_week = period.day_of_week
                    if (i + 1 == car_activity_history.length){
                        array_week.push(day_obj)
                    }
                } else {
                    period.id = i + 1000
                    day_obj.day_of_week = period.day_of_week
                    day_obj.periods.push(period)
                    if (i + 1 == car_activity_history.length){
                        array_week.push(day_obj)
                    }
                }
                i++
            }
        }
    }

    // console.log('array_week : ', array_week)

    return (
        <div className='car_chart_activity_main'>
            <div style={{'display': 'flex', 'alignItems': 'center'}}>
                <div className='car_profile_chart_header'>
                    График работы
                </div>
                <div style={{'marginLeft': '20px'}}>
                    <Basic_select current_value={time_range_filter_value} all_values={time_range_filter} setValue={setTime_range_filter_value} width={'180px'}/>
                </div>
                <div style={{'marginLeft': 'auto', 'display': 'flex', 'alignItems': 'center'}} className='work_relax_circles'>
                    <div style={{'display': 'flex', 'alignItems': 'center'}}>
                        <div className='circle_work'>
                            
                        </div>
                        <div  style={{'marginLeft': '8px'}}>
                            Работа
                        </div>
                    </div>
                    <div style={{'marginLeft': '20px', 'display': 'flex', 'alignItems': 'center'}}>
                        <div className='circle_relax'>
                            
                        </div>
                        <div style={{'marginLeft': '8px'}}>
                            Отдых
                        </div>
                    </div>
                </div>
            </div>
            {is_loading == false
                ?
                <>
                    {car_activity_history != null
                        ?
                            <div style={{'width': '100%', 'display': 'flex', 'justifyContent': 'center'}}>
                                <table style={{'width': '95%', 'marginTop': '27px'}}>
                                    <tbody>
                                        <tr className='tr_car_chart_activity'>
                                        {/* {car_activity_history.map((period) => */}
                                            {time_range_filter_value == 'DAY'
                                                ?
                                                <>
                                                    {car_activity_history.map((period) =>
                                                        <td key={period.id} className='td_car_chart_activity'>
                                                            <div className={period.value==true ? 'period_car_chart_activity work ' + period.class : 'period_car_chart_activity relax ' + period.class}>
        
                                                            </div>
                                                            <div className='time_car_chart_activity'>
                                                                {period.label}
                                                            </div>
                                                        </td>
                                                    )}
                                                </>
                                                :
                                                <>
                                                {time_range_filter_value == 'MONTH'
                                                    ?
                                                    <>
                                                    {car_activity_history.map((period) =>
                                                        <td key={period.id} className='td_car_chart_activity'>
                                                            <div className={period.value==true ? 'period_car_chart_activity work ' + period.class : 'period_car_chart_activity relax ' + period.class}>
        
                                                            </div>
                                                            <div className='month_car_chart_activity'>
                                                                {period.label}
                                                            </div>
                                                        </td>
                                                    )}
                                                    </>
                                                    :
                                                    <>
                                                    {array_week.map((day) =>
                                                        <td key={day.id} className={'td_week_car_chart_activity'}>
                                                            <div style={{'display': 'flex', 'width': '100%', 'height': '100%', 'alignItems': 'center'}}>
                                                                {day.periods.map((period) =>
                                                                    <div key={period.id} className='div_big_period_week_car_chart_activity' style={{'width': 'calc(100%/(' + day.periods.length + '))'}}>
                                                                        <Tippy render={attrs => (
                                                                            <div className="box tippy_content" tabIndex="-1" {...attrs}>
                                                                                {period.label}
                                                                            </div>
                                                                        )}
                                                                        >
                                                                            <div key={period.id} className={period.value==true ? 'period_week_car_chart_activity work ' + period.class : 'period_week_car_chart_activity relax '  + period.class}>
        
                                                                            </div>
                                                                        </Tippy>
                                                                    </div>
                                                                    
                                                                )} 
                                                            </div>
                                                            <div className='week_car_chart_activity'>
                                                                {days_of_week[day.day_of_week]}
                                                            </div>
                                                        </td>
                                                    )}
                                                    </>
                                                }
                                                </>
                                            }
                                        {/* )} */}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        :
                            <div className='no_data_car_chart_mileage' style={{'height': '170px'}}>Нет данных</div>
                    }
                </>
                :
                    <div style={{'height': '150px', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center'}}><ReactLoading type={'spin'} color="#6486FB" /></div>
            }
        </div>
    )

    async function get_car_activity_history(){
        setIs_loading(true)
        let car_activity_history_new = await axios_api.car_history(cookies.csrftoken, time_range_filter_value, 'working', props.car.id)
        console.log('car_activity_history_new : ', car_activity_history_new)

        if (car_activity_history_new == 'error'){
            setCar_activity_history(null)
            setIs_loading(false)
            return
        }

        if (!car_activity_history_new.length){
            setCar_activity_history([])
        } else {
            setCar_activity_history(car_activity_history_new)
        }
        setIs_loading(false)
    }
}