import React from 'react'
import { Link } from 'react-router-dom'
import rectangl from '../../styles/icons/rectangl_navbar.svg'

export default function Navbar_item(props) {
  let class_status_icon
  let class_status_text
  let class_status_rectangl
  let class_status_div_bottom
  let href = '/' + props.page

  if (props.active_page == props.page) {
    class_status_icon = 'active-icon'
    class_status_text = 'active-text-navbar'
    class_status_rectangl = 'active-rectangl_navbar'

    class_status_div_bottom = 'div_bottom_navbar_item_rectangl-active'
  } else {
    class_status_icon = 'normal-icon'
    class_status_text = 'normal-text-navbar'
    class_status_rectangl = 'normal-rectangl_navbar'

    class_status_div_bottom = 'div_bottom_navbar_item_rectangl-none'
  }

  return (
    <Link to={href} className='navbar_main-item'>
      <div className='div_top_navbar_item_rectangl'></div>
      <div className='navbar_main-icon_text'>
        <img className={class_status_icon} src={props.img} />
        <span className={class_status_text}>{props.text}</span>
      </div>

      <img className={class_status_rectangl} src={rectangl} />
      <div className={class_status_div_bottom}></div>
    </Link>
  )
}
