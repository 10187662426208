import React, { useEffect, useState, useContext } from 'react';

import '../../styles/drivers/drivers_main.css'

import { useCookies } from 'react-cookie';
import axios_api from "../../API/axios_api"
import { DriversContext } from '../../context/index';
import Main_driver_profile from './driver_profile/main_driver_profile.jsx'
import Blocked_driver_profile from './driver_profile/blocked_driver_profile.jsx'
import Main_drivers_list from './drivers_list/main_drivers_list.jsx'

export default function Draw_drivers() {
    const [cookies] = useCookies(['csrftoken']);
    let {drivers_object} = useContext(DriversContext)

    const [drivers, setDrivers] = useState(null)
    const [aggregators, setAggregators] = useState(null)
    const [cars_delailed, setCars_delailed] = useState(null)

    const [show_all_drivers, setShow_all_drivers] = useState(false)
    
    const [driver_profile_id, setDriver_profile_id] = useState(null)
    const [driver_profile, setDriver_profile] = useState(null)
    const [rerender, setRerender] = useState((new Date).getTime())

    useEffect(() => {
        get_aggregators()
        get_cars_and_drivers()
    }, []);

    useEffect(() => {
        setDriver_profile_id(null)
        setDriver_profile(null)
        setDrivers(null)
        setAggregators(null)
        setCars_delailed(null)
        get_aggregators()
        get_cars_and_drivers()
    }, [show_all_drivers]);

    if (drivers_object.first_drivers){
        setInterval(() => {
            get_aggregators()
            get_cars_and_drivers()
        }, 30000);
        drivers_object.first_drivers = false
    }

    function click_driver(id){
        console.log('click_driver : ', id)
        setDriver_profile_id(id)

        for (let driver of drivers){
            if (driver.id == id){
                setDriver_profile(driver)
            }
        }
    }

    if (!driver_profile_id){
        if (drivers){
            if (drivers[0]){
                click_driver(drivers[0].id)   
            }
        }
    }

    return (
        <div className='div_main_drivers'>
            <div className='div_drivers_list'>
                <Main_drivers_list aggregators={aggregators} drivers={drivers} cars={cars_delailed} driver_profile_id={driver_profile_id} click_driver={click_driver} show_all_drivers={show_all_drivers} setShow_all_drivers={setShow_all_drivers}/>
            </div>
            <div className='big_div_driver_profile'>
                <div className='div_driver_profile' style={{'height': '250px'}}>
                    <Main_driver_profile driver={driver_profile} driver_profile_id={driver_profile_id} drivers={drivers} get_drivers={get_cars_and_drivers} setDriver_profile={setDriver_profile}/>
                </div>
                {(driver_profile)&&(driver_profile.engine_status != 'normal')
                    ?
                        <Blocked_driver_profile driver={driver_profile} driver_profile_id={driver_profile_id} drivers={drivers} get_drivers={get_cars_and_drivers} setDriver_profile={setDriver_profile}/>
                    :
                        <></>
                }
            </div>
        </div>
    )

    // async function get_drivers(){
    // }

    async function get_cars_and_drivers(){
        let cars = await axios_api.get_cars_detailed(cookies.csrftoken)
        console.log('new cars detailed : ', cars)
        setCars_delailed(cars)

        let drivers_new
        if (show_all_drivers){
            drivers_new = await axios_api.get_drivers(cookies.csrftoken)
        } else {
            drivers_new = await axios_api.get_drivers_active(cookies.csrftoken)
        }

        console.log('drivers_new : ', drivers_new)
        setDrivers(prepare_drivers(drivers_new, cars))
    }

    async function get_aggregators(){
        let aggregators_new = await axios_api.get_aggregators(cookies.csrftoken)
        console.log('aggregators_new : ', aggregators_new)
        setAggregators(aggregators_new)
    }

    function prepare_drivers(drivers_new, cars){
        let prepared_drivers = []
        
        for (let driver of drivers_new){
            let unblock_until = null

            for (let car of cars){
                if (driver.car == car.id){
                    unblock_until = car.unblock_until
                }
            }

            if (driver.car_blocked){
                driver.engine_status = 'red'
                driver.engine_status_digit = 2
            } else {
                if (unblock_until != null){
                    driver.engine_status = 'yellow'
                    driver.engine_status_digit = 1
                } else {
                    driver.engine_status = 'normal'
                    driver.engine_status_digit = 0
                }
            }

            driver.unblock_until = unblock_until
            prepared_drivers.push(driver)
        }

        // console.log('prepared_drivers : ', prepared_drivers)

        prepared_drivers.sort(function(a, b){
            return b.engine_status_digit - a.engine_status_digit;
        })

        console.log('prepared_drivers : ', prepared_drivers)

        return prepared_drivers
    }

}