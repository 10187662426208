import React from "react";
import '../../styles/routes_map/routes.css'

const Modal_accept_delete = ({active, setActive, children}) => {

    return (
        <div className={active ? "modal_accept_delete active" : "modal_accept_delete"} onClick={() => setActive(false)}>
            <div className="modal_accept_delete_content" onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );

}

export default Modal_accept_delete;