import React, { useEffect, useState, useContext } from 'react'

import '../../styles/report/main_report.css'

import { useCookies } from 'react-cookie'
import axios_api from '../../API/axios_api'
import map_api from '../../API/map_api'
import { MapContext } from '../../context/index'
import { ReportContext } from '../../context/index'
import Car_list from './car_list.jsx'
import Create_report from './сreate_report.jsx'
import Charts from './charts.jsx'

import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

import { useDispatch, useSelector } from 'react-redux'
import resize_action from '../../actions/window_actions.js'
import $ from 'jquery'

export default function Draw_report() {
  const dispatch = useDispatch()
  let { report_object } = useContext(ReportContext)
  const [cookies] = useCookies(['csrftoken'])

  const [time, setTime] = useState(null)
  const [groups, setGroups] = useState(null)
  const [cars_delailed, setCars_delailed] = useState(null)
  const [cars_for_report, setCars_for_report] = useState([])

  const [checked_metrics, setChecked_metrics] = useState([])
  const [all_metrics, setAll_metrics] = useState(null)

  const [report_ready, setReport_ready] = useState(false)

  useEffect(() => {
    update_all()
  }, [])

  useEffect(() => {
    make_all_metrics()
    setChecked_metrics([])
    setReport_ready(false)
    // console.log('cars_for_report.length : ', cars_for_report.length)
  }, [cars_for_report.length])

  useEffect(() => {
    setReport_ready(false)
  }, [checked_metrics.length])

  useEffect(() => {
    setTimeout(() => {
      let height = $(document).height()
      let width = $(document).width()
      dispatch(resize_action(height, width))
    }, 1500)
    // console.log('effect')
  }, [report_ready])

  if (report_object.first_report) {
    setInterval(() => {
      update_all()
    }, 30000)
    report_object.first_report = false
  }

  return (
    <div className="div_main_report">
      <div className="div_car_list_report">
        <Car_list
          groups={groups}
          cars={cars_delailed}
          cars_for_report={cars_for_report}
          setCars_for_report={setCars_for_report}
          setTime={setTime}
        />
      </div>
      <div className="div_create_report">
        <Create_report
          cars_for_report={cars_for_report}
          cars={cars_delailed}
          all_metrics={all_metrics}
          checked_metrics={checked_metrics}
          setChecked_metrics={setChecked_metrics}
          setCars_for_report={setCars_for_report}
          setTime={setTime}
          report_ready={report_ready}
          setReport_ready={setReport_ready}
        />

        <SlideDown className={'my-dropdown-slidedown'}>
          {report_ready ? (
            <Charts
              cars_for_report={cars_for_report}
              cars={cars_delailed}
              all_metrics={all_metrics}
              checked_metrics={checked_metrics}
              setTime={setTime}
            />
          ) : (
            <></>
          )}
        </SlideDown>
      </div>
    </div>
  )

  function update_all() {
    get_groups()
    get_cars_detailed()
  }

  async function get_groups() {
    let groups_new = await axios_api.get_groups(cookies.csrftoken)
    // console.log('report new groups : ', groups_new)
    setGroups(groups_new)
  }

  async function get_cars_detailed() {
    let cars = await axios_api.get_cars_detailed(cookies.csrftoken)
    // console.log('report new cars detailed : ', cars)
    setCars_delailed(cars)
  }

  async function make_all_metrics() {
    setAll_metrics(null)
    let new_all_metrics = []

    if (cars_delailed == null) {
      return
    }

    // // график пробега
    // new_all_metrics.push({'type': 'mileage', 'name': 'Пробег', 'units': 'км'})
    // //

    // //график работы
    // new_all_metrics.push({'type': 'working', 'name': 'Активность', 'units': '-'})
    // //

    // for (let car of cars_delailed){

    //     if (!cars_for_report.includes(car.id)){
    //         continue
    //     }

    //     if (car.inputs != null){
    //         for (let input of car.inputs){

    //             if (input.type == 'POW'){
    //                 continue
    //             }
    //             if (input.type == 'MILE'){
    //                 continue
    //             }
    //             if (input.type == 'MIL'){
    //                 continue
    //             }
    //             if (input.type == 'VEL'){
    //                 continue
    //             }

    //             let find = false

    //             for (let metric of new_all_metrics){
    //                 if (metric.type == input.type){
    //                     find = true
    //                 }
    //             }

    //             if (!find){
    //                 new_all_metrics.push({'type': input.type, 'name': input.verbose_name, 'units': input.units})
    //             }
    //         }
    //     }
    // }

    let new_all_metrics_resp = await axios_api.get_all_metrics_resp(
      cars_for_report,
      cookies.csrftoken
    )

    if (new_all_metrics_resp.status === 200) {
      for (let key in new_all_metrics_resp.data) {
        for (let input of new_all_metrics_resp.data[key]) {
          let find = false

          for (let metric of new_all_metrics) {
            if (metric.type === input.type) {
              metric.cars_id.push(key)
              find = true
            }
          }

          if (!find) {
            new_all_metrics.push({
              cars_id: [key],
              type: input.type,
              name: input.name,
              units: input.units,
            })
          }
        }
      }
    } else {
      setAll_metrics([])
    }
    setAll_metrics(new_all_metrics)
  }
}
