import axios from 'axios'

let base_url

if (process.env.REACT_APP_SERVER === 'PROD') {
  base_url = 'https://api.controlgps.org/api/v2/'
} else {
  base_url = 'http://dev.api.controlgps.org/api/v2/'
}

export default class shareAxiosApi {
  static async getCarRoute(key, start, end) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    }

    let href = base_url + `sessions/route/?token=${key}&date_start=${start}&date_end=${end}`

    let response = await axios.get(href, { headers })

    // console.log('response getCarRoute : ', response)

    return response
  }

  static async getCarData(key) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    }

    let href = base_url + `sessions/car/?token=${key}`

    let response = await axios.get(href, { headers })

    // console.log('response getCarData : ', response)

    return response
  }
}
