import React from "react";

const Basic_modal = ({active, setActive, children}) => {

    return (
        <div className={active ? "basic_modal active" : "basic_modal"} onClick={() => setActive(false)}>
            <div className="basic_modal_content" onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );

}

export default Basic_modal;