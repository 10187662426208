import React, { useEffect, useContext } from 'react'
import Draw_report from '../components/report/draw_report.jsx'

import { useDispatch } from 'react-redux'
import resize_action from '../actions/window_actions.js'
import page_change from '../actions/router_actions.js'
import { MapContext } from '../context/index'
import $ from 'jquery'

const MainReport = () => {
  let { map_object } = useContext(MapContext)
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = 'Отчет | ControlGPS'
    let height = $('#root').height()
    let width = $('#root').width()
    dispatch(resize_action(height, width))
    dispatch(page_change('report'))
    map_object.markers = []
    map_object.map = null
  }, [])

  return <Draw_report />
}

export default MainReport
