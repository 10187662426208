/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useEffect } from 'react'
import Draw_map from '../components/map/draw_map.js'
import Routes_window from '../components/map/window.jsx'
import Notifications from '../components/map/notifications/main_notifications.jsx'
import Modal_bills from '../components/map/bills_modal/modal_bills.jsx'
import { MapContext } from '../context/index'
import map_service from '../API/map_api.js'
import axios_api from '../API/axios_api.js'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import page_change from '../actions/router_actions.js'
import resize_action from '../actions/window_actions.js'
import $ from 'jquery'

let stop = false

const MainMap = () => {
  const dispatch = useDispatch()
  let { map_object } = useContext(MapContext)
  const park_current = useSelector((state) => state.park_current)
  const user_current = useSelector((state) => state.user_current)

  if (window.location.pathname !== '/map') {
    window.history.replaceState(null, 'New Page Title', '/map')
  }

  const [cookies] = useCookies(['csrftoken'])

  if (map_object.first_init) {
    setInterval(() => {
      reload_markers_map()
    }, 10000)
    map_object.first_init = false
  }

  useEffect(() => {
    reload_markers_map()
    document.title = 'Карта | ControlGPS'
    let height = $(document).height()
    let width = $(document).width()
    dispatch(resize_action(height, width))
    dispatch(page_change('map'))
  }, [])

  map_object.draw_routes = false

  return (
    <>
      <Draw_map park_current={park_current} />
      <Routes_window />
      <Notifications />
      <Modal_bills />
    </>
  )

  async function reload_markers_map() {
    if (user_current) {
      if (window.location.pathname === '/map') {
        map_object.cars = await axios_api.get_markers(cookies.csrftoken, map_object.frame_index)

        map_object.frame_index = map_object.frame_index + 1 //инфа для бэка
        if (map_object.navbar_item !== 'routes') {
          await map_service.draw_markers(map_object, 'ALL', user_current)
        }

        console.log('reloaded map_object: ', map_object)
      }
    } else {
      if (!stop) {
        stop = true
        setTimeout(() => {
          reload_markers_map()
          stop = false
        }, 300)
      }
    }
  }
}

export default MainMap
