import React, { useEffect, useState, useContext } from 'react';
import '../../../styles/drivers/drivers_main.css'
import Btn_defolt from '../../UI/btn_defolt'
import Modal_with_accept from '../../UI/modal_with_accept'
import axios_api from "../../../API/axios_api"
import { useCookies } from 'react-cookie';

export default function Blocked_driver_profile(props) {
    const [cookies] = useCookies(['csrftoken']);

    const [unblock_count, setUnblock_count] = useState('5')

    const [modal_cancel_active, setActive_modal_cancel] = useState(false)
    const [modal_unblock_active, setActive_modal_unblock] = useState(false)

    const [status_modal_cancel, setStatus_modal_cancel] = useState('')
    const [status_modal_unblock, setStatus_modal_unblock] = useState('')

    function open_modal_cancel(){
        setActive_modal_cancel(true)
    }

    async function accept_cancel(){
        let response = await axios_api.car_unblock_until('None', props.driver.car, cookies.csrftoken)

        if (response.status == '200'){
            setStatus_modal_cancel('success')
        } else {
            setStatus_modal_cancel('error')
        }

        setTimeout(
            function(){
                setActive_modal_cancel(false)
                setStatus_modal_cancel('')
                props.get_drivers()
            }, 1000
        );
    }

    function open_modal_unblock(){
        setActive_modal_unblock(true)
    }

    function convert_to_date(days){
        Date.prototype.yyyymmdd = function() {
            var mm = this.getMonth() + 1; // getMonth() is zero-based
            var dd = this.getDate();
            return [
                    (dd>9 ? '' : '0') + dd,
                    (mm>9 ? '' : '0') + mm,
                    this.getFullYear()
                   ].join('.');
        };

        Date.prototype.addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }
          
        let date_now = new Date();
        date_now = date_now.addDays(parseInt(days))

        return (date_now.yyyymmdd() + ' ' + date_now.getHours() + ':' + date_now.getMinutes() + ':' + date_now.getSeconds())
    }

    function convert_date(date){
        return date.split('T')[0].split('-')[2] + '.' + date.split('T')[0].split('-')[1] + '.' + date.split('T')[0].split('-')[0]
    }

    async function accept_unblock(){
        let response = await axios_api.car_unblock_until(convert_to_date(unblock_count), props.driver.car, cookies.csrftoken)

        if (response.status == '200'){
            setStatus_modal_unblock('success')
        } else {
            setStatus_modal_unblock('error')
        }

        setTimeout(
            function(){
                setActive_modal_unblock(false)
                setStatus_modal_unblock('')
                props.get_drivers()
            }, 1000
        );
    }

    let first_name = '-'
    let last_name = '-'
    let middle_name = '-'
    let fio

    if (props.driver.first_name){
        if (props.driver.first_name[0]){
            first_name = props.driver.first_name[0]
        }
    }

    if (props.driver.last_name){
        last_name = props.driver.last_name
    }

    if (props.driver.middle_name){
        if (props.driver.middle_name[0]){
            middle_name = props.driver.middle_name[0]
        }
    }

    fio = last_name + ' ' + first_name + '. ' + middle_name + '.'

    // let style

    // if (props.driver.engine_status == 'red'){   
    //     style = {
    //         'height': '200px'
    //     }
    // } else {
    //     style = {
    //         'height': '200px'
    //     }
    // }

    return(
        <div className='div_driver_profile'>
            <div className={'div_engine_unblock'}>
                {props.driver.engine_status == 'red'
                    ?
                        <>
                            <div className={'text_engine_blocked'}>
                                Авто заглушено, так как баланс ниже допустимого.
                            </div>
                            <div style={{'display': 'flex', 'alignItems': 'center', 'marginLeft': '24px', 'marginRight': '24px'}}>
                                <div className={'text_engine_unblock'}>
                                    Разглушить авто на 
                                </div>
                                <input className="input_money_refill_settings" type="text" value={unblock_count} onChange={(e) => setUnblock_count(e.target.value)} style={{'marginLeft': '10px', 'marginRight': '10px', 'width': '70px'}}></input>
                                <div className={'text_engine_unblock'}>
                                    дней
                                </div>
                            </div>
                            <Btn_defolt color={'#fa5c7c'} text_color={'#FFFFFF'} hover_color={'#fa4166'} text={'Разглушить'} height={'40px'} width={'100%'} onClick={open_modal_unblock}/>
                        </>
                    :
                        <>
                            <div className={'text_engine_unblocked'}>
                                Авто разглушено до:
                            </div>
                            <div className={'text_engine_unblocked_date'}>
                                {convert_date(props.driver.unblock_until)}
                            </div>
                            {/* <div style={{'display': 'flex', 'alignItems': 'center', 'marginLeft': '24px', 'marginRight': '24px', 'justifyContent': 'center'}}>
                                <div className={'text_engine_unblock'} style={{'marginLeft': '10px'}}>
                                    Разглушить авто на 
                                </div>
                                <input className="input_money_refill_settings" type="text" value={unblock_count} onChange={(e) => setUnblock_count(e.target.value)} style={{'marginLeft': '10px', 'marginRight': '10px', 'width': '100px'}}></input>
                                <div className={'text_engine_unblock'}>
                                    дней
                                </div>
                            </div> */}
                            <Btn_defolt color={'#e8b62c'} text_color={'#FFFFFF'} hover_color={'#f90'} text={'Отменить'} height={'40px'} width={'100%'} onClick={open_modal_cancel}/>
                        </>
                }
                <Modal_with_accept active={modal_unblock_active} setActive={setActive_modal_cancel}>
                    <div className='text_accept_delete'>
                        Вы уверены, что хотите разглушить авто водителя '{fio}' на {unblock_count} дней?
                    </div>
                    <div style={{'display' : 'flex', 'justifyContent': 'space-between', 'marginTop': '30px'}}>
                        <Btn_defolt color={'#EFF3FF'} text_color={'#6486FB'} hover_color={'#dbe4ff'} text={'Отмена'} height={'40px'} width={'150px'} onClick={(e) => setActive_modal_unblock(false)}/>
                        <Btn_defolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Разглушить'} height={'40px'} width={'150px'} onClick={accept_unblock}/>
                    </div>
                    <div className={status_modal_unblock == 'success' ? "status_modal_success active" : "status_modal_success"}>
                        <div style={{'display': 'flex', 'flexDirection': 'column'}}>   
                            <div className='text_status_main'>
                                Успешно!
                            </div>
                            <div className='text_status_info'>
                                Авто разглушено!
                            </div>
                        </div>
                    </div>
                    <div className={status_modal_unblock == 'error' ? "status_modal_error active" : "status_modal_error"}>
                        <div style={{'display': 'flex', 'flexDirection': 'column'}}>
                            <div className='text_status_main'>
                                Ошибка!
                            </div>
                            <div className='text_status_info'>
                                Не удалось разглушить авто!
                            </div>
                        </div>
                    </div>
                </Modal_with_accept>
                <Modal_with_accept active={modal_cancel_active} setActive={setActive_modal_cancel}>
                    <div className='text_accept_delete'>
                        Вы уверены, что хотите заглушить авто водителя '{fio}'?
                    </div>
                    <div style={{'display' : 'flex', 'justifyContent': 'space-between', 'marginTop': '30px'}}>
                        <Btn_defolt color={'#EFF3FF'} text_color={'#6486FB'} hover_color={'#dbe4ff'} text={'Отмена'} height={'40px'} width={'150px'} onClick={(e) => setActive_modal_cancel(false)}/>
                        <Btn_defolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Заглушить'} height={'40px'} width={'150px'} onClick={accept_cancel}/>
                    </div>
                    <div className={status_modal_cancel == 'success' ? "status_modal_success active" : "status_modal_success"}>
                        <div style={{'display': 'flex', 'flexDirection': 'column'}}>   
                            <div className='text_status_main'>
                                Успешно!
                            </div>
                            <div className='text_status_info'>
                                Авто заглушено!
                            </div>
                        </div>
                    </div>
                    <div className={status_modal_cancel == 'error' ? "status_modal_error active" : "status_modal_error"}>
                        <div style={{'display': 'flex', 'flexDirection': 'column'}}>
                            <div className='text_status_main'>
                                Ошибка!
                            </div>
                            <div className='text_status_info'>
                                Не удалось заглушить авто!
                            </div>
                        </div>
                    </div>
                </Modal_with_accept>
            </div>
        </div>
    )
}