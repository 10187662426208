import React, { useEffect, useState, useContext } from 'react'
import '../../../styles/routes_map/routes.css'
import { MapContext } from '../../../context/index'
import Routes_info_list_item from './routes_info_list_item'

export default function Routes_info_list() {
  let { map_object } = useContext(MapContext)

  let id = 0

  for (let day of map_object.routes) {
    day.id = id
    id = id + 1
  }

  return (
    <div className='div_routes_info_list'>
      {map_object.routes.length ? (
        <>
          {map_object.routes.map((day) => (
            <Routes_info_list_item key={day.id} day={day} />
          ))}
        </>
      ) : (
        <div className='no_routes'>Нет данных за этот период</div>
      )}
    </div>
  )
}
