/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import routeIcon from '../../styles/icons/route_icon_routes.svg'
import carIcon from '../../styles/icons/car_icon_routes.svg'
import line from '../../styles/icons/rectangl_icon_routes.svg'
import arrow_white_input from '../../styles/icons/arrow_white_input.svg'
import IconBtn from '../UI/icon_btn'
import React, { useState } from 'react'
import './Share.css'
import Inputs from './Inputs'
import Route from './Route'
import 'react-slidedown/lib/slidedown.css'

const Panel = (props) => {
  const [tab, setTab] = useState('inputs')
  const [open, setOpen] = useState(!props.isMobile)

  const mobileClass = props.isMobile ? '_mobile' : ''

  return (
    <div className={'routeInfo' + mobileClass}>
      {props.isMobile ? (
        <div className='btnOpen' onClick={() => setOpen(!open)}>
          <div className='btnOpenBody'>
            <div style={open ? undefined : { rotate: '180deg' }}>
              <IconBtn
                onClick={() => setOpen(!open)}
                active={true}
                color={'#6486FB'}
                hover_color={'#4d76fa'}
                height={'20px'}
                width={'60px'}
                icon={arrow_white_input}
              />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {open ? (
        <>
          <div className='tabsRouteInfo'>
            <div
              className={tab === 'inputs' ? 'tabRouteInfo_active' : 'tabRouteInfo'}
              onClick={() => setTab('inputs')}
            >
              <div className='lineTopRouteInfo'></div>
              <div>
                <img
                  className={tab === 'inputs' ? 'iconRouteInfo_active' : 'iconRouteInfo'}
                  src={carIcon}
                />
              </div>
              <img
                className={tab === 'inputs' ? 'lineRouteInfo_active' : 'lineRouteInfo'}
                src={line}
              />
              <div className={tab === 'inputs' ? 'lineRouteInfo' : 'lineRouteInfo_psevdo'}></div>
            </div>
            <div
              className={tab === 'route' ? 'tabRouteInfo_active' : 'tabRouteInfo'}
              onClick={() => setTab('route')}
            >
              <div className='lineTopRouteInfo'></div>
              <div>
                <img
                  className={tab === 'route' ? 'iconRouteInfo_active' : 'iconRouteInfo'}
                  src={routeIcon}
                />
              </div>
              <img
                className={tab === 'route' ? 'lineRouteInfo_active' : 'lineRouteInfo'}
                src={line}
              />
              <div className={tab === 'route' ? 'lineRouteInfo' : 'lineRouteInfo_psevdo'}></div>
            </div>
          </div>
          <div style={tab === 'inputs' ? undefined : { display: 'none' }}>
            <Inputs car={props.car} isMobile={props.isMobile} />
          </div>
          <div style={tab !== 'inputs' ? undefined : { display: 'none' }}>
            <Route
              route={props.route}
              routeLoading={props.routeLoading}
              isMobile={props.isMobile}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Panel
