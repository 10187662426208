export const initialState = {
    window_height: document.documentElement.clientHeight,
    window_width: document.documentElement.clientWidth,
    user_current: null,
    park_current: null,
    page_current: '',
  };
  
  export function rootReducer(state = initialState, action) {
    switch (action.type) {
        case 'WINDOW_RESIZE':
            return { ...state, window_height: action.payload.height, window_width: action.payload.width };
        case 'USER_LOADED':
          return { ...state, user_current: action.payload.user_current };
        case 'PARK_LOADED':
          return { ...state, park_current: action.payload.park_current };
        case 'PAGE_CHANGE':
            return { ...state, page_current: action.payload.page_current };
        default:
          return state;
      }
  }