import '../../../styles/routes_map/car_table_zone.css'
import React from 'react';

export default function Car_table_add_cars_to_zone_item(props) {
    let car = props.car

    if (car.car_number == null){
        car.car_number = '-'
    }
    if (car.brand == null){
        car.brand = '-'
    }

    if (car.phone_number == null){
        car.phone_number = '-'
    }

    function check_box_click(e){
        let id = e.currentTarget.getAttribute('data_id')
        let checked = e.currentTarget.checked

        console.log('1: ', props.add_car_to_zone)

        let count = 0
        let find = false

        for (let car_id_in_array of props.add_car_to_zone){
            if (car_id_in_array == id){
                find = true
                break;
            }
            count = count + 1
        }

        if (checked){
            if (!find){
                props.add_car_to_zone.push(parseFloat(id))
            }
        } else {
            if (find){
                props.add_car_to_zone.splice(count, 1)
            }
        }

        console.log('2: ', props.add_car_to_zone)

        props.setAdd_car_to_zone(props.add_car_to_zone)
        props.setRender_table(new Date)
    }

    let checkbox

    //console.log('car : ', car.id, ' - ' , props.checked)

    if (props.checked == 'default checked'){
        checkbox = <input className='form-check-input' disabled checked={true} type='checkbox' data_id={car.id}/>
    }
    if (props.checked == 'checked'){
        checkbox = <input className='form-check-input' type='checkbox' checked={true} data_id={car.id} onChange={(e) => check_box_click(e)}/>
    }
    if (props.checked == 'unchecked'){
        checkbox = <input className='form-check-input' type='checkbox' checked={false} data_id={car.id} onChange={(e) => check_box_click(e)}/>
    }

    return (    
        <div className='table_zone_row'>
            <div className='table_zone_div'>
                <div className='table_zone_td_checkbox'>
                    {checkbox}
                </div>
                <div className='table_zone_td_img'>
                    <img src={props.car_img} style={{'height': '25px'}}/>
                </div>
                <div className='table_zone_td_number'>
                    <div className='table_zone_number'>
                        {car.car_number}
                    </div>
                    <div className='table_zone_brand'>
                        {car.brand}
                    </div>
                </div>
                <div className='table_zone_td_phone_number'>
                    {car.phone_number}
                </div>
            </div>
            <div className='div_border_bottom_table_zone_row'>
                <div className='border_bottom_table_zone_row'></div>
            </div>
        </div>
    )
}