import React from "react";
import '../../../styles/routes_map/routes.css'

const Modal_zones = ({active, setActive, children}) => {

    return (
        <div className={active ? "modal_zones active" : "modal_zones"} onClick={() => setActive(false)}>
            <div className="modal_zones_content" onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );

}

export default Modal_zones;