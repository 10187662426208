/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react'
import '../../../styles/park_settings/main_park_settings.css'
import Btn_arrow_for_settings from '../btn_arrow_for_settings.jsx'
import arrow_right_blue_icon from '../../../styles/icons/arrow_right_blue_icon.svg'
import arrow_right_white_icon from '../../../styles/icons/arrow_right_white_icon.svg'

export default function TableItemInspections(props) {
  let inspection = props.inspection

  const [active, setActive] = useState(false)

  function row_click(e) {
    let id = e.currentTarget.getAttribute('data_id')
    props.row_click(id)
  }

  const carsCount = () => {
    let count = 0
    if (props.cars_delailed) {
      for (let car of props.cars_delailed) {
        if (car.inspection_group === inspection.id) {
          count++
        }
      }
    }
    return count
  }

  return (
    <div className="">
      <div
        className="table_row_inspections_settings"
        data_id={inspection.id}
        onMouseEnter={(e) => setActive(true)}
        onMouseLeave={(e) => setActive(false)}
        onClick={(e) => row_click(e)}
      >
        <div className="td_name_inspections_settings">
          {inspection.verbose_name}
        </div>
        <div className="td_brand_inspections_settings">
          {inspection.brand}{' '}
          <span className="model_inspections_settings">{inspection.model}</span>
        </div>
        <div className="td_count_category_inspections_settings">
          <div>{inspection.categories.length} парам.</div>
        </div>
        <div className="td_count_cars_inspections_settings">
          <div>{carsCount()} авто</div>
        </div>
        <div className="td_arrows_inspections_settings">
          <Btn_arrow_for_settings
            active={active}
            color={'#EFF3FF'}
            active_color={'#6486FB'}
            not_active_icon={arrow_right_blue_icon}
            active_icon={arrow_right_white_icon}
            height={'36px'}
            width={'36px'}
          />
        </div>
      </div>
      <div className="div_border_bottom_inspections_settings">
        <div className="border_bottom_inspections_settings"></div>
      </div>
    </div>
  )
}
