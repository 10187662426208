import React, { useEffect, useState, useContext } from 'react';
import '../../../styles/routes_map/car_routes.css'
export default function Cars_for_draw_routes(props) {

    //console.log('props : ', props)

    return (
        <div>
            {props.info_draw_routes.car
                ? 
                    <div className='car_div_routes' onClick={props.onClick}>
                        <div className='car_number'>
                            {props.info_draw_routes.car.car_number}
                        </div>
                        <div className='car_brand'>
                            {props.info_draw_routes.car.brand}
                        </div>
                    </div>
                :   
                    <div className='car_div_routes' onClick={props.onClick}>
                        <div className='car_number'>
                            Авто не выбрано
                        </div>
                    </div>
            }    
        </div>

    )
}