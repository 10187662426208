/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react'
import Basic_input from '../../../UI/basic_input.jsx'
import trash from '../../../../styles/icons/Trash.svg'
import '../../../../styles/park_settings/main_park_settings.css'
import Btn_defolt from '../../../UI/btn_defolt.jsx'

export default function Param(props) {
  const [nameValue, setNameValue] = useState(props.param.verbose_name)
  const [type, setType] = useState(props.param.period_type) // MILEAGE, MILEAGE_RANGES, TIME

  const [milValue, setMilValue] = useState(props.param.value_period)

  const [rangesValue1, setRangesValue1] = useState(
    props.param.mileage_ranges[0]
  )
  const [rangesValue2, setRangesValue2] = useState(
    props.param.mileage_ranges[1]
  )
  const [rangesValue3, setRangesValue3] = useState(
    props.param.mileage_ranges[2]
  )
  const [rangesValue4, setRangesValue4] = useState(
    props.param.mileage_ranges[3]
  )

  const [timeValue, setTimeValue] = useState(props.param.value_period)

  const typeChange = (newValue) => {
    for (let item of props.params) {
      if (item.id === props.param.id) {
        if (props.param.status !== 'new') {
          props.param.edited = true
        }
        props.param.period_type = newValue
        props.setParams(props.params)
      }
    }
    setType(newValue)
  }

  const nameChange = (newValue) => {
    for (let item of props.params) {
      if (item.id === props.param.id) {
        if (props.param.status !== 'new') {
          props.param.edited = true
        }
        props.param.verbose_name = newValue
        props.setParams(props.params)
      }
    }
    setNameValue(newValue)
  }

  const milValueChange = (newValue) => {
    for (let item of props.params) {
      if (item.id === props.param.id) {
        if (props.param.status !== 'new') {
          props.param.edited = true
        }
        props.param.value_period = newValue
        props.setParams(props.params)
      }
    }
    setMilValue(newValue)
  }

  const rangesValue1Change = (newValue) => {
    for (let item of props.params) {
      if (item.id === props.param.id) {
        if (props.param.status !== 'new') {
          props.param.edited = true
        }
        props.param.mileage_ranges = [
          newValue,
          rangesValue2,
          rangesValue3,
          rangesValue4,
        ]
        props.setParams(props.params)
      }
    }
    setRangesValue1(newValue)
  }

  const rangesValue2Change = (newValue) => {
    for (let item of props.params) {
      if (item.id === props.param.id) {
        if (props.param.status !== 'new') {
          props.param.edited = true
        }
        props.param.mileage_ranges = [
          rangesValue1,
          newValue,
          rangesValue3,
          rangesValue4,
        ]
        props.setParams(props.params)
      }
    }
    setRangesValue2(newValue)
  }

  const rangesValue3Change = (newValue) => {
    for (let item of props.params) {
      if (item.id === props.param.id) {
        if (props.param.status !== 'new') {
          props.param.edited = true
        }
        props.param.mileage_ranges = [
          rangesValue1,
          rangesValue2,
          newValue,
          rangesValue4,
        ]
        props.setParams(props.params)
      }
    }
    setRangesValue3(newValue)
  }

  const rangesValue4Change = (newValue) => {
    for (let item of props.params) {
      if (item.id === props.param.id) {
        if (props.param.status !== 'new') {
          props.param.edited = true
        }
        props.param.mileage_ranges = [
          rangesValue1,
          rangesValue2,
          rangesValue3,
          newValue,
        ]
        props.setParams(props.params)
      }
    }
    setRangesValue4(newValue)
  }

  const timeValueChange = (newValue) => {
    for (let item of props.params) {
      if (item.id === props.param.id) {
        if (props.param.status !== 'new') {
          props.param.edited = true
        }
        props.param.value_period = newValue
        props.setParams(props.params)
      }
    }
    setTimeValue(newValue)
  }

  const renderInputs = () => {
    if (type === 'MILEAGE') {
      return (
        <div style={{ marginTop: '24px' }}>
          <Basic_input
            value={milValue}
            text={'Для авто с любым пробегом каждые'}
            status={props.param.error === 'value_period' ? 'error' : 'active'}
            width={'342px'}
            type={'text'}
            onChange={milValueChange}
            rightText={'КМ'}
          />
        </div>
      )
    }
    if (type === 'MILEAGE_RANGES') {
      return (
        <div style={{ marginTop: '24px' }}>
          <div style={{ display: 'flex' }}>
            <div>
              <Basic_input
                value={rangesValue1}
                text={'Для авто с пробегом 0-100 тыс км каждые'}
                status={
                  props.param.error === 'mileage_ranges1' ? 'error' : 'active'
                }
                width={'342px'}
                type={'text'}
                onChange={rangesValue1Change}
                rightText={'КМ'}
              />
            </div>
            <div style={{ marginLeft: '24px' }}>
              <Basic_input
                value={rangesValue2}
                text={'Для авто с пробегом 100-200 тыс км каждые'}
                status={
                  props.param.error === 'mileage_ranges2' ? 'error' : 'active'
                }
                width={'342px'}
                type={'text'}
                onChange={rangesValue2Change}
                rightText={'КМ'}
              />
            </div>
          </div>
          <div style={{ display: 'flex', marginTop: '24px' }}>
            <div>
              <Basic_input
                value={rangesValue3}
                text={'Для авто с пробегом 200-300 тыс км каждые'}
                status={
                  props.param.error === 'mileage_ranges3' ? 'error' : 'active'
                }
                width={'342px'}
                type={'text'}
                onChange={rangesValue3Change}
                rightText={'КМ'}
              />
            </div>
            <div style={{ marginLeft: '24px' }}>
              <Basic_input
                value={rangesValue4}
                text={'Для авто с пробегом >300  тыс км каждые'}
                status={
                  props.param.error === 'mileage_ranges4' ? 'error' : 'active'
                }
                width={'342px'}
                type={'text'}
                onChange={rangesValue4Change}
                rightText={'КМ'}
              />
            </div>
          </div>
        </div>
      )
    }
    if (type === 'TIME') {
      return (
        <div style={{ marginTop: '24px' }}>
          <Basic_input
            value={timeValue}
            text={'Для авто с любым пробегом каждые'}
            status={props.param.error === 'value_period' ? 'error' : 'active'}
            width={'342px'}
            type={'text'}
            onChange={timeValueChange}
            rightText={'Дней'}
          />
        </div>
      )
    }
  }

  const countNotDelete = () => {
    let count = 0
    for (let item of props.params) {
      if (item.status !== 'delete') {
        count++
      }
    }
    return count
  }

  const deleteParam = () => {
    for (let item of props.params) {
      if (item.id === props.param.id) {
        if (props.param.status !== 'new') {
          props.param.status = 'delete'
        } else {
          const index = props.params.indexOf(item)
          props.params.splice(index, 1)
        }
        props.setParams(props.params)
        props.setRender(new Date().getTime())
      }
    }
  }

  const returnParam = () => {
    for (let item of props.params) {
      if (item.id === props.param.id) {
        props.param.status = ''
        props.setParams(props.params)
        props.setRender(new Date().getTime())
      }
    }
  }

  return (
    <div className={'paramCard'}>
      {props.param.status === 'delete' ? (
        <div className="paramCardDelete">
          <div className="paramCardDeleteText">
            Вид работы будет удален после сохранения изменений
          </div>
          <div style={{ marginTop: '16px', paddingBottom: '100px' }}>
            <Btn_defolt
              color={'#eff3ff'}
              text_color={'#6486fb'}
              hover_color={'#d2dcfc'}
              text={'Восстановить вид работы'}
              height={'40px'}
              width={'250px'}
              onClick={returnParam}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="paramTitleDiv">
        <div className="paramTitle">Вид работы №{props.number + 1}</div>
        {countNotDelete() > 1 ? (
          <div className="paramDeleteDiv">
            <img src={trash} alt="" />
            <div className="paramDelete" onClick={deleteParam}>
              Удалить вид работы
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div style={{ marginTop: '24px' }}>
        <Basic_input
          value={nameValue}
          text={'Название вида работы'}
          status={props.param.error === 'verbose_name' ? 'error' : 'active'}
          width={'100%'}
          type={'text'}
          onChange={nameChange}
        />
      </div>
      <div className="paramTypeTitle">Период проведения работы</div>
      <div className="paramTypeDiv">
        <div
          className={
            type === 'MILEAGE' ? 'paramTypeItem active' : 'paramTypeItem'
          }
          onClick={() => typeChange('MILEAGE')}
        >
          По километражу
        </div>
        <div
          className={
            type === 'MILEAGE_RANGES' ? 'paramTypeItem active' : 'paramTypeItem'
          }
          onClick={() => typeChange('MILEAGE_RANGES')}
        >
          В зависимости от пробега
        </div>
        <div
          className={type === 'TIME' ? 'paramTypeItem active' : 'paramTypeItem'}
          onClick={() => typeChange('TIME')}
        >
          В период времени
        </div>
      </div>
      <div className="paramValueTitle">
        {type === 'MILEAGE_RANGES'
          ? 'Промежутки прохождения'
          : 'Промежуток прохождения'}
      </div>
      {renderInputs()}
    </div>
  )
}
