/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import Basic_input from '../../UI/basic_input'
import Icon_btn from '../../UI/icon_btn'
import Btn_defolt from '../../UI/btn_defolt'
import content_copy from '../../../styles/icons/content-copy.svg'
import Tippy from '@tippyjs/react/headless'
import Basic_modal from '../../UI/basic_modal'
import ReactLoading from 'react-loading'

export default function CarRouteKeyModal(props) {
  const url = 'https://crm.controlgps.org/share?key=' + props.routeKey

  const copy_text = () => {
    navigator.clipboard.writeText(url)
  }

  return (
    <Basic_modal active={props.active} setActive={props.setActive}>
      <div className='text_accept_delete' style={{ textAlign: 'center' }}>
        Маршрут авто '{props.carNumber}'
      </div>
      <div style={{ width: '500px' }}>
        {props.loading ? (
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '32px',
            }}
          >
            <ReactLoading type={'spin'} color='#6486FB' />
          </div>
        ) : (
          <div style={{ width: '100%', marginTop: '32px' }}>
            <div style={{ display: 'flex', alignItems: 'end' }}>
              <Basic_input value={url} text={''} status={'readonly'} width={'100%'} type={'text'} />
              <Tippy
                render={(attrs) => (
                  <div className='box tippy_content' tabIndex='-1' {...attrs}>
                    Скопировать
                  </div>
                )}
              >
                <div style={{ marginTop: '6px', marginLeft: '8px' }}>
                  <Icon_btn
                    color={'#EFF3FF'}
                    hover_color={'#dbe4ff'}
                    icon={content_copy}
                    height={'46px'}
                    width={'46px'}
                    onClick={copy_text}
                  />
                </div>
              </Tippy>
            </div>
          </div>
        )}
        <div
          style={{ width: '100%', marginTop: '32px', display: 'flex', justifyContent: 'center' }}
        >
          <Btn_defolt
            color={'#EFF3FF'}
            text_color={'#6486FB'}
            hover_color={'#dbe4ff'}
            text={'Закрыть'}
            height={'40px'}
            width={'150px'}
            onClick={(e) => props.setActive(false)}
          />
        </div>
      </div>
    </Basic_modal>
  )
}
