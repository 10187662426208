import React, { useEffect, useState, useContext } from 'react'
import Chart from 'react-apexcharts'
import '../../styles/report/charts.css'

import { useCookies } from 'react-cookie'
import axios_api from '../../API/axios_api'
import map_api from '../../API/map_api'
import { ReportContext } from '../../context/index'

import SimpleBar from 'simplebar-react'
import Basic_select from '../UI/basic_select.jsx'
import ReactLoading from 'react-loading'

import $ from 'jquery'

export default function Chart_item(props) {
  let { report_object } = useContext(ReportContext)

  let colors = report_object.colors

  const [cookies] = useCookies(['csrftoken'])

  const time_range_filter = [
    ['За день', 'DAY'],
    ['За неделю', 'WEEK'],
    ['За месяц', 'MONTH'],
  ]

  const [chart_data, setChart_data] = useState([])
  const [time_range_filter_value, setTime_range_filter_value] = useState('WEEK')
  const [render, setRender] = useState(new Date())
  const [checked_cars, setChecked_cars] = useState([])
  const [first_render, setFirst_render] = useState(true)

  // console.log('checked_cars : ', checked_cars)

  // console.log(props.metric_object.type ,' cars : ', props.metric_object.cars)

  let metric_object = props.metric_object

  if (first_render) {
    let new_checked_cars = []
    for (let car of metric_object.cars) {
      new_checked_cars.push(car.id)
    }
    // console.log('new_checked_cars : ', new_checked_cars)
    setChecked_cars(new_checked_cars)
    setFirst_render(false)
  }

  get_colors()

  let state = prepare_state()

  prepare_state()

  useEffect(() => {
    reload_chart()
  }, [time_range_filter_value])

  return (
    <div className="div_chart_item">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="title_chart" style={{ marginRight: 'auto' }}>
          {metric_object.name}
        </div>
        <Basic_select
          current_value={time_range_filter_value}
          all_values={time_range_filter}
          setValue={setTime_range_filter_value}
          width={'180px'}
        />
      </div>
      <div style={{ display: 'flex', marginTop: '24px' }}>
        <div style={{ width: '200px' }}>
          <SimpleBar style={{ maxHeight: 150 }}>
            {metric_object.cars.map((car) => (
              <div
                key={car.id}
                className="car_item_charts"
                style={{ backgroundColor: car.backgroud_color }}
              >
                <input
                  className={
                    'form-check-input checkbox_chart_' + metric_object.type
                  }
                  checked={check_checked(car.id)}
                  data_id={car.id}
                  type="checkbox"
                  onChange={(e) => checkbox_change(e)}
                />
                <div
                  className="car_number_item_charts"
                  style={{ color: car.text_color }}
                  data_type={metric_object.type}
                  data_id={car.id}
                >
                  {car.car_number}
                </div>
              </div>
            ))}
          </SimpleBar>
        </div>
        <div style={{ width: 'calc(100% - 224px)' }}>
          <Chart
            options={state.options}
            series={state.series}
            type="line"
            width="100%"
            height="200px"
          />
        </div>
      </div>
    </div>
  )

  function prepare_state() {
    let new_state = {
      options: {
        legend: {
          show: false,
        },
        chart: {
          id: 'basic-bar',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              download: false, // <== line to add
            },
          },
          fontFamily: 'Gilroy, Gilroy, Gilroy',
        },
        tooltip: {
          enabled: true,
          marker: {
            show: false,
          },
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let first_iter = true

            let custom_tooltip

            for (let key in chart_data) {
              if (first_iter) {
                if (time_range_filter_value == 'WEEK') {
                  custom_tooltip =
                    '<div class="tooltip_report_charts_div"><div class="title_tooltip_report_charts_div">' +
                    days_of_week[chart_data[key][dataPointIndex].day_of_week] +
                    '  ' +
                    chart_data[key][dataPointIndex].label +
                    '</div><table><thead><th><th></thead><tbody>'
                } else {
                  custom_tooltip =
                    '<div class="tooltip_report_charts_div"><div class="title_tooltip_report_charts_div">' +
                    chart_data[key][dataPointIndex].label +
                    '</div><table><thead><th><th></thead><tbody>'
                }
              }
              let id = parseInt(key)

              for (let car of metric_object.cars) {
                if (car.id == id) {
                  let color = $(
                    '.car_number_item_charts[data_type=' +
                      metric_object.type +
                      '][data_id=' +
                      car.id +
                      ']'
                  ).css('color')
                  custom_tooltip =
                    custom_tooltip +
                    '<tr class="row_tooltip_report_charts_div"><td class="number_tooltip_report_charts_div" style="color: ' +
                    color +
                    '">' +
                    car.car_number +
                    '</td><td class="data_tooltip_report_charts_div" style="color: ' +
                    color +
                    '">' +
                    Math.round(
                      parseFloat(chart_data[key][dataPointIndex].value)
                    ) +
                    metric_object.units +
                    '</td></tr>'
                }
              }

              first_iter = false
            }

            // for (let car of state.series){
            //     custom_tooltip = custom_tooltip + '<tr class="row_tooltip_report_charts_div"><td class="number_tooltip_report_charts_div">' + car.name + '</td><td class="data_tooltip_report_charts_div">' + car.data[dataPointIndex] + metric_object.units + '</td></tr>'
            // }

            custom_tooltip = custom_tooltip + '</tbody></table></div>'

            return custom_tooltip
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: [],
          tooltip: {
            enabled: false,
          },
          // labels: {
          //     show: true,
          //     rotate: -45,
          // }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return Math.round(parseFloat(value)) + metric_object.units
            },
          },
        },
        colors: [],
      },
      series: [],
    }

    let days_of_week = [
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
      'Воскресенье',
    ]

    let day_of_week_current = -1
    let first_iter = true
    if (time_range_filter_value == 'WEEK') {
      for (let key in chart_data) {
        let obj = {}
        obj.data = []

        for (let car of metric_object.cars) {
          if (car.id == parseFloat(key)) {
            obj.name = car.car_number
          }
        }

        for (let piece of chart_data[key]) {
          if (first_iter) {
            if (piece.day_of_week != day_of_week_current) {
              new_state.options.xaxis.categories.push(
                days_of_week[piece.day_of_week]
              )
              day_of_week_current = piece.day_of_week
            } else {
              new_state.options.xaxis.categories.push('')
            }
          }
          obj.data.push(Math.round(parseFloat(piece.value)))
        }

        new_state.series.push(obj)

        first_iter = false
      }
    } else {
      for (let key in chart_data) {
        let obj = {}
        obj.data = []

        for (let car of metric_object.cars) {
          if (car.id == parseFloat(key)) {
            obj.name = car.car_number
          }
        }

        for (let piece of chart_data[key]) {
          if (first_iter) {
            new_state.options.xaxis.categories.push(piece.label)
          }
          obj.data.push(Math.round(parseFloat(piece.value)))
        }

        new_state.series.push(obj)

        first_iter = false
      }
    }

    for (let key in chart_data) {
      let id = parseInt(key)

      for (let car of metric_object.cars) {
        if (car.id == id) {
          new_state.options.colors.push(car.text_color)
        }
      }
    }

    return new_state
  }

  function get_colors() {
    let current_color = 0
    let current_pos = 0

    for (let car of metric_object.cars) {
      if (current_color >= colors.length - 1) {
        current_color = 0
      }

      if (current_pos % 2 == 0) {
        car.backgroud_color = '#EFF3FF'
      } else {
        car.backgroud_color = '#FFFFFF'
      }

      if (checked_cars.includes(car.id)) {
        car.text_color = colors[current_color]
        current_color++
      } else {
        car.text_color = '#91A4D2'
      }
      current_pos++
    }
  }

  function checkbox_change(e) {
    let checkbox_id_change = e.currentTarget.getAttribute('data_id')

    console.log('metric_object : ', metric_object)

    let new_checked_cars = checked_cars

    if (new_checked_cars.includes(parseInt(checkbox_id_change))) {
      let pos = 0
      for (let id of new_checked_cars) {
        if (id == parseInt(checkbox_id_change)) {
          new_checked_cars.splice(pos, 1)
        }
        pos++
      }
    } else {
      new_checked_cars.push(parseInt(checkbox_id_change))
    }

    setChecked_cars(new_checked_cars)
    setRender(new Date())

    reload_chart()
  }

  function check_checked(id) {
    // console.log('checked_cars : ', checked_cars)
    if (checked_cars.includes(id)) {
      return true
    } else {
      return false
    }
  }

  async function reload_chart() {
    if (checked_cars.length == 0) {
      setChart_data([])
    } else {
      let response = await axios_api.get_stats_chart(
        checked_cars,
        time_range_filter_value,
        metric_object.type,
        cookies.csrftoken
      )

      let data = response.data
      let min_size
      let first_iter = true

      for (let key in data) {
        if (data[key] != null) {
          if (first_iter) {
            min_size = data[key].length
          } else {
            if (min_size > data[key].length) {
              min_size = data[key].length
            }
          }
          first_iter = false
        } else {
          delete data[key]
        }
      }

      for (let key in data) {
        data[key] = data[key].slice(0, min_size)
      }
      // console.log('min_size : ', min_size)
      // console.log('data : ', data)

      setChart_data(data)
    }
  }
}
