import React, { useEffect, useState, useContext } from 'react';

import '../../styles/dashboard/charts.css'

import Chart_fuel from './chart_fuel.jsx'
import Chart_activity from './chart_activity.jsx'

export default function Сharts_card(props) {

    const [chart_type, setChart_type] = useState('activity')

    return (
        <div className='main_div_charts_card_dashboard'>
            <div className={'btns_div_charts_card_dashboard active'}>
                <div className={chart_type == 'activity' ? 'div_activity_type_chart_dashboard active' : 'div_activity_type_chart_dashboard'} onClick={(e) =>setChart_type('activity')}>
                    График по количеству активных машин
                </div>
                {/* <div className={chart_type == 'fuel' ? 'div_fuel_type_chart_dashboard active' : 'div_fuel_type_chart_dashboard'} onClick={(e) => setChart_type('fuel')}>
                    График суммарного расхода топлива
                </div> */}
            </div>
            <div className={'div_charts_card_dashboard'}>
                <div className={chart_type == 'fuel' ? 'div_chart_fuel active' : 'div_chart_fuel'}>
                    <Chart_fuel/>
                </div>
                <div className={chart_type == 'activity' ? 'div_chart_activity active' : 'div_chart_activity'}>
                    <Chart_activity group_sort_id={props.group_sort_id}/>
                </div>

            </div>
            
        </div>
    )
}