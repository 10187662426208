import React, { useEffect, useState, useContext } from 'react';

import '../../../styles/park_settings/main_park_settings.css'

import { useCookies } from 'react-cookie';
import axios_api from "../../../API/axios_api"
import map_api from "../../../API/map_api"
import { MapContext } from '../../../context/index';
import { ParkSettingsContext } from '../../../context/index';


import Btn_arrow_for_settings from '../btn_arrow_for_settings.jsx'

import arrow_right_blue_icon from '../../../styles/icons/arrow_right_blue_icon.svg';
import arrow_right_white_icon from '../../../styles/icons/arrow_right_white_icon.svg';

import robot_icon from '../../../styles/icons/robot_icon.svg';


export default function Table_item_users(props) {
    let {park_settings_object} = useContext(ParkSettingsContext)
    const [cookies] = useCookies(['csrftoken']);

    let user = props.user

    if (!user.first_name){
        user.first_name = '-'
    }
    if (!user.last_name){
        user.last_name = '-'
    }
    if (!user.middle_name){
        user.middle_name = '-'
    }

    const [active, setActive] = useState(false)

    function row_click(e){
        let id = e.currentTarget.getAttribute('data_id')
        props.row_click(id)
    }

    return (
        <div className=''>                                        
            <div className='table_row_users_settings' data_id={user.id} onMouseEnter={(e) => setActive(true)} onMouseLeave={(e) => setActive(false)} onClick={(e) => row_click(e)}>
                <div className='td_name_users_settings'>
                    <img src={robot_icon}/>
                    <div style={{'marginLeft': '15px'}}>
                        <div className='user_name_settings'>
                            {user.last_name} {user.first_name} {user.middle_name} 
                        </div>
                        <div className='user_type_settings'>
                            {user.user_type_description}
                        </div>
                    </div>
                </div>
                <div className='td_mail_users_settings'>
                    {user.email}
                </div>
                <div className='td_groups_users_settings'>
                    {(user.car_groups.length != 0 || (user.permissions.includes('GROUPS')))
                        ?
                        <div className='div_td_groups_users_settings success'>
                            Да
                        </div>
                        :
                        <div className='div_td_groups_users_settings error'>
                            Нет
                        </div>
                    }

                </div>
                <div className='td_arrows_users_settings'>
                    <Btn_arrow_for_settings active={active} color={'#EFF3FF'} active_color={'#6486FB'} not_active_icon={arrow_right_blue_icon} active_icon={arrow_right_white_icon} height={'36px'} width={'36px'}/>
                </div>
            </div>
            <div className='div_border_bottom_users_settings'>
                <div className='border_bottom_users_settings'></div>
            </div>
        </div>
    )
}