import React, { useEffect, useState } from 'react';
import '../../../styles/routes_map/routes.css'

import rectangl from '../../../styles/icons/rectangl_icon_routes.svg'

export default function Navbar_routes_item(props) {
    
    let class_name
    let class_icon
    let class_rectangl
    let class_rectangl_hiden

    //console.log(props.current_item, props.item)

    if (props.current_item == props.item){
        class_name = 'navbar_routes_item_active'
        class_icon = 'navbar_routes_icon_active'

        class_rectangl = 'navbar_routes_rectangl_active'
        class_rectangl_hiden = 'navbar_routes_rectangl_hiden_active'

    } else {
        class_name = 'navbar_routes_item_normal'
        class_icon = 'navbar_routes_icon_normal'

        class_rectangl = 'navbar_routes_rectangl_normal'
        class_rectangl_hiden = 'navbar_routes_rectangl_hiden_normal'
    }

    function change_item(){
        props.onClick()
    }

    return (
        <div className={class_name} onClick={change_item}>
            <div className='div_top_navbar_routes_item_rectangl'></div>
            
            <div>
                <img className={class_icon} src={props.img}/>
            </div>

            <img className={class_rectangl} src={rectangl}/>
            <div className={class_rectangl_hiden}></div>
        </div>
    )
}