/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react'

import '../../styles/report/main_report.css'

import { useCookies } from 'react-cookie'
import ReactLoading from 'react-loading'
import axios_api from '../../API/axios_api'
import remove_icon from '../../styles/icons/remove_icon.svg'
import download_icon from '../../styles/icons/download_icon.svg'
import Checkbox_with_text from '../UI/checkbox_with_text.jsx'
import Btn_defolt from '../UI/btn_defolt'

import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

export default function Create_report(props) {
  const [cookies] = useCookies(['csrftoken'])

  const [error_metrics, setError_metrics] = useState(false)

  let cars_for_report_detailed = []

  for (let car of props.cars_for_report) {
    for (let car_detailed of props.cars) {
      if (car_detailed.id == car) {
        cars_for_report_detailed.push(car_detailed)
      }
    }
  }

  return (
    <div className="div_small_create_report">
      {cars_for_report_detailed.length ? (
        <>
          <div className="text_car_list">Формирование отчета для:</div>
          <div className="div_cars_for_report">
            {cars_for_report_detailed.map((car) => (
              <div key={car.id} className={'car_for_report'}>
                <div style={{ display: 'flex' }}>
                  <div className="car_number_car_for_report">
                    {car.car_number}
                  </div>
                  <div className="car_brand_car_for_report">{car.brand}</div>
                </div>
                <div style={{ marginRight: '5px' }}>
                  <img
                    src={remove_icon}
                    style={{ cursor: 'pointer' }}
                    data_id={car.id}
                    onClick={(e) => car_remove(e)}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="text_car_list" style={{ marginTop: '32px' }}>
            Выберите метрики для формирования отчета:
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '24px' }}>
            {props.all_metrics === null ? (
              <div
                style={{
                  height: '50px',
                  width: '300px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ReactLoading type={'spin'} color="#6486FB" />
              </div>
            ) : props.all_metrics.length === 0 ? (
              <div
                style={{
                  width: '250px',
                  borderBottom: '1px solid #EFF2F5',
                  height: '42px',
                  alignItems: 'center',
                  display: 'flex',
                  marginRight: '24px',
                }}
                className="Checkbox_with_text_metric"
              >
                Метрики отсутствуют
              </div>
            ) : (
              <>
                {props.all_metrics.map((metric) => (
                  <div
                    key={metric.type}
                    style={{
                      width: '250px',
                      borderBottom: '1px solid #EFF2F5',
                      height: '42px',
                      alignItems: 'center',
                      display: 'flex',
                      marginRight: '24px',
                    }}
                    className="Checkbox_with_text_metric"
                  >
                    <Checkbox_with_text
                      checked={check_checked(metric.type)}
                      text={metric.name}
                      type={metric.type}
                      onChange={change_checked}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
          <div style={{ marginTop: '30px' }}>
            <Btn_defolt
              color={'#6486FB'}
              text_color={'#FFFFFF'}
              hover_color={'#5179fc'}
              text={'Сформировать отчет'}
              height={'40px'}
              width={'300px'}
              onClick={create_report}
            />
          </div>
          <SlideDown className={'my-dropdown-slidedown'}>
            {error_metrics ? (
              <div className="error_metrics">
                Выберите хотя бы одну метрику для формирования отчета
              </div>
            ) : (
              <></>
            )}
          </SlideDown>
          <SlideDown className={'my-dropdown-slidedown'}>
            {props.report_ready ? (
              <div className="" style={{ width: '100%', paddingTop: '35px' }}>
                <div className="hr_report"></div>
                <div className="report_ready_div">
                  <div className="report_ready_text">Отчет сформирован</div>
                  <div className="report_save_div" onClick={get_report}>
                    <div className="report_save_text">Скачать таблицу</div>
                    <div className="report_save_icon">
                      <img src={download_icon} />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </SlideDown>
        </>
      ) : (
        <div className="text_car_list">
          Выберите хотя бы одно авто для фомирования отчета
        </div>
      )}
    </div>
  )

  function car_remove(e) {
    let count = 0
    let id = e.currentTarget.getAttribute('data_id')

    for (let car_id_in_array of props.cars_for_report) {
      if (car_id_in_array == id) {
        break
      }
      count = count + 1
    }
    props.cars_for_report.splice(count, 1)
    props.setCars_for_report(props.cars_for_report)
    props.setTime(new Date())
  }

  async function get_report() {
    let get_report_response = await axios_api.get_report(
      props.cars_for_report,
      props.checked_metrics,
      cookies.csrftoken
    )
  }

  function create_report() {
    if (props.checked_metrics.length == 0) {
      setError_metrics(true)
      setTimeout(() => {
        setError_metrics(false)
      }, 5000)
    } else {
      props.setReport_ready(true)
    }
  }

  function check_checked(type) {
    if (props.checked_metrics.includes(type)) {
      return true
    } else {
      return false
    }
  }

  function change_checked(checked, e) {
    let type = e.currentTarget.getAttribute('data_type')

    let pos = 0

    if (props.checked_metrics.includes(type)) {
      for (let metric of props.checked_metrics) {
        if (metric == type) {
          props.checked_metrics.splice(pos, 1)
        }
        pos++
      }
    } else {
      props.checked_metrics.push(type)
    }

    props.setChecked_metrics(props.checked_metrics)
    props.setTime(new Date())

    // for (metric of checked_metrics){
    //     if (metric == type){
    //         find = true

    //     }
    //     pos++
    // }

    console.log('checked_metrics : ', props.checked_metrics)
  }
}
