import React, { useEffect, useState } from 'react';
import '../../styles/components/components.css'



export default function Checkbox_with_text(props) {


    return (
        <div className='div_checkbox_with_text'>
            <input className='form-check-input' type='checkbox' checked={props.checked} data_type={props.type} onChange={(e) => props.onChange(!props.checked, e)}/>
            <div className={props.checked ? "text_checkbox_with_text active" : "text_checkbox_with_text"}>
                {props.text}
            </div>
        </div>
    )
}