/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react'

import '../../styles/car_list/car_list_main.css'

import ReactLoading from 'react-loading'
import Search_input from '../UI/search_input.jsx'
import plus_white from '../../styles/icons/plus_white.svg'
import Icon_btn from '../UI/icon_btn'
import Basic_select from '../UI/basic_select.jsx'
import Groups_cars_filter from '../UI/groups_cars_filter.jsx'
import SimpleBar from 'simplebar-react'
import car_data from '../../API/car_data'
import { useSelector } from 'react-redux'
import CarMobileAddModal from './CarMobile/CarMobileAddModal.jsx'

const filterTOvalues = [
  ['Все статусы ТО', 'ALL'],
  ['Не настроено', 'NOT_CONFIGURED'],
  ['Пройдено', 'PASSED'],
  ['Требуется', 'NEED'],
  ['Пропущено', 'SKIPPED'],
  ['Запланировано', 'FUTURE'],
  ['Не требуется', 'NO_NEED'],
]

export default function Car_list(props) {
  const window_height = useSelector((state) => state.window_height)
  const simplebar_height = window_height - 70 - 24 - 24 - 24 - 24 - 21 - 60 - 55 - 28
  const user_current = useSelector((state) => state.user_current)

  let searched_array = []
  //groups_filter
  const [group_sort_id, setGroup_sort_id] = useState('ALL')
  //end groups_filter

  //
  const [search, setSearch] = useState(null)
  //

  const [filterTOvalue, setFilterTOvalue] = useState('ALL')

  const [addMobileCarModalActive, setAddMobileCarModalActive] = useState(false)

  let cars = []

  if (props.cars) {
    cars = props.cars
  }

  cars.map((car) => {
    if (car.car_number == null) {
      car.car_number = '-'
    }
    if (car.brand == null) {
      car.brand = '-'
    }
    if (car.model == null) {
      car.model = '-'
    }
    if (car.phone_number == null) {
      car.phone_number = '-'
    }
    if (car.mileage == null) {
      car.mileage = '-'
    }
    if (car.daily_mileage == null) {
      car.daily_mileage = '-'
    }
    if (!car.fuel_consumption == null) {
      car.fuel_consumption = '-'
    }
    if (!search) {
      if (
        (group_sort_id == 'ALL' || group_sort_id == car.car_group) &&
        (filterTOvalue == 'ALL' || filterTOvalue == car.inspections_state)
      ) {
        searched_array.push(car)
      }
    } else {
      if (car.car_number && car.car_number.toUpperCase().includes(search.toUpperCase())) {
        if (
          (group_sort_id == 'ALL' || group_sort_id == car.car_group) &&
          (filterTOvalue == 'ALL' || filterTOvalue == car.inspections_state)
        ) {
          searched_array.push(car)
        }
      }
    }
  })

  function car_click(e) {
    let id = e.currentTarget.getAttribute('data_id')
    props.click_car(id)
  }

  const makeTOClass = (inspections_state) => {
    switch (inspections_state) {
      case 'NOT_CONFIGURED':
        return 'Не настроено'
      case 'PASSED':
        return 'Пройдено'
      case 'NEED':
        return 'Требуется'
      case 'SKIPPED':
        return 'Пропущено'
      case 'FUTURE':
        return 'Запланировано'
      case 'NO_NEED':
        return 'Не требуется'
      default:
        return ''
    }
  }

  const onChangeFilterTO = (val) => {
    setFilterTOvalue(val)
  }

  return (
    <div className='' style={{ height: '100%' }}>
      <div className='text_car_list'>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
          <div>Список авто</div>
          <div>
            <Icon_btn
              color={'#6486FB'}
              hover_color={'#5179fc'}
              icon={plus_white}
              height={'36px'}
              width={'40px'}
              onClick={() => setAddMobileCarModalActive(true)}
            />
          </div>
        </div>
      </div>
      {props.cars && props.groups ? (
        <>
          <div style={{ marginTop: '24px', display: 'flex' }}>
            <div style={{ width: '340px' }}>
              <Groups_cars_filter
                groups={props.groups}
                cars={props.cars}
                group_sort_id={group_sort_id}
                setGroup_sort_id={setGroup_sort_id}
                text_all={'Выбрать группу'}
              />
            </div>
            <div style={{ marginLeft: '24px', display: 'flex' }}>
              <Basic_select
                current_value={filterTOvalue}
                all_values={filterTOvalues}
                setValue={onChangeFilterTO}
                status={'active'}
                width={'250px'}
                height={'36px'}
              />
            </div>{' '}
          </div>
          <div style={{ marginTop: '20px' }}>
            <Search_input placeholder={'Введите  гос. номер'} change={setSearch} />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
              paddingLeft: '16px',
              paddingRight: '16px',
            }}
          >
            <div className='thead_table_car_list' style={{ width: '47px' }}></div>
            <div className='thead_table_car_list' style={{ width: '120px' }}>
              Номер и марка
            </div>
            <div className='thead_table_car_list' style={{ width: '100px' }}>
              Пробег
            </div>
            <div className='thead_table_car_list' style={{ width: '100px' }}>
              Пробег сут.
            </div>
            {user_current && user_current.balance_blocking && (
              <div className='thead_table_car_list' style={{ width: '20px' }}>
                Агр.
              </div>
            )}
            <div className='thead_table_car_list' style={{ width: '132px' }}>
              ТО
            </div>
          </div>
          <SimpleBar style={{ maxHeight: simplebar_height }}>
            {searched_array.map((car) => (
              <div key={car.id}>
                <div
                  className={
                    props.car_profile_id == car.id
                      ? 'row_table_car_list active'
                      : 'row_table_car_list'
                  }
                  key={car.id}
                  data_id={car.id}
                  onClick={(e) => car_click(e)}
                >
                  <div className='td_car_icon_table_car_list'>
                    <img src={car_data.get_svg_car(car.color)} style={{ height: '25px' }} alt='' />
                  </div>
                  <div className='td_car_number_table_car_list'>
                    <div className='table_car_list_number'>{car.car_number}</div>
                    <div className='table_car_list_brand'>
                      {!car.is_mobile
                        ? `${car.brand} ${car.model}`
                        : car.mobile_driver.fio || 'ФИО отсутствует'}
                    </div>
                  </div>
                  <div className='td_car_mil_table_car_list'>
                    {Math.round(parseFloat(car.mileage))} км.
                  </div>
                  <div className='td_car_day_mil_table_car_list'>
                    {Math.round(parseFloat(car.daily_mileage))} км.
                  </div>
                  {user_current && user_current.balance_blocking && (
                    <div className='td_car_agg_table_car_list'>{car.aggregator ? '+' : ''}</div>
                  )}
                  <div className='td_TO_car_list'>
                    <div className={'div_TO_car_list ' + car.inspections_state}>
                      {makeTOClass(car.inspections_state)}
                    </div>
                  </div>
                </div>
                <div className='div_border_bottom_table_car_list'>
                  <div className='border_bottom_table_car_list'></div>
                </div>
              </div>
            ))}
          </SimpleBar>
        </>
      ) : (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ReactLoading type={'spin'} color='#6486FB' />
        </div>
      )}
      <CarMobileAddModal
        get_cars_detailed={props.get_cars_detailed}
        get_cars_brands_and_models={props.get_cars_brands_and_models}
        active={addMobileCarModalActive}
        setActive={setAddMobileCarModalActive}
      />
    </div>
  )
}
