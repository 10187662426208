import React from "react";
import '../../../styles/routes_map/routes.css'

const Modal_mask_navbar = ({active, children}) => {

    return (
        <div className={active ? "modal_mask_navbar active" : "modal_mask_navbar"}>
            <div className="">
                {children}
            </div>
        </div>
    );

}

export default Modal_mask_navbar;