/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react'
import Btn_arrow_for_settings from '../btn_arrow_for_settings.jsx'
import '../../../styles/park_settings/main_park_settings.css'
import Basic_input from '../../UI/basic_input.jsx'
import List_input from '../../UI/list_input.jsx'
import Modal_with_accept from '../../UI/modal_with_accept.jsx'
import arrow_left_white_icon from '../../../styles/icons/arrow_left_white_icon.svg'
import arrow_left_blue_icon from '../../../styles/icons/arrow_left_blue_icon.svg'
import Btn_defolt from '../../UI/btn_defolt.jsx'
import Fake_btn_defolt from '../../UI/fake_btn_defolt.jsx'
import axios_api from '../../../API/axios_api'
import Params from './params/params.jsx'
import { useCookies } from 'react-cookie'

export default function Inspection(props) {
  const [cookies] = useCookies(['csrftoken'])

  const inspectionId = props.inspection ? props.inspection.id : null
  const inspectionName = props.inspection ? props.inspection.verbose_name : null
  const inspectionBrand = props.inspection ? props.inspection.brand : null
  const inspectionModel = props.inspection ? props.inspection.model : null

  const [btn_back_active, setBtn_back_active] = useState(false)
  const [nameValue, setNameValue] = useState(inspectionId ? inspectionName : '')
  const [brandValue, setBrandValue] = useState(
    inspectionId ? inspectionBrand : ''
  )
  const [modelValue, setModelValue] = useState(
    inspectionId ? inspectionModel : ''
  )

  const [nameStatus, setNameStatus] = useState('active')
  const [brandStatus, setBrandStatus] = useState('active')
  const [modelStatus, setModelStatus] = useState('active')

  const [saveStatus, setSaveStatus] = useState(null)
  const [deleteStatus, setDeleteStatus] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)

  const [params, setParams] = useState([])
  const [render, setRender] = useState(0)

  const go_back_to_list = () => {
    props.setPage('list')
  }

  const saveInspection = () => {
    let error = false

    if (!nameValue) {
      setNameStatus('error')
      setSaveStatus('error')
      setTimeout(() => {
        setNameStatus('active')
      }, 4000)
      error = true
    }

    if (!brandValue) {
      setBrandStatus('error')
      setSaveStatus('error')
      setTimeout(() => {
        setBrandStatus('active')
      }, 4000)
      error = true
    }

    if (!modelValue) {
      setModelStatus('error')
      setSaveStatus('error')
      setTimeout(() => {
        setModelStatus('active')
      }, 4000)
      error = true
    }

    for (let item of params) {
      if (!item.verbose_name) {
        item.error = 'verbose_name'
        setSaveStatus('error')
        setParams(params)
        setRender(new Date().getTime())
        setTimeout(() => {
          item.error = ''
        }, 4000)
        error = true
      }

      if (
        (item.period_type !== 'MILEAGE_RANGES' &&
          !item.value_period &&
          item.status !== 'delete') ||
        !parseInt(item.value_period) ||
        /[a-zа-яё]/i.test(item.value_period)
      ) {
        item.error = 'value_period'
        setSaveStatus('error')
        setParams(params)
        setRender(new Date().getTime())
        setTimeout(() => {
          item.error = ''
        }, 4000)
        error = true
      }

      if (
        (item.period_type === 'MILEAGE_RANGES' &&
          (!item.mileage_ranges[0] ||
            !item.mileage_ranges[1] ||
            !item.mileage_ranges[2] ||
            !item.mileage_ranges[3]) &&
          item.status !== 'delete') ||
        !parseInt(item.mileage_ranges[0]) ||
        /[a-zа-яё]/i.test(item.mileage_ranges[0]) ||
        !parseInt(item.mileage_ranges[1]) ||
        /[a-zа-яё]/i.test(item.mileage_ranges[1]) ||
        !parseInt(item.mileage_ranges[2]) ||
        /[a-zа-яё]/i.test(item.mileage_ranges[2]) ||
        !parseInt(item.mileage_ranges[3]) ||
        /[a-zа-яё]/i.test(item.mileage_ranges[3])
      ) {
        if (
          !item.mileage_ranges[0] ||
          !parseInt(item.mileage_ranges[0]) ||
          /[a-zа-яё]/i.test(item.mileage_ranges[0])
        ) {
          item.error = 'mileage_ranges1'
        }
        if (
          !item.mileage_ranges[1] ||
          !parseInt(item.mileage_ranges[1]) ||
          /[a-zа-яё]/i.test(item.mileage_ranges[1])
        ) {
          item.error = 'mileage_ranges2'
        }
        if (
          !item.mileage_ranges[2] ||
          !parseInt(item.mileage_ranges[2]) ||
          /[a-zа-яё]/i.test(item.mileage_ranges[2])
        ) {
          item.error = 'mileage_ranges3'
        }
        if (
          !item.mileage_ranges[3] ||
          !parseInt(item.mileage_ranges[3]) ||
          /[a-zа-яё]/i.test(item.mileage_ranges[3])
        ) {
          item.error = 'mileage_ranges4'
        }
        setSaveStatus('error')
        setParams(params)
        setRender(new Date().getTime())
        setTimeout(() => {
          item.error = ''
        }, 3000)
        error = true
      }
    }
    if (error) {
      setSaveStatus('error')
      setTimeout(() => {
        setSaveStatus(null)
        setParams(params)
        setRender(new Date().getTime())
      }, 3000)
    } else {
      saveInspectionAccept()
    }
  }

  const saveInspectionAccept = async () => {
    let inspectionIdNew = inspectionId

    let paramsIds = []
    for (let item of params) {
      if (item.status !== 'new') {
        paramsIds.push(item.id)
      }
    }

    let responseSaveInspection
    if (inspectionIdNew) {
      responseSaveInspection = await axios_api.saveInspection(
        inspectionId,
        nameValue,
        brandValue,
        modelValue,
        paramsIds,
        cookies.csrftoken
      )
    } else {
      responseSaveInspection = await axios_api.postInspection(
        nameValue,
        brandValue,
        modelValue,
        paramsIds,
        cookies.csrftoken
      )
      if (responseSaveInspection.status === 201) {
        inspectionIdNew = responseSaveInspection.data.id
      }
    }

    if (
      responseSaveInspection.status === 200 ||
      responseSaveInspection.status === 201
    ) {
      let error = false

      for (let item of params) {
        if (item.status !== 'new' && item.edited) {
          let responseInspectionParam = await axios_api.saveInspectionParam(
            inspectionIdNew,
            item.id,
            item.verbose_name,
            item.period_type,
            item.value_period,
            item.mileage_ranges,
            cookies.csrftoken
          )
          if (responseInspectionParam.status !== 200) {
            error = true
            break
          }
        }
        if (item.status === 'new') {
          let responseInspectionParam = await axios_api.postInspectionParam(
            inspectionIdNew,
            item.verbose_name,
            item.period_type,
            item.value_period,
            item.mileage_ranges,
            cookies.csrftoken
          )
          if (responseInspectionParam.status !== 201) {
            error = true
            break
          }
        }
        if (item.status === 'delete') {
          let responseInspectionParam = await axios_api.deleteInspectionParam(
            item.id,
            cookies.csrftoken
          )
          if (responseInspectionParam.status !== 204) {
            error = true
            break
          }
        }
      }
      if (!error) {
        setSaveStatus('success')
        props.setInspection(responseSaveInspection.data)
        props.update_all()
      } else {
        setSaveStatus('error')
      }
      if (responseSaveInspection.status === 201) {
        setTimeout(() => {
          props.setPage('list')
        }, 1000)
      }
      setTimeout(() => {
        setSaveStatus(null)
      }, 4000)
    } else {
      setSaveStatus('error')

      setTimeout(() => {
        setSaveStatus(null)
      }, 4000)
    }
  }

  const deleteInspection = () => {
    setDeleteModal(true)
  }

  const deleteInspectionAccept = async () => {
    let responseInspectionParam = await axios_api.deleteInspection(
      inspectionId,
      cookies.csrftoken
    )
    if (responseInspectionParam.status === 204) {
      setDeleteStatus('success')
    } else {
      setDeleteStatus('error')
    }
    setTimeout(() => {
      setDeleteStatus(null)
      if (responseInspectionParam.status === 204) {
        props.update_all()
        props.setPage('list')
      }
    }, 1000)
  }

  return (
    <div className="div_main_setting">
      <div className="header_group_edit_settings">
        <div
          onMouseEnter={(e) => setBtn_back_active(true)}
          onMouseLeave={(e) => setBtn_back_active(false)}
          onClick={go_back_to_list}
          style={{ cursor: 'pointer' }}
        >
          <Btn_arrow_for_settings
            active={btn_back_active}
            color={'#EFF3FF'}
            active_color={'#6486FB'}
            not_active_icon={arrow_left_blue_icon}
            active_icon={arrow_left_white_icon}
            height={'36px'}
            width={'36px'}
          />
        </div>
        <div className="text_header_group_edit_settings">
          {inspectionId
            ? `Редактирование плана ТО '${inspectionName}'`
            : 'Новая настройка тех. обслуживания'}
        </div>
      </div>
      <div className="body_inspection_settings">
        <div>
          <Basic_input
            value={nameValue}
            text={'Название плана ТО'}
            status={nameStatus}
            width={'100%'}
            type={'text'}
            onChange={setNameValue}
          />
        </div>
        <div
          style={{
            marginTop: '24px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <List_input
            value={brandValue}
            text={'Марка автомобиля'}
            status={brandStatus}
            width={'366px'}
            type={'text'}
            onChange={setBrandValue}
            source={props.cars_brands}
          />
          <List_input
            value={modelValue}
            text={'Модель автомобиля'}
            status={modelStatus}
            width={'366px'}
            type={'text'}
            onChange={setModelValue}
            source={props.cars_models}
          />
        </div>
        <div
          style={{
            marginTop: '32px',
          }}
        >
          <Params
            inspection={props.inspection}
            params={params}
            setParams={setParams}
            render={render}
            setRender={setRender}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '32px' }}>
          <div>
            {saveStatus ? (
              <>
                {saveStatus === 'success' ? (
                  <Fake_btn_defolt
                    color={'#0acf97'}
                    text_color={'#FFFFFF'}
                    hover_color={'#0acf97'}
                    text={
                      inspectionId !== null ? 'Сохранено!' : 'План ТО добавлен'
                    }
                    height={'40px'}
                    width={'250px'}
                  />
                ) : (
                  <Fake_btn_defolt
                    color={'#fa5c7c'}
                    text_color={'#FFFFFF'}
                    hover_color={'#fa5c7c'}
                    text={'Ошибка!'}
                    height={'40px'}
                    width={'250px'}
                  />
                )}
              </>
            ) : (
              <Btn_defolt
                color={'#0acf97'}
                text_color={'#FFFFFF'}
                hover_color={'#07b584'}
                text={
                  inspectionId !== null
                    ? 'Сохранить изменения'
                    : 'Добавить план ТО'
                }
                height={'40px'}
                width={'250px'}
                onClick={saveInspection}
              />
            )}
          </div>
          {inspectionId !== null ? (
            <div style={{ marginLeft: '24px' }}>
              <Btn_defolt
                color={'#fa5c7c'}
                text_color={'#FFFFFF'}
                hover_color={'#fa4166'}
                text={'Удалить план ТО'}
                height={'40px'}
                width={'250px'}
                onClick={deleteInspection}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Modal_with_accept active={deleteModal} setActive={setDeleteModal}>
        <div className="text_accept_delete">
          Вы уверены, что хотите удалить план ТО "{nameValue}"?
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '30px',
          }}
        >
          <Btn_defolt
            color={'#EFF3FF'}
            text_color={'#6486FB'}
            hover_color={'#dbe4ff'}
            text={'Отмена'}
            height={'40px'}
            width={'150px'}
            onClick={(e) => setDeleteModal(false)}
          />
          <Btn_defolt
            color={'#6486FB'}
            text_color={'#FFFFFF'}
            hover_color={'#5179fc'}
            text={'Удалить'}
            height={'40px'}
            width={'150px'}
            onClick={deleteInspectionAccept}
          />
        </div>
        <div
          className={
            deleteStatus === 'success'
              ? 'status_modal_success active'
              : 'status_modal_success'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="text_status_main">Успешно!</div>
            <div className="text_status_info">План ТО удален!</div>
          </div>
        </div>
        <div
          className={
            deleteStatus === 'error'
              ? 'status_modal_error active'
              : 'status_modal_error'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="text_status_main">Ошибка!</div>
            <div className="text_status_info">Не удалось удалить план ТО!</div>
          </div>
        </div>
      </Modal_with_accept>
    </div>
  )
}
