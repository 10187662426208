import React, { useEffect, useState, useContext } from 'react';
import SimpleBar from 'simplebar-react';
import Zone_current_card from './zone_current_card'
import { MapContext } from '../../../context/index';
import ReactLoading from "react-loading";
import '../../../styles/routes_map/zones_list.css'

import {useDispatch, useSelector} from 'react-redux'

export default function Zones_current(props) {
    const window_height = useSelector(state => state.window_height)
    const simplebar_height = window_height - 70 - 24 - 24 - 55 - 100

    let {map_object} = useContext(MapContext)

    // console.log('props.current_geozone : ', props.current_geozone)
    // console.log('props.cars : ', props.cars)

    return (
        <div style={{'marginTop': '20px'}}>
            <SimpleBar style={{ maxHeight: simplebar_height }}>
                <div style={{'padding': '12px', 'background': '#EFF3FF', 'borderRadius': '8px'}}>
                    {((props.current_geozones) && (props.cars))
                        ?
                        <>
                            {props.current_geozones.map((geozone) => 
                                <Zone_current_card key={geozone.id} geozone={geozone} cars={props.cars}/>
                            )}
                        </>
                        :   
                        <div style={{'height': '100%', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center'}}><ReactLoading type={'spin'} color="#6486FB" /></div>
                    }
                </div>
            </SimpleBar>
        </div>
    )
}