/* eslint-disable react/jsx-pascal-case */
import MapShare from '../components/share/MapShare.js'
import Header from '../components/share/Header'
import Panel from '../components/share/Panel.jsx'
import { MapContext } from '../context/index'
import React, { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import shareMapApi from '../API/share/shareMapApi.js'
import shareAxiosApi from '../API/share/shareAxiosApi.js'
import Basic_modal from '../components/UI/basic_modal'
import moment from 'moment'

const ShareMap = () => {
  document.title = 'Share | ControlGPS'
  let { map_object } = useContext(MapContext)
  const [searchParams] = useSearchParams()
  const key = searchParams.get('key')
  const [errorModal, setErrorModal] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [car, setCar] = useState()
  const [route, setRoute] = useState([])
  const [routeLoading, setRouteLoading] = useState(true)

  const checkIsMobile = () => {
    if (document.documentElement.clientWidth > 1024) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }

  const getCarInfo = async () => {
    try {
      let getCarDataResp = await shareAxiosApi.getCarData(key)
      const carData = getCarDataResp.data[0]
      setCar(carData)
      console.log('reloadedCar : ', carData)
      const carLocs = carData.locations
      if (carData && carLocs && carLocs.length !== 0)
        shareMapApi.setCarMarkerLoc(carData, carLocs[0].latitude, carLocs[0].longitude, map_object)
    } catch (error) {
      setErrorModal(true)
    }

    try {
      let getCarRouteResp = await shareAxiosApi.getCarRoute(
        key,
        moment().format('DD.MM.YYYY'),
        moment().format('DD.MM.YYYY'),
      )
      if (getCarRouteResp.data) {
        setRoute(getCarRouteResp.data)
        shareMapApi.drawCarRoute(getCarRouteResp.data, map_object)
      } else {
        shareMapApi.drawCarRoute([], map_object)
        setRoute([])
      }
      setRouteLoading(false)
    } catch (error) {
      setRoute([])
      shareMapApi.drawCarRoute([], map_object)
      setRouteLoading(false)
    }
  }

  useEffect(() => {
    checkIsMobile()
    getCarInfo()
    setInterval(() => {
      getCarInfo()
    }, 30000)
    map_object.stopMarkersShare = []
    map_object.linesShare = []
  }, [])

  return (
    <>
      <Header isMobile={isMobile} />
      <MapShare />
      {car ? (
        <Panel car={car} route={route} routeLoading={routeLoading} isMobile={isMobile} />
      ) : (
        <></>
      )}
      <Basic_modal active={errorModal} setActive={() => {}}>
        <div className='text_accept_delete' style={{ textAlign: 'center', maxWidth: '550px' }}>
          Ссылка устарела, попросите владельца передать Вам актуальную ссылку для мониторинга авто
        </div>
      </Basic_modal>
    </>
  )
}

export default ShareMap
