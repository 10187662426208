import React, { useEffect, useState, useContext } from 'react';

import '../../styles/dashboard/notifications.css'
import SimpleBar from 'simplebar-react'

import Btn_defolt from '../UI/btn_defolt'
import Modal_with_accept from '../UI/modal_with_accept'
import Notification_card from './notification_card.jsx'

import { useCookies } from 'react-cookie';
import axios_api from "../../API/axios_api"

import {useDispatch, useSelector} from 'react-redux'

export default function Notifications(props) {
    const window_height = useSelector(state => state.window_height)
    const simplebar_height = window_height - 65

    const [cookies] = useCookies(['csrftoken']);

    const [modal_hide_notification_active, setActive_modal_hide_notification] = useState(false)
    const [status_modal_hide_notification, setStatus_modal_hide_notification] = useState('')

    const [notification_id, setNotification_id] = useState(null)
    const [notification_message, setNotification_message] = useState(null)
    const [notification_car_number, setNotification_car_number] = useState(null)

    let notifications = props.notifications

    if (!notifications){
        notifications = []
    }

    let notifications_types = []

    for (let notification of notifications){
        if (!notifications_types.includes(notification.type)){
            if ((!notification.car_group)||(notification.car_group == props.group_sort_id)||(props.group_sort_id == 'ALL')){
                notifications_types.push(notification.type)
            }
        }
    }

    let notifications_separated = []
    for (let notification_type of notifications_types){
        let type_obj = {}
        type_obj.type = notification_type
        type_obj.notifications = []
        for (let notification of notifications){
            if (notification_type == notification.type){
                if (!notification.car_number){
                    notification.car_number = 'НЕТНОМЕРА'
                }
                if ((!notification.car_group)||(notification.car_group == props.group_sort_id)||(props.group_sort_id == 'ALL')){
                    type_obj.notifications.push(notification)
                    type_obj.type_name = notification.type_name
                }
            }
        }
        notifications_separated.push(type_obj)
    }
    //console.log('notifications_separated : ', notifications_separated)

    return (
        <div className='main_div_notifications_dashboard'>
            <SimpleBar style={{ maxHeight: simplebar_height }}>
                {notifications_separated.map((group_of_notification) => 
                    <Notification_card key={group_of_notification.type} group={group_of_notification} hide_notification={hide_notification}/>
                )}
            </SimpleBar>
            <Modal_with_accept active={modal_hide_notification_active} setActive={setActive_modal_hide_notification}>
                <div className='text_accept_delete'>
                    Вы уверены, что хотите скрыть уведомление "{notification_message}" у авто "{notification_car_number}"?
                </div>
                <div style={{'display' : 'flex', 'justifyContent': 'space-between', 'marginTop': '30px'}}>
                    <Btn_defolt color={'#EFF3FF'} text_color={'#6486FB'} hover_color={'#dbe4ff'} text={'Отмена'} height={'40px'} width={'150px'} onClick={(e) => setActive_modal_hide_notification(false)}/>
                    <Btn_defolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Скрыть'} height={'40px'} width={'150px'} onClick={accept_hide_notification}/>
                </div>
                <div className={status_modal_hide_notification == 'success' ? "status_modal_success active" : "status_modal_success"}>
                    <div style={{'display': 'flex', 'flexDirection': 'column'}}>   
                        <div className='text_status_main'>
                            Успешно!
                        </div>
                        <div className='text_status_info'>
                            Уведомление скрыто!
                        </div>
                    </div>
                </div>
                <div className={status_modal_hide_notification == 'error' ? "status_modal_error active" : "status_modal_error"}>
                    <div style={{'display': 'flex', 'flexDirection': 'column'}}>
                        <div className='text_status_main'>
                            Ошибка!
                        </div>
                        <div className='text_status_info'>
                            Не удалось скрыть уведомление!
                        </div>
                    </div>
                </div>
            </Modal_with_accept>
        </div>

        
    )

    function hide_notification(e){
        setNotification_id(e.currentTarget.getAttribute('data_id'))
        setNotification_message(e.currentTarget.getAttribute('data_message'))
        setNotification_car_number(e.currentTarget.getAttribute('data_car_number'))

        setActive_modal_hide_notification(true)
    }

    async function accept_hide_notification(){
        let response = await axios_api.hide_notification(notification_id, cookies.csrftoken)

        if (response.status == '200'){
            setStatus_modal_hide_notification('success')
        } else {
            setStatus_modal_hide_notification('error')
        }

        setTimeout(
            function(){
                setActive_modal_hide_notification(false)
                setStatus_modal_hide_notification('')
            }, 1000
        );

        props.get_notifications()
    }
}