import { useState } from 'react'
import '../../../styles/park_settings/main_park_settings.css'
import List from './list'
import Inspection from './inspection'

export default function Inspections(props) {
  const [page, setPage] = useState('list')
  const [inspection, setInspection] = useState(null)

  if (page === 'list') {
    return (
      <List
        setPage={setPage}
        setInspection={setInspection}
        inspectionsGroups={props.inspectionsGroups}
        update_all={props.update_all}
        cars_delailed={props.cars_delailed}
      />
    )
  } else {
    return (
      <Inspection
        setPage={setPage}
        inspection={inspection}
        cars_models={props.cars_models}
        cars_brands={props.cars_brands}
        update_all={props.update_all}
        setInspection={setInspection}
      />
    )
  }
}
