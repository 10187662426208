import React, { useEffect, useState, useContext } from 'react';
import '../../../styles/routes_map/routes.css'
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Pieces_of_way_routes_info(props) {

    let style_text = {
        'color': props.color
    }

    let style_bg = {
        'background': 'rgba(' + props.bg_color.r + ', ' + props.bg_color.g + ', ' + props.bg_color.b + ', 0.1)'
    }

    let percent = (props.piece.distance*100/props.day_distance)
    //console.log('max_distance : ', props.max_distance)

    useEffect(() => {
        if (props.piece.type == 'move'){
            document.getElementsByClassName('progress-bar')[0].style.backgroundColor = 'props.color !important;'
        }
    }, []);

    let custom = 'custom_' +  props.color.split('#')[1]

    if (props.piece.type == 'move'){
        return (
            <div className='div_move_piece' style={style_text}>
                <div className='background_div_time' style={style_bg}>
                    {range_date_str(props.piece.start_dt, props.piece.end_dt)}
                </div>
                <div className='progress_bar'>
                    <style type="text/css">
                        {
                            '.bg-' + custom + ' {' + 
                                'background-color: ' + props.color + ';' +
                                'color: white;' +
                            '}'
                        }
                    </style>
                    <ProgressBar variant={custom} now={percent.toFixed(0)} />
                    {/* {percent.toFixed(1)}% */}
                </div>
                <div style={{'width': '80px'}}>
                    <div className='background_div' style={style_bg}>
                        {props.piece.distance.toFixed(1)} км.
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='div_stop_piece'>
                <div className='stop_time_piece'>
                    {range_date_str(props.piece.start_dt, props.piece.end_dt)}
                </div>
                <div className='stop_text_piece'>
                    Стоянка
                </div>
            </div>
        )
    }

    function range_date_str(start, end){

        start = start.split(' ')[1]
        start = start.split(':')[0] + ':' + start.split(':')[1]

        end = end.split(' ')[1]
        end = end.split(':')[0] + ':' + end.split(':')[1]

        return start + '-' + end
    }

}