import React from 'react'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import ClickAwayListener from '@mui/material/ClickAwayListener'

export default function Navbar_user(props) {
  const [cookies, removeCookie] = useCookies(['csrftoken'])

  const duration = 100
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    display: 'none',
  }
  const transitionStyles = {
    entering: { opacity: 1, display: 'block' },
    entered: { opacity: 1, display: 'block' },
    exiting: { opacity: 0, display: 'block' },
    exited: { opacity: 0, display: 'none' },
  }

  function handleClickAway() {
    if (props.status) {
      props.change_status()
    }
  }

  function exit_user() {
    removeCookie('csrftoken')
    window.location.reload()
  }

  let label = ''
  if (props.user) {
    if (props.user.last_name) {
      label = label + props.user.last_name
    } else {
      label = label + '-'
    }

    if (props.user.first_name) {
      label = label + ' ' + props.user.first_name
    } else {
      label = label + ' -'
    }

    if (props.user.middle_name) {
      label = label + ' ' + props.user.middle_name
    } else {
      label = label + ' -'
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className='div_user_navbar'>
        <div className='div_name_and_type' onClick={props.change_status}>
          <div className='text_name_user_navbar'>{props.user ? <>{label}</> : <></>}</div>
          <div
            className={
              props.user.partner.days_left > 7
                ? 'text_type_user_navbar'
                : 'text_type_user_navbar error'
            }
            onClick={props.change_status}
          >
            {props.user ? (
              <>{`${props.user.partner.licenses.toFixed()} Лицензий (${
                props.user.partner.days_left
              } дн)`}</>
            ) : (
              <></>
            )}
          </div>
        </div>
        <Transition in={props.status} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
              className='div_menu_navbar'
            >
              <Link to={'/park_settings?bills=true'} style={{ textDecoration: 'none' }}>
                <div className='text_menu_navbar'>Купить лицензии</div>
              </Link>
              <div className='text_menu_navbar' onClick={exit_user}>
                Выйти
              </div>
            </div>
          )}
        </Transition>
      </div>
    </ClickAwayListener>
  )
}
