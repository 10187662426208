import React from "react";
import '../../../styles/routes_map/routes.css'

const Modal_add_car_to_zone = ({active, setActive, children}) => {

    return (
        <div className={active ? "modal_add_car_to_zone active" : "modal_add_car_to_zone"} onClick={() => setActive()}>
            <div className="modal_add_car_to_zone_content" onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );

}

export default Modal_add_car_to_zone;