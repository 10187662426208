/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import Logo from '../../styles/icons/logo.svg'
import './Share.css'

const Header = (props) => {
  const mobileClass = props.isMobile ? '_mobile' : ''

  return (
    <div className={'shareHeader' + mobileClass}>
      <img src={Logo} className={'shareLogo' + mobileClass} />
    </div>
  )
}

export default Header
