import React, { useEffect, useState, useContext } from 'react'
import '../../../styles/park_settings/main_park_settings.css'
import Switch from 'react-switch'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import Btn_defolt from '../../UI/btn_defolt.jsx'
import Fake_btn_defolt from '../../UI/fake_btn_defolt.jsx'
import Basic_input from '../../UI/basic_input.jsx'

import axios_api from '../../../API/axios_api.js'
import { useCookies } from 'react-cookie'

export default function Geozones(props) {
  const [cookies] = useCookies(['csrftoken'])

  const [geozone_engine_stop_safe, setGeozone_engine_stop_safe] = useState(false)
  const [geozone_phone_number, setGeozone_phone_number] = useState('')
  const [is_geozone_phone_number, setIs_geozone_phone_number] = useState(false)

  const [status_save_edit_btn, setStatus_save_edit_btn] = useState(null)

  useEffect(() => {
    if (props.settings_current) {
      setGeozone_engine_stop_safe(props.settings_current.geozone_engine_stop_safe)
      setGeozone_phone_number(props.settings_current.geozone_phone_number)
      if (props.settings_current.geozone_phone_number) {
        setIs_geozone_phone_number(true)
      }
    }
  }, [])

  async function save_edit() {
    let response_edit = await axios_api.geozone_settings_edit(
      geozone_engine_stop_safe,
      is_geozone_phone_number ? geozone_phone_number : '',
      cookies.csrftoken,
    )

    if (response_edit.status == '200') {
      setStatus_save_edit_btn('success')
    } else {
      setStatus_save_edit_btn('error')
    }

    setTimeout(function () {
      setStatus_save_edit_btn(null)
    }, 2000)

    props.update_all()
  }

  return (
    <div className='div_main_setting'>
      <div className='header_refill_settings'>
        <div className='text_header_refill_settings'>Геозоны</div>
      </div>
      <div style={{ marginTop: '30px', marginLeft: '16px' }}>
        <div
          style={{ marginTop: '30px', display: 'flex', alignItems: 'center', marginBottom: '20px' }}
        >
          <Switch
            onChange={(e) => setGeozone_engine_stop_safe(!geozone_engine_stop_safe)}
            checked={geozone_engine_stop_safe}
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={36}
            offColor={'#E7ECFB'}
            onColor={'#6486FB'}
            activeBoxShadow={'0 0 2px 3px #6486FB'}
          />
          <div
            className={
              geozone_engine_stop_safe
                ? 'text_checkbox_with_text active'
                : 'text_checkbox_with_text'
            }
            style={{ marginLeft: '10px' }}
          >
            Не блокировать авто на ходу при выезде из геозоны
          </div>
        </div>
        <div
          style={{ marginTop: '30px', display: 'flex', alignItems: 'center', marginBottom: '20px' }}
        >
          <Switch
            onChange={(e) => setIs_geozone_phone_number(!is_geozone_phone_number)}
            checked={is_geozone_phone_number}
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={36}
            offColor={'#E7ECFB'}
            onColor={'#6486FB'}
            activeBoxShadow={'0 0 2px 3px #6486FB'}
          />
          <div
            className={
              is_geozone_phone_number ? 'text_checkbox_with_text active' : 'text_checkbox_with_text'
            }
            style={{ marginLeft: '10px' }}
          >
            СМС-уведомления при выезде авто из геозоны
          </div>
        </div>
        <SlideDown className={'my-dropdown-slidedown'}>
          {is_geozone_phone_number ? (
            <div
              style={{
                marginTop: '24px',
              }}
            >
              <div>
                <Basic_input
                  value={geozone_phone_number}
                  text={'Номер телефона для отправки СМС-уведомлений'}
                  status={status_save_edit_btn === 'success' ? 'success' : 'active'}
                  width={'400px'}
                  type={'text'}
                  onChange={setGeozone_phone_number}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </SlideDown>
        <div style={{ marginTop: '30px' }}>
          {status_save_edit_btn ? (
            <>
              {status_save_edit_btn == 'success' ? (
                <Fake_btn_defolt
                  color={'#0acf97'}
                  text_color={'#FFFFFF'}
                  hover_color={'#0acf97'}
                  text={'Сохранено!'}
                  height={'40px'}
                  width={'250px'}
                />
              ) : (
                <Fake_btn_defolt
                  color={'#fa5c7c'}
                  text_color={'#FFFFFF'}
                  hover_color={'#fa5c7c'}
                  text={'Ошибка!'}
                  height={'40px'}
                  width={'250px'}
                />
              )}
            </>
          ) : (
            <Btn_defolt
              color={'#0acf97'}
              text_color={'#FFFFFF'}
              hover_color={'#07b584'}
              text={'Сохранить изменения'}
              height={'40px'}
              width={'250px'}
              onClick={save_edit}
            />
          )}
        </div>
      </div>
    </div>
  )
}
