import React from 'react'
import SimpleBar from 'simplebar-react'

import '../../styles/car_list/car_list_main.css'

export default function Car_info(props) {
  let count = 0

  if (props.car.inputs) {
    for (let input of props.car.inputs) {
      input.id = count
      count++
    }
  }

  return (
    <div className="car_info_main">
      <div className="header_car_info">Данные автомобиля</div>
      {props.car.inputs ? (
        <div style={{ marginTop: '15px' }}>
          <SimpleBar style={{ maxHeight: 232 }}>
            {props.car.inputs.map((input) => (
              <div key={input.id} className="car_info_item">
                <div className="car_info_name">{input.verbose_name}</div>
                <div className="car_info_value">
                  {input.value} {input.units}
                </div>
              </div>
            ))}
          </SimpleBar>
        </div>
      ) : (
        <div style={{ marginTop: '15px' }}>
          <div className="car_info_item">
            <div className="car_info_name">Нет данных</div>
          </div>
        </div>
      )}
    </div>
  )
}
